import { useSearchParams } from 'react-router-dom';
import { EAppStageContentType } from '../../chat-wrapper/resizable-container/stage-container/stageContainer.interfaces';
import {
  productTourDataAttrChat,
  productTourDataAttrPlan,
  productTourDataAttrScratchpad,
  productTourDataAttrTasks,
  productTourDataAttrUserAvatarDesk,
  productTourDataAttrUserAvatarMobile,
  stageParam,
} from '../../../app/constants';
import { ReactComponent as MascotComponent1 } from '../../../assets/images/product-tour/mascot-1.svg';
import { ReactComponent as MascotComponent2 } from '../../../assets/images/product-tour/mascot-2.svg';
import { ReactComponent as MascotComponent3 } from '../../../assets/images/product-tour/mascot-3.svg';
import { ReactComponent as MascotComponent4 } from '../../../assets/images/product-tour/mascot-4.svg';
import { ReactComponent as MascotComponent5 } from '../../../assets/images/product-tour/mascot-5.svg';
import { ReactComponent as MascotComponent6 } from '../../../assets/images/product-tour/mascot-6.svg';
import { ReactComponent as MascotComponent7 } from '../../../assets/images/product-tour/mascot-7.svg';
import { useTranslation } from 'react-i18next';
import type { IProductTour } from '../ProductTour.utils';

export const ProductTourArray = (): IProductTour[] => {
  const [, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const replaceStageParam = (screen: EAppStageContentType) => {
    setSearchParams((prev) => {
      prev.delete(stageParam);
      if (screen !== EAppStageContentType.CHAT) {
        prev.append(stageParam, screen);
      }
      return prev;
    });
  };

  return [
    {
      title: t('productTourTitle1'),
      icon: <MascotComponent1 />,
      text: t('productTourParagraph1'),
      next: () => {},
      prev: () => {},
      target: null,
    },
    {
      // chat
      title: t('productTourTitle2'),
      icon: <MascotComponent2 />,
      text: t('productTourParagraph2'),
      next: () => replaceStageParam(EAppStageContentType.SCRATCHPAD),
      prev: () => {},
      target: productTourDataAttrChat,
    },
    {
      // scratchpad
      title: t('productTourTitle3'),
      icon: <MascotComponent3 />,
      text: t('productTourParagraph3'),
      next: () => replaceStageParam(EAppStageContentType.TASKS),
      prev: () => replaceStageParam(EAppStageContentType.CHAT),
      target: productTourDataAttrScratchpad,
    },
    {
      // tasks
      title: t('productTourTitle4'),
      icon: <MascotComponent4 />,
      text: t('productTourParagraph4'),
      next: () => replaceStageParam(EAppStageContentType.PLANNER),
      prev: () => replaceStageParam(EAppStageContentType.SCRATCHPAD),
      target: productTourDataAttrTasks,
    },
    {
      // plan
      title: t('productTourTitle5'),
      icon: <MascotComponent5 />,
      text: t('productTourParagraph5'),
      next: () => replaceStageParam(EAppStageContentType.CHAT),
      prev: () => replaceStageParam(EAppStageContentType.TASKS),
      target: productTourDataAttrPlan,
    },
    {
      // settings
      title: t('productTourTitle6'),
      icon: <MascotComponent6 />,
      text: t('productTourParagraph6'),
      next: () => {},
      prev: () => replaceStageParam(EAppStageContentType.PLANNER),
      target: productTourDataAttrUserAvatarDesk,
      mobileTarget: productTourDataAttrUserAvatarMobile,
    },
    {
      title: t('productTourTitle7'),
      icon: <MascotComponent7 />,
      text: t('productTourParagraph7'),
      next: () => {},
      prev: () => replaceStageParam(EAppStageContentType.CHAT),
      target: null,
    },
  ];
};
