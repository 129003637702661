import { getFeatureFlagByKey, isDesktopView, isMobileView } from '../../shared/utils/utils';
import productTourSassVariables from './ProductTour.module.scss';
import { EClientFlags } from '../../shared/components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { IUser } from '../../app/auth/auth.interfaces';
import { FlagUtils } from '../../shared/utils/flagUtils';
import { EProductTourViewType } from './ProductTourWrapper';
import { ApplicationInsightsApi } from '../../application-insights';

export interface IProductTour {
  title: string;
  icon?: React.ReactNode;
  text: string;
  next: () => void;
  prev: () => void;
  target?: string | null;
  mobileTarget?: string;
  list?: { title: string; text: string }[];
  cardClassName?: string;
}

interface PositionModalParams {
  modalWidth: number;
  modalHeight: number;
  elementSelector: string;
  limitFromTop?: number;
  limitFromBottom?: number;
  limitFromLeft?: number;
  limitFromRight?: number;
}

export interface ModalPosition {
  top: number;
  left: number;
  chevronPosition: {
    top: number;
    left: number;
    direction: 'up' | 'down' | 'left' | 'right';
  };
}

// A function where the input is an id of an element, a width and height of a modal, and return the position of the modal and its chevron to be close to the position of the element with the id without overflowing the html window
export function getModalPosition({
  modalWidth,
  modalHeight,
  elementSelector,
  limitFromTop = isMobileView() ? 5 : 30,
  limitFromBottom = isMobileView()
    ? Number(productTourSassVariables?.productTourMobileBottomSpacingFromWindowPX.replace('px', ''))
    : 10,
  limitFromLeft = isMobileView()
    ? 10
    : Number(productTourSassVariables?.productTourDesktopLeftSpacingFromWindowPX.replace('px', '')),
  limitFromRight = 10,
}: PositionModalParams): ModalPosition | null {
  const element = document.querySelector(elementSelector);
  if (!element) return null;

  const elementRect = element.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  // Calculate space availability with limits
  const spaceAbove = elementRect.top - limitFromTop;
  const spaceBelow = windowHeight - elementRect.bottom - limitFromBottom;
  const spaceLeft = elementRect.left - limitFromLeft;
  const spaceRight = windowWidth - elementRect.right - limitFromRight;

  // Determine initial positioning heuristic
  let position = 'below';
  if (spaceBelow < modalHeight && spaceAbove > modalHeight) {
    position = 'above';
  } else if (spaceLeft > modalWidth) {
    position = 'left';
  } else if (spaceRight > modalWidth) {
    position = 'right';
  }

  // Further refine for desktop screens (or large screens)
  if (isDesktopView()) {
    // Assuming a breakpoint for desktop
    if (spaceLeft > modalWidth) {
      position = 'left';
    } else if (spaceRight > modalWidth) {
      position = 'right';
    }
  }

  // Further refine for vertically oriented navbar items
  if (position === 'below' && elementRect.height < 50 && spaceLeft > modalWidth) {
    position = 'left';
  }

  // Determine final top and left based on the chosen position
  let top = 0;
  let left = 0;
  let chevronPosition = {
    top: 0,
    left: 0,
    direction: 'up' as 'up' | 'down' | 'left' | 'right',
  };

  // Position modal and chevron according to the determined direction
  switch (position) {
    case 'above':
      top = elementRect.top - modalHeight - 10;
      left = elementRect.left + elementRect.width / 2 - modalWidth / 2;
      chevronPosition = {
        top: modalHeight - 6,
        left: Math.min(
          Math.max(elementRect.left + elementRect.width / 2 - left - 10, 10),
          modalWidth - 30,
        ),
        direction: 'down',
      };
      break;
    case 'below':
      top = elementRect.bottom + 10;
      left = elementRect.left + elementRect.width / 2 - modalWidth / 2;
      chevronPosition = {
        top: -8,
        left: Math.min(
          Math.max(elementRect.left + elementRect.width / 2 - left - 10, 10),
          modalWidth - 30,
        ),
        direction: 'up',
      };
      break;
    case 'left':
      top = elementRect.top + elementRect.height / 2 - modalHeight / 2;
      left = elementRect.left - modalWidth - 10;
      chevronPosition = {
        top: Math.min(
          Math.max(elementRect.top + elementRect.height / 2 - top - 10, 10),
          modalHeight - 30,
        ),
        left: modalWidth - 11,
        direction: 'right',
      };
      break;
    case 'right':
      top = elementRect.top + elementRect.height / 2 - modalHeight / 2;
      left = elementRect.right + 10;
      chevronPosition = {
        top: Math.min(
          Math.max(elementRect.top + elementRect.height / 2 - top - 10, 10),
          modalHeight - 30,
        ),
        left: -9,
        direction: 'left',
      };
      break;
  }

  // Adjustments for overflow, respecting the limits
  if (top < limitFromTop) top = limitFromTop;
  if (left < limitFromLeft) left = limitFromLeft;
  if (top + modalHeight > windowHeight - limitFromBottom)
    top = windowHeight - modalHeight - limitFromBottom;
  if (left + modalWidth > windowWidth - limitFromRight)
    left = windowWidth - modalWidth - limitFromRight;

  // Adjust the chevron position to align with the element
  switch (position) {
    case 'above':
    case 'below':
      chevronPosition.left = Math.min(
        Math.max(elementRect.left - left + elementRect.width / 2 - 10, 10),
        modalWidth - 30,
      );
      break;
    case 'left':
    case 'right':
      chevronPosition.top = Math.min(
        Math.max(elementRect.top - top + elementRect.height / 2 - 10, 10),
        modalHeight - 30,
      );
      break;
  }

  return { top, left, chevronPosition };
}

// Checks if the product tour has already been displayed to the user.
export const isProductTourAlreadyDisplayed = (user: IUser | null): boolean => {
  const clientFlags = user?.clientFlags || 0;
  const isProductTourDisplayed = FlagUtils.getFlag(
    clientFlags,
    EClientFlags.PRODUCT_TOUR_DISPLAYED,
  );
  // Product tour was not displayed yet if the user is new (created on or after 2024-11-04) and the PRODUCT_TOUR_DISPLAYED client flag is not set
  if (getFeatureFlagByKey('UserCreatedIn_2024_11_4_OrLater') && !isProductTourDisplayed)
    return false;
  return true;
};

export const logProductTourDisplayedEvent = (type: EProductTourViewType) => {
  switch (type) {
    case EProductTourViewType.PRODUCT_TOUR:
      ApplicationInsightsApi.trackEvent('*SignUpProductTourDisplayed');
      break;
    default:
      break;
  }
};
