import { useTranslation } from 'react-i18next';
import DiscordLink from '../../discord-link/DiscordLink';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import feedbackImg from '../../../../assets/images/feedback-modal-image.png';
import { setAppModalContentType } from '../appModals.store';
import { updateUserProfileReq } from '../../../../app/useAppData/user.store';
import './FeedbackModalContent.scss';
import { openExternalLinkOnMobileApp } from '../../../../mobile-application-utils';

const FeedbackModalContent = () => {
  const { userAppData } = useAppSelector((store) => store.userReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCloseModal = () => {
    dispatch(updateUserProfileReq({ shouldDisplayFeedbackSurvey: false }));
    dispatch(setAppModalContentType(null));
  };

  return (
    <div className="feedback-modal-content-container">
      <section>
        <h2 className="title">{t('feedbackModalTitle')}</h2>
        <img src={feedbackImg} alt="feedback-modal" />
        <p className="feedback-modal-para">
          {t('feedbackModalParagraph1')}
          <br />
          <br />
          {t('feedbackModalParagraph2')}
        </p>
        <a
          href={userAppData.data?.feedbackSurveyUrl ?? '#'}
          target="_blank"
          rel="noreferrer"
          className="feedback-modal-link"
          onClick={(e) => {
            openExternalLinkOnMobileApp(e, userAppData.data?.feedbackSurveyUrl);
            onCloseModal();
          }}
        >
          {t('feedbackModalSurveyLinkText')}
        </a>
      </section>
      <section className="bottom-container">
        <DiscordLink
          className="feedback-modal-discord-link"
          textFollowTheDiscordLink={t('AppModalTextFollowJoinOurDiscordLink')}
        />
        <button className="modal-button" onClick={onCloseModal}>
          {t('feedbackModalButtonText')}
        </button>
      </section>
    </div>
  );
};

export default FeedbackModalContent;
