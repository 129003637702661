import { useCallback, useEffect, useRef } from 'react';

interface UseScrollThresholdProps {
  ref: React.RefObject<HTMLElement>;
  threshold: number;
  direction?: 'x' | 'y';
  onThresholdReached: () => void;
  debounceTime?: number;
}

export const useScrollThreshold = ({
  ref,
  threshold,
  direction = 'y',
  onThresholdReached,
  debounceTime = 200,
}: UseScrollThresholdProps): void => {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);
  const lastScrollPosition = useRef(0);

  const handleScroll = useCallback(() => {
    if (!ref.current) return;

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    const currentScrollPosition =
      direction === 'y' ? ref.current.scrollTop : ref.current.scrollLeft;
    const maxScroll =
      direction === 'y'
        ? ref.current.scrollHeight - ref.current.clientHeight
        : ref.current.scrollWidth - ref.current.clientWidth;
    const remainingScroll = maxScroll - currentScrollPosition;

    const isScrollingInCorrectDirection = currentScrollPosition > lastScrollPosition.current;

    lastScrollPosition.current = currentScrollPosition;

    if (isScrollingInCorrectDirection) {
      timeoutId.current = setTimeout(() => {
        if (remainingScroll <= threshold) {
          onThresholdReached();
        }
      }, debounceTime);
    }
  }, [ref, threshold, direction, onThresholdReached, debounceTime]);

  useEffect(() => {
    if (!ref.current) return;

    const currentRef = ref.current;
    currentRef.addEventListener('scroll', handleScroll);

    return () => {
      if (timeoutId.current) {
        clearTimeout(timeoutId.current);
      }
      currentRef.removeEventListener('scroll', handleScroll);
    };
  }, [ref, handleScroll]);
};
