import { useTranslation } from 'react-i18next';
import { privacyPolicyLink, termsOfServiceLink } from '../../../constants';
import './TermsOfService.scss';

export const TermsOfService = ({ shouldAddVerifiedAge }: { shouldAddVerifiedAge?: boolean }) => {
  const { t } = useTranslation();

  const termsOfServiceText = (): string => {
    const checkboxHtmlString = t(
      shouldAddVerifiedAge
        ? 'registrationTermsOfServiceVerifiedAge'
        : 'registrationTermsOfServiceSections',
    )
      .replace(
        '{terms}',
        `<a href=${termsOfServiceLink} target="_blank" rel="noreferrer">${t(
          'termsOfServiceLinkText',
        )}</a>`,
      )
      .replace(
        '{privacy_policy}',
        `<a href=${privacyPolicyLink} target="_blank" rel="noreferrer">${t(
          'privacyPolicyLinkText',
        )}</a>`,
      );
    return checkboxHtmlString;
  };

  return (
    <div className="terms-of-service-container">
      <p
        className="terms-of-service-content"
        dangerouslySetInnerHTML={{ __html: termsOfServiceText() }}
      ></p>
    </div>
  );
};
