import { useTranslation } from 'react-i18next';
import type { IMessageDataTask } from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import { ETaskStatus } from '../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import type { SyntheticEvent } from 'react';
import { Fragment, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import SingleTaskItemContainer, {
  ETaskViewType,
} from '../../chat-wrapper/chat/chat-conversation/message-data-card/tasks-list/tasks-list-single-item/SingleTaskItemContainer';
import CalendarDay from '../calendar/calendar-day/CalendarDay';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { EPlanDayCardDisplayType } from './PlanDayCard';
import {
  addDaysToDate,
  getDateBasedOnDayIndexAndWeekOffset,
  getDateWithYear,
} from '../../../shared/utils/dateFormat';
import { isMobileView } from '../../../shared/utils/utils';
import PlanDayScheduledWorkBlock from './plan-day-scheduled-work-block/PlanDayScheduledWorkBlock';
import { calcCalendarDragAndDropDimensions } from '../calendar/calendar-day/CalendarDragAndDrop.util';
import { setCalendarFirstCellVisiblePortion } from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';

interface IProps {
  index: number;
  daysToDisplay: number;
  displayType: EPlanDayCardDisplayType;
  inView: boolean;
}

export const PlanDayCardScheduleSection = ({
  index,
  daysToDisplay,
  displayType,
  inView,
}: IProps) => {
  const { t } = useTranslation();
  const [tasksAndWorkBlocksScheduled, setTasksAndWorkBlocksScheduled] = useState<
    IMessageDataTask[]
  >([]);
  const { allTasks, allWorkBlocks } = useAppSelector((store) => store.StageTasksReducer);
  const { dragAndDropCalendarDimensions } = useAppSelector((store) => store.StagePlannerReducer);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const scrollPosition = useRef<number | null>(null);
  const [droppingProcessCounter, setDroppingProcessCounter] = useState<number>(0);
  const calendarResizeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const scrollTimerRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();

  const cardDate = useCallback(
    (currentDayIndex: number): string => {
      switch (displayType) {
        case EPlanDayCardDisplayType.MY_PLAN:
        case EPlanDayCardDisplayType.MY_DAY:
          return getDateWithYear(addDaysToDate(new Date(), currentDayIndex).toISOString());
        case EPlanDayCardDisplayType.MY_WEEK:
          if (daysToDisplay === 7)
            return getDateWithYear(
              getDateBasedOnDayIndexAndWeekOffset(currentDayIndex, index).toISOString(),
            );
          return getDateWithYear(addDaysToDate(new Date(), currentDayIndex).toISOString());
      }
    },
    [displayType, index, daysToDisplay],
  );

  const getTasksAndWorkBlocksScheduled = useCallback(
    (currentDayIndex: number, shouldSetState = true) => {
      let itemWorkTime = null;
      let taskDate = '';

      const tasksAndWorkBlocksScheduled = [...allTasks, ...allWorkBlocks].filter(
        (item: IMessageDataTask) => {
          itemWorkTime = item.workTime;
          if (
            item.status === ETaskStatus.DELETED ||
            !itemWorkTime ||
            (!shouldSetState && item.isFullDayEvent)
          )
            return false;
          if (item?.workBlockId) return false;
          taskDate = getDateWithYear(itemWorkTime as string);
          return taskDate === cardDate(currentDayIndex);
        },
      );
      tasksAndWorkBlocksScheduled.sort(
        (a, b) => new Date(a.workTime || '').getTime() - new Date(b.workTime || '').getTime(),
      );
      if (shouldSetState) setTasksAndWorkBlocksScheduled(tasksAndWorkBlocksScheduled);
      return tasksAndWorkBlocksScheduled;
    },
    [allTasks, allWorkBlocks, cardDate],
  );

  useEffect(() => {
    getTasksAndWorkBlocksScheduled(index);

    // scroll to previous scroll position (if available) when day card is in view and tasks are loaded
    if (scrollPosition.current != null && scrollableContainerRef.current && inView) {
      if (!isMobileView()) {
        setTimeout(() => {
          scrollableContainerRef.current?.scrollTo({
            top: scrollPosition.current!,
            behavior: 'smooth',
          });
        }, 0);
      } else {
        scrollableContainerRef.current?.scrollTo({
          top: scrollPosition.current,
          behavior: 'smooth',
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getTasksAndWorkBlocksScheduled]);
  // scroll to current hour line on first render
  useLayoutEffect(() => {
    if (scrollPosition.current == null && scrollableContainerRef.current) {
      setTimeout(() => {
        const hour = new Date().getHours();
        const hourLine = document.getElementById(`hour-${hour}-${index}`);
        if (hourLine) {
          const rect = hourLine.getBoundingClientRect();
          if (scrollableContainerRef.current) {
            if (rect.top > 0) {
              scrollableContainerRef.current.scrollTo({ top: rect.top - 150, behavior: 'smooth' });
            }
          }
        }
      }, 100);
    }
  }, [index]);

  const clearCalendarResizeTimer = () => {
    if (calendarResizeTimerRef.current) {
      clearTimeout(calendarResizeTimerRef.current);
      calendarResizeTimerRef.current = null;
    }
  };

  useEffect(() => {
    if (!scrollableContainerRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      // use throttling to avoid performance issues on desktop when expanding/shrinking the staging area
      clearCalendarResizeTimer();
      calendarResizeTimerRef.current = setTimeout(() => {
        calcCalendarDragAndDropDimensions();
      }, 300);
    });
    resizeObserver.observe(scrollableContainerRef.current);
    return () => {
      resizeObserver.disconnect();
      clearCalendarResizeTimer();
      if (scrollTimerRef.current) clearTimeout(scrollTimerRef.current);
    };
  }, []);

  const handleScroll = (e: SyntheticEvent<HTMLDivElement>) => {
    scrollPosition.current = e.currentTarget.scrollTop;
    if (scrollTimerRef.current) clearTimeout(scrollTimerRef.current);
    scrollTimerRef.current = setTimeout(() => {
      // calc the firstCellVisiblePortion and update the calendarFirstCellVisiblePortion
      if (dragAndDropCalendarDimensions && scrollableContainerRef.current) {
        const firstCellVisiblePortion =
          dragAndDropCalendarDimensions.cellHeight -
          (scrollableContainerRef.current.scrollTop % dragAndDropCalendarDimensions.cellHeight);
        dispatch(setCalendarFirstCellVisiblePortion(firstCellVisiblePortion));
      }
    }, 250);
  };

  return (
    <>
      <div
        className={`plan-day-card-content--scheduled-tasks plan-day-card-content--scheduled-tasks--total-${tasksAndWorkBlocksScheduled.length}`}
      >
        <div className="plan-day-card-content-scrollable-container">
          <div>
            {tasksAndWorkBlocksScheduled.map((t) => (
              <Fragment key={t.id}>
                {t.isEvent && t.isWorkBlock ? (
                  <PlanDayScheduledWorkBlock workBlock={t} />
                ) : (
                  <SingleTaskItemContainer
                    viewType={ETaskViewType.MY_PLAN_SCHEDULED}
                    shouldHideOnComplete={false}
                    singleTask={t}
                    shouldDisplayActionButtons={!t?.isEvent}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div
        onScroll={handleScroll}
        ref={scrollableContainerRef}
        className={`plan-day-card-content--calendar-day${
          displayType === EPlanDayCardDisplayType.MY_WEEK
            ? ' scroll-y-container-hidden-scroll-bar'
            : ''
        }`}
      >
        <div style={{ display: 'flex' }}>
          {Array.from({ length: daysToDisplay }).map((_, dayIndex) => (
            <Fragment key={dayIndex}>
              <CalendarDay
                daysToDisplay={daysToDisplay}
                dayIndex={daysToDisplay * index + dayIndex}
                cardIndex={index}
                shouldShowHourText={dayIndex === 0}
                tasksAndWorkBlocks={[
                  ...getTasksAndWorkBlocksScheduled(
                    displayType === EPlanDayCardDisplayType.MY_WEEK && daysToDisplay === 7
                      ? dayIndex
                      : daysToDisplay * index + dayIndex,
                    false,
                  ),
                ]}
                showCurrentTimeIndicator={index === 0 && dayIndex === 0}
                playViewType={displayType}
                setDroppingProcessCounter={setDroppingProcessCounter}
                droppingProcessCounter={droppingProcessCounter}
                scrollableContainerRef={scrollableContainerRef}
                nonRelativeDayIndex={dayIndex}
              />
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};
