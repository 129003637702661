import type { Dispatch, SetStateAction, FunctionComponent } from 'react';
import React, { useState } from 'react';
import { ContentFrameWrapper } from '../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import './WelcomeScreen.scss';
import { useTranslation } from 'react-i18next';
import { ReactComponent as MascotComponent } from '../../../assets/images/mascot/main_3.svg';
import AppButton from '../../../shared/components/app-button/AppButton';
import { useAppDispatch } from '../../../app/store';
import { setShouldDisplayProductTour } from '../../../shared/store/shared.store';

interface IWelcomeScreenProps {
  isWelcomeScreenOpen: boolean;
  setIsWelcomeScreenOpen: Dispatch<SetStateAction<boolean>>;
}

const WelcomeScreen: FunctionComponent<IWelcomeScreenProps> = ({
  isWelcomeScreenOpen,
  setIsWelcomeScreenOpen,
}) => {
  const [shouldShowComponent, setShouldShowComponent] = useState(isWelcomeScreenOpen);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setIsWelcomeScreenOpen(false);
    dispatch(setShouldDisplayProductTour(true));
  };

  return (
    <>
      {shouldShowComponent && (
        <div
          className={`welcome-screen-container ${!isWelcomeScreenOpen ? 'fadeOut' : ''}`}
          data-testid="welcome-screen"
          onAnimationEnd={() => {
            setShouldShowComponent(false);
          }}
        >
          <ContentFrameWrapper className="welcome-screen-frame">
            <div className="welcome-screen-content-container scroll-y-container-hidden-scroll-bar">
              <h1 className="welcome-screen-title">{t('welcomeScreenTitle')}</h1>
              <MascotComponent className="mascot" />
              <p className="welcome-screen-description">{t('welcomeScreenParagraph')}</p>
            </div>
            <AppButton
              data-testid="lets-go-button"
              className="lets-go-btn"
              onClick={handleClose}
              id="welcome-screen-lets-go"
            >
              {t('welcomeScreenButtonText')}
            </AppButton>
          </ContentFrameWrapper>
        </div>
      )}
    </>
  );
};

export default WelcomeScreen;
