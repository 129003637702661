export enum EPlannerClickEvent {
  TIME_PICKER = 1,
  UNSCHEDULED_PLACEMENT = 2,
  EVENT_CREATION = 3,
}

export enum EPlanViewSelection {
  MY_PLAN = 'My Plan',
  MY_DAY = 'My Day',
  MY_WEEK = 'My Week',
}
