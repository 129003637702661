import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import type { ECalendarSyncType } from '../../../../../../app/auth/auth.interfaces';

interface ISyncButtonProps {
  syncType: ECalendarSyncType;
  icon: string;
  title: string;
  subtitle: string;
  imageAltText: string;
  onClick: (syncType: ECalendarSyncType) => void;
}

const SyncButton: FunctionComponent<ISyncButtonProps> = ({
  syncType,
  icon,
  title,
  subtitle,
  imageAltText,
  onClick,
}) => {
  const { t } = useTranslation();

  return (
    <button
      id={`set-calendar-sync-${t(title)?.trim()}`}
      className="calendar-sync-popover-sync-option"
      onClick={() => onClick(syncType)}
    >
      <div className="calendar-sync-button-content-container">
        <div className="title-container">
          <img src={icon} alt={t(`${imageAltText}`)} />
          <h3 className="title">{t(title)}</h3>
        </div>
        <h6 className="subTitle">{t(subtitle)}</h6>
      </div>
    </button>
  );
};
export default SyncButton;
