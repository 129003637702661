import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import welcomeScreen2Image from '../../../../assets/images/welcome-screen-2-image.png';

export const CarouselScreen2: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="carousel-element welcome-screen-2" data-testid="carousel-element">
      <h2 className="title auth-title">{t('welcomeScreen2Title')}</h2>
      <div className="image-container">
        <img src={welcomeScreen2Image} alt={t('welcomeScreen2ImageAltText2')} />
      </div>
      <p className="extension-text">{t('welcomeScreen2Paragraph1')}</p>
      <p className="extension-text section-2">{t('welcomeScreen2Paragraph2')}</p>
    </div>
  );
};
