import type { Locale } from 'antd/es/locale';
import enUS from 'antd/locale/en_US';
import heIL from 'antd/locale/he_IL';

export const locales: { [key: string]: Locale } = {
  en: enUS,
  he: heIL,
};

export const defaultAntdLocale = enUS;
