import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { useLocalStorage } from '../../../utils/useLocalStorage';
import {
  API_ROUTES,
  APP_ROUTING_PATHS,
  registrationParametersStorageKey,
  userPhoneCountryTwoLetterCodeLocalStorageKey,
  userPhoneNumberLocalStorageKey,
} from '../../../../app/constants';
import type { ILoginReqPayload, IVerifyOtpResponse } from '../../../../app/auth/auth.interfaces';
import { useTranslation } from 'react-i18next';
import { verifyOtpReqAction } from '../../../../app/useAppData/user.store';
import {
  loginReqAction,
  requestMagicLink,
  sendOtpReqAction,
} from '../../../../app/auth/auth.store';
import { ApplicationInsightsApi } from '../../../../application-insights';
import phoneMascotImg from '../../../../assets/images/mascot/phone-mascot.png';
import emailMascotImg from '../../../../assets/images/mascot/onboarding/email-sign-in-mascot.png';
import OtpInput from '../../otp-input/OtpInput';
import './PhoneOrEmailVerificationByOtp.scss';
import { useApiData } from '../../../hooks/useApiData';
import { AuthHeroCard } from '../../auth-hero-card/AuthHeroCard';
import AppButton from '../../app-button/AppButton';
import { baseUrl } from '../../../../app/auth/login/Login';
import { isEmbeddedBrowser } from '../../../../app/auth/auth.utils';

interface IPhoneOrEmailVerificationByOtpProps {
  isLoginFlow?: boolean;
  emailToVerify?: string;
  title: string;
  subTitle: string;
  onFulfilledVerifyOtp?: (response: IVerifyOtpResponse) => void;
  submitText?: string;
  className?: string;
  isRegistrationFlow?: boolean;
  trackEventName: string;
  context: 'CreateUser' | 'UpdateSettings';
}

const PhoneOrEmailVerificationByOtp = ({
  onFulfilledVerifyOtp,
  isLoginFlow,
  emailToVerify,
  submitText,
  className = '',
  isRegistrationFlow = false,
  trackEventName,
  context,
  title,
  subTitle,
}: IPhoneOrEmailVerificationByOtpProps) => {
  const { control, handleSubmit, setFocus, formState, reset } = useForm<{
    otp: string;
  }>({
    defaultValues: { otp: '' },
  });

  const { verifyOtpRes } = useAppSelector((store) => store.userReducer);
  const [phoneNumber] = useLocalStorage<string | null>(userPhoneNumberLocalStorageKey, null);
  const [phoneCountryTwoLetterCode] = useLocalStorage<string | null>(
    userPhoneCountryTwoLetterCodeLocalStorageKey,
    null,
  );
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const resetOtpTimer = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState<string | null>(null);
  const isMobileOrEmbeddedBrowser = isEmbeddedBrowser();

  useEffect(() => {
    // set auto focus on the otp input
    setFocus('otp');

    return () => {
      // clear Timeout when the component is destroyed
      if (resetOtpTimer.current) clearTimeout(resetOtpTimer.current);
    };
  }, [setFocus]);

  useApiData(verifyOtpRes, {
    onRejected() {
      handleLoginError();
    },
  });

  const handleLoginError = () => {
    if (context === 'UpdateSettings') {
      setShouldDisplayError('phoneVerificationPhoneExists');
    } else {
      setShouldDisplayError('phoneVerificationPhoneNumberError');
    }
    resetOtpInput();
  };

  const onSubmit = (formData: { otp: string }) => {
    if (isLoginFlow) {
      const payload: ILoginReqPayload = {
        accessToken: formData.otp,
        redirectUri: '',
        error: '',
      };
      if (emailToVerify) payload.email = emailToVerify;
      else payload.phoneNumber = phoneNumber;
      dispatch(
        loginReqAction({ payload: { ...payload }, loginType: emailToVerify ? 'email' : 'phone' }),
      )
        .unwrap()
        .then((data) => {
          ApplicationInsightsApi.trackTrace(trackEventName, {
            request: { ...payload },
            response: { ...data },
          });
        })
        .catch((e) => {
          handleLoginError();
          ApplicationInsightsApi.trackException(`${trackEventName} failed with error: ${e}`);
        });
    } else {
      if (phoneNumber) {
        dispatch(
          verifyOtpReqAction({
            phoneNumber: `${phoneNumber}`,
            otp: formData.otp,
            phoneCountryTwoLetterCode: `${phoneCountryTwoLetterCode}`,
            context,
          }),
        )
          .unwrap()
          .then((data: IVerifyOtpResponse) => {
            if (isRegistrationFlow)
              ApplicationInsightsApi.trackEvent('*SignUpPhoneNumberValidated');
            if (onFulfilledVerifyOtp) onFulfilledVerifyOtp(data);
          });
      }
    }
  };

  const resetOtpInput = () => {
    if (resetOtpTimer.current) clearTimeout(resetOtpTimer.current);
    resetOtpTimer.current = setTimeout(() => {
      reset(
        { otp: '' },
        {
          keepDefaultValues: true, // Retain default values
          keepErrors: false, // Clear validation errors
        },
      );
      setFocus('otp'); // Restore focus
    }, 500);
  };

  const resendCodeLoginWithPhone = () => {
    if (phoneNumber && phoneCountryTwoLetterCode) {
      dispatch(
        requestMagicLink({
          payload: {
            phoneNumber: phoneNumber,
            phoneCountryTwoLetterCode: phoneCountryTwoLetterCode,
            redirectUri: `${baseUrl}${APP_ROUTING_PATHS.SSO_MAGIC_LINK}`,
            registrationParameters:
              localStorage.getItem(registrationParametersStorageKey) || undefined,
            isShortCodeRequested: !isMobileOrEmbeddedBrowser,
          },
          api: API_ROUTES.AUTH.REQUEST_MAGIC_LINK_PHONE,
        }),
      );
    }
  };

  const resendCodeLoginWithEmail = () => {
    if (phoneNumber && phoneCountryTwoLetterCode) {
      dispatch(
        requestMagicLink({
          payload: {
            email: emailToVerify || '',
            redirectUri: `${baseUrl}${APP_ROUTING_PATHS.SSO_MAGIC_LINK}`,
            registrationParameters:
              localStorage.getItem(registrationParametersStorageKey) || undefined,
            isShortCodeRequested: !isMobileOrEmbeddedBrowser,
          },
          api: API_ROUTES.AUTH.REQUEST_MAGIC_LINK_EMAIL,
        }),
      );
    }
  };

  const resendCode = () => {
    if (isLoginFlow) {
      if (emailToVerify) resendCodeLoginWithEmail();
      else resendCodeLoginWithPhone();
    } else dispatch(sendOtpReqAction({ phoneNumber: `${phoneNumber}` }));
    setShouldDisplayError(null);
  };

  return (
    <form
      className={`scroll-y-container-hidden-scroll-bar phone-verification-flow phone-verification-form ${className}`}
      id="phone-verification-form"
      onSubmit={handleSubmit(onSubmit)}
      data-testid="phone-verification-form"
    >
      <AuthHeroCard
        mascot={emailToVerify ? emailMascotImg : phoneMascotImg}
        title={title}
        subTitle={subTitle}
      />
      <div className="phone-verification-form-content">
        <p className="phone-verification-text">{t('phoneVerificationTextNewFlow')}</p>
        <p className="data-to-verify">{emailToVerify || phoneNumber}</p>
        <section className="verification-code-controllers-container">
          <p className="enter-code-verification">{t('phoneVerificationEnterCodeText')}</p>
          <Controller
            name="otp"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <OtpInput
                value={value}
                onChange={onChange}
                error={shouldDisplayError ? t(shouldDisplayError) : undefined}
              />
            )}
          />
          <p className="resend-code-verification">
            {t('phoneVerificationResendCodeText')} &nbsp;
            <span onClick={resendCode}>{t('phoneVerificationResendNow')}</span>
          </p>
        </section>
      </div>
      <AppButton
        className="auth-next-btn submit"
        form="phone-verification-form"
        id="phone-verification-form-submit"
        data-testid="phone-verification-form-submit"
        type="submit"
        disabled={!formState.isValid || formState.isSubmitted}
      >
        {submitText || t('submit')}
      </AppButton>
    </form>
  );
};

export default PhoneOrEmailVerificationByOtp;
