import React from 'react';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import type { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export interface IAppInsightsErrorBoundaryProps {
  appInsights: ReactPlugin;
  onError: React.ComponentType<any>;
  children: React.ReactElement;
}
export interface IAppInsightsErrorBoundaryState {
  hasError: boolean;
}
export default class RootErrorBoundary extends React.Component<
  IAppInsightsErrorBoundaryProps,
  IAppInsightsErrorBoundaryState
> {
  constructor(props: IAppInsightsErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ hasError: true });
    this.props.appInsights.trackException({
      error: error,
      exception: error,
      severityLevel: SeverityLevel.Error,
      properties: {
        ...errorInfo,
        localStorage: { ...localStorage },
        sessionStorage: { ...sessionStorage },
      },
    });
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = '/';
  }

  render() {
    if (this.state.hasError) {
      const { onError } = this.props;
      return React.createElement(onError);
    }

    return this.props.children;
  }
}
