import type { CSSProperties, ReactNode } from 'react';
import React, { useState } from 'react';
import './AppSelect.scss';
import { isMobileDevice } from '../../utils/isMobileDevice';

// TODO: on desktop support up and down keys when options are visible

interface Option {
  value: any;
  label: string | ReactNode;
  labelStringForTrackEvent?: string;
  selectedLabel?: string | ReactNode;
  className?: string;
}

interface SelectProps {
  options: Option[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: number | string;
  customStyles?: CSSProperties;
  className?: string;
  placeHolder?: string;
}

export const AppSelect: React.FC<SelectProps> = ({
  options,
  onChange,
  value,
  customStyles,
  className,
  placeHolder,
}) => {
  const [isMobile] = useState<boolean>(isMobileDevice());
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const elements = {
    button: document.querySelector('[role="combobox"]'),
    dropdown: document.querySelector('[role="listbox"]'),
    options: document.querySelectorAll('[role="option"]'),
  };
  let currentOptionIndex = 0;
  let lastTypedChar = '';
  let lastMatchingIndex = 0;

  const handleOptionClick = (value: string) => {
    onChange({ target: { value } } as React.ChangeEvent<HTMLSelectElement>);
    setIsOpen(false);
  };

  const handleSelectClick = () => {
    if (!isMobile) {
      setIsOpen(!isOpen);
      document.getElementById('app-custom-select')?.focus();
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isMobile) return;
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleSelectClick();
      return;
    }
    if (event.key === 'Escape') {
      setIsOpen(false);
      return;
    }
    handleAlphanumericKeyPress(event.key);
  };

  const handleKeyDownOnOption = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (isMobile) return;
    handleAlphanumericKeyPress(event.key);
  };

  const handleOptionKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, optionValue: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleOptionClick(optionValue);
    }
  };

  const focusCurrentOption = () => {
    const currentOption = elements.options[currentOptionIndex];

    currentOption.classList.add('current');
    (currentOption as HTMLOptionElement).focus();

    // Scroll the current option into view
    currentOption.scrollIntoView({
      block: 'nearest',
    });

    elements.options.forEach((option, index) => {
      if (option !== currentOption) {
        option.classList.remove('current');
      }
    });
  };

  const handleAlphanumericKeyPress = (key: string) => {
    try {
      const typedChar = key.toLowerCase();
      if (lastTypedChar !== typedChar) {
        lastMatchingIndex = 0;
      }

      const matchingOptions = Array.from(elements.options).filter((option) =>
        option?.textContent?.toLowerCase().split(' ')[1].startsWith(typedChar),
      );

      if (matchingOptions.length) {
        if (lastMatchingIndex === matchingOptions.length) {
          lastMatchingIndex = 0;
        }
        const value = matchingOptions[lastMatchingIndex];
        const index = Array.from(elements.options).indexOf(value);
        currentOptionIndex = index;
        focusCurrentOption();
        lastMatchingIndex += 1;
      }
      lastTypedChar = typedChar;
    } catch (error) {
      console.error(error);
    }
  };

  const selectArrow = () => {
    return (
      <svg
        className="app-select-arrow"
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="12"
        viewBox="0 0 15 12"
        fill="none"
      >
        <path
          d="M3.75 4.5L7.5 7.5L11.25 4.5"
          stroke="#334C45"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  return (
    <div
      className={`app-select-container${isOpen ? ' app-select-container--open' : ''} ${
        className || ''
      }`}
      style={customStyles}
      onClick={handleSelectClick}
    >
      {selectArrow()}
      {isMobile ? (
        <select
          className={'app-select-element'}
          onChange={onChange}
          value={value}
          style={customStyles}
        >
          <option value={0}>{placeHolder || 'Select'}</option>
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      ) : (
        <div className="app-custom-select">
          <div
            className="app-select-element"
            id="app-custom-select"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            aria-expanded={isOpen}
            role="combobox"
            aria-haspopup="listbox"
            aria-controls="listbox"
          >
            {options.find((opt) => opt.value === value)?.selectedLabel ||
              options.find((opt) => opt.value === value)?.label ||
              placeHolder ||
              'Select'}
          </div>
          {
            <div
              className={`app-custom-options ${isOpen ? ' app-custom-options--open' : ''}`}
              role="listbox"
              id="listbox"
              onKeyDown={handleKeyDownOnOption}
            >
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`app-custom-option ${option.className || ''}`}
                  onClick={() => handleOptionClick(option.value.toString())}
                  onKeyDown={(event) => handleOptionKeyDown(event, option.value.toString())}
                  tabIndex={0}
                  role="option"
                  aria-selected={value === option.value}
                >
                  {option.label}
                </div>
              ))}
            </div>
          }
        </div>
      )}
    </div>
  );
};
