import { useState, useEffect } from 'react';
import Cookie from 'universal-cookie';

interface IUseRenewableCookieProps {
  cookieName: string;
  expirationTimer: number; // in seconds
  renewTime: number; // in milliseconds
  callback: () => Promise<string>;
}

export const useRenewableCookie = ({
  cookieName,
  expirationTimer,
  renewTime,
  callback,
}: IUseRenewableCookieProps): [string | null] => {
  const [cookieValue, setCookieValue] = useState<string | null>(null);
  const cookie = new Cookie();

  useEffect(() => {
    const renewCookie = async () => {
      try {
        const value = await callback();
        cookie.set(cookieName, value, { path: '/', maxAge: expirationTimer });
        setCookieValue(value);
      } catch (err) {
        setCookieValue(null);
      }
    };
    if (cookie.get(cookieName)) setCookieValue(cookie.get(cookieName));

    renewCookie();

    const intervalId = setInterval(renewCookie, renewTime);

    // Cleanup function to clear the interval when the component unmounts or inputs change
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [cookieName, expirationTimer, renewTime]);

  return [cookieValue];
};
