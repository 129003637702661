import './StagePlanner.scss';
import { MyPlan } from '../../../../plan/my-plan/MyPlan';
import { PlanScrollContainer } from '../../../../plan/plan-scroll-container/PlanScrollContainer';
import { MyDay } from '../../../../plan/my-day/MyDay';
import { useAppSelector } from '../../../../../app/store';
import { MyWeek } from '../../../../plan/my-week/MyWeek';
import ProductTourWrapper, {
  EProductTourViewType,
} from '../../../../product-tour/ProductTourWrapper';
import './PlanTour.scss';
import { EPlanViewSelection } from './stagePlanner.interface';

export const StagePlanner = () => {
  const { planView } = useAppSelector((store) => store.StagePlannerReducer);

  return (
    <div className="stage-child-component stage-planner-container">
      <div className="stage-planner-content-container">
        <div className="stage-planner-content-planner-container">
          <PlanScrollContainer className={'plan-container-horizontal'} scrollDirection={'x'}>
            {planView === EPlanViewSelection.MY_PLAN && <MyPlan />}
            {planView === EPlanViewSelection.MY_DAY && <MyDay />}
            {planView === EPlanViewSelection.MY_WEEK && <MyWeek />}
          </PlanScrollContainer>
        </div>
      </div>
      <ProductTourWrapper
        dataAttribute="data-plan-tour"
        className="mini-plan-tour"
        viewType={EProductTourViewType.PLAN_TOUR}
      />
    </div>
  );
};
