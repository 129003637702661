import { useTranslation } from 'react-i18next';
import { ReactComponent as ScheduleSvgComponent } from '../../../../../../../assets/images/task-details/task-details-due-date-icon.svg';
import { ReactComponent as BellIconComponent } from '../../../../../../../assets/images/task-bell-icon.svg';
import '../../../stage-tasks/create-or-edit-task/scheduling-section/SchedulingSection.scss';
import { useFormContext } from 'react-hook-form';
import type { IWorkBlockFormControllerProps, IWorkBlockFormData } from '../../workBlock.interface';
import {
  getDateFormatted,
  getTimeRange,
} from '../../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import { TaskEditOverlay } from '../../../stage-tasks/task-edit-overlays/TaskEditOverlay';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import type { IRecurrenceValues } from '../../../stage-tasks/stageTasks.interface';
import { editOverlaysPositionStyle } from '../../../stage-tasks/create-or-edit-task/CreateOrEditTask.utils';
import type { FunctionComponent } from 'react';
import './WorkBlockScheduling.scss';
import { ECloseSwipeDirection } from '../../../../../../../shared/hooks/swipe-hooks/swipe.utils';

const WorkBlockScheduling: FunctionComponent<IWorkBlockFormControllerProps> = ({
  onChangeFields,
}) => {
  const { t } = useTranslation();
  const workBlockForm = useFormContext<IWorkBlockFormData>();
  const startTimeWatch = workBlockForm.watch().workTime;
  const durationWatch = workBlockForm.watch().duration;
  const reminderWatch = workBlockForm.watch().workTimeReminder;
  const recurrenceTypeWatch = workBlockForm.watch().workTimeRecurrenceType;
  const recurrenceIntervalWatch = workBlockForm.watch().workTimeRecurrenceInterval;
  const recurrenceWeekDaysWatch = workBlockForm.watch().workTimeRecurrenceWeekDays;

  const onChangeWorkBlockSchedulingData = (
    date: Date,
    reminder?: number | null,
    duration?: number | null,
    recurrenceValues?: IRecurrenceValues,
  ) => {
    const fieldsToUpdate: IWorkBlockFormData = {
      workTime: date ? date.toISOString() : null,
      workTimeReminder: !!reminder || reminder === 0 ? reminder : null,
      duration: duration != null && duration !== undefined ? duration : null,
      workTimeRecurrenceType: recurrenceValues?.workTimeRecurrenceType || null,
      workTimeRecurrenceInterval: recurrenceValues?.workTimeRecurrenceInterval || null,
      workTimeRecurrenceWeekDays: recurrenceValues?.workTimeRecurrenceWeekDays || null,
    };
    onChangeFields(fieldsToUpdate);
  };

  const onOpenSchedulingOverlay = (e: React.MouseEvent<HTMLElement>) => {
    createAppOverlayPopover(
      <TaskEditOverlay
        overlayType="workTime"
        duration={durationWatch}
        date={startTimeWatch ? new Date(startTimeWatch) : undefined}
        reminder={reminderWatch}
        recurrenceValues={{
          workTimeRecurrenceType: recurrenceTypeWatch || null,
          workTimeRecurrenceInterval: recurrenceIntervalWatch || null,
          workTimeRecurrenceWeekDays: recurrenceWeekDaysWatch || null,
        }}
        isWorkBlock={true}
        onChange={(date, reminder, duration, recurrenceValues) =>
          onChangeWorkBlockSchedulingData(date as Date, reminder, duration, recurrenceValues)
        }
      />,
      null,
      e,
      editOverlaysPositionStyle,
      {
        // overlay config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.FULL_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
      },
    );
  };

  const getScheduleDataString = () => {
    if (startTimeWatch) {
      const startTimeDate = new Date(startTimeWatch);
      return `${getDateFormatted(startTimeDate)} ${getTimeRange(
        startTimeDate,
        durationWatch || null,
      )}`;
    }
  };

  return (
    <div className="scheduling-section work-block-controller">
      <h4 className="create-or-edit-sub-title">{t('workBlockDetailsSchedulingControllerTitle')}</h4>
      <div
        onClick={onOpenSchedulingOverlay}
        className={`scheduling-section-data-container scheduling-section-data-container--${
          startTimeWatch ? 'with-value' : 'without-value'
        }`}
      >
        <div className="scheduling-icon-and-label-container">
          <ScheduleSvgComponent
            className={`scheduling-icon task-detail-icon ${
              startTimeWatch ? 'task-detail-icon--active' : ''
            }`}
          />
          <p>
            {startTimeWatch ? getScheduleDataString() : t('createOrEditEventWorkTimeControlText')}
          </p>
        </div>
        {(!!reminderWatch || reminderWatch === 0) && (
          <div className={`bell-reminder ${!startTimeWatch && 'visibility-hidden'}`}>
            <BellIconComponent className={`task-bell task-detail-icon task-detail-icon--right`} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkBlockScheduling;
