// The custom preview using usePreview() (inside DraggedDestinationPreview component) is displayed together with the default preview in desktop.

import type { ReactElement } from 'react';
import { useEffect, useRef } from 'react';
import type { ConnectableElement, DragPreviewOptions } from 'react-dnd';

// This component return a transparent element for  on desktop so the custom preview is displayed alone
export const TransparentDefaultPreview = ({ dragPreview }: ITransparentDefaultPreview) => {
  const transparentRef = useRef(null);

  useEffect(() => {
    if (transparentRef.current) {
      dragPreview(transparentRef.current);
    }
  }, [dragPreview]);

  return (
    <div
      ref={transparentRef}
      style={{
        position: 'fixed',
        top: -9999,
        left: -9999,
        width: 0,
        height: 0,
        opacity: 0,
      }}
    />
  );
};

interface ITransparentDefaultPreview {
  dragPreview: (
    elementOrNode: ConnectableElement,
    options?: DragPreviewOptions | undefined,
  ) => ReactElement | null;
}
