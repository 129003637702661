import { Navigate } from 'react-router-dom';
import { APP_ROUTING_PATHS } from '../constants';
import { useAppSelector } from '../store';
import { handleLocalLogOut } from '../../shared/utils/logOut';
import { formatUrlWithParams } from '../../shared/utils/routingUtils';

export const Redirect = () => {
  const { user } = useAppSelector((store) => store.userReducer);
  const hasAllNeededKeys =
    user && user.hasOwnProperty('isActive') && user.hasOwnProperty('canRegister');
  const { loginRes } = useAppSelector((store) => store.authReducer);

  if (user && !hasAllNeededKeys) handleLocalLogOut();

  const redirect = (): JSX.Element => {
    const shouldLink = loginRes.data?.shouldLink;

    switch (true) {
      case user && !user?.canRegister:
        return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.WAIT_LIST)} />;
      case user && shouldLink:
        return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.LINK_ACCOUNTS)} />;
      case user && !user?.phoneNumber:
        return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.ADD_PHONE_REGISTER)} />;
      case user && !!user?.phoneNumber:
        return (
          <Navigate
            to={`${formatUrlWithParams(`${APP_ROUTING_PATHS.HOME}/${APP_ROUTING_PATHS.CHAT}`)}`}
          />
        );
      default:
        return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.PAGE_404)} />;
    }
  };

  return redirect();
};
