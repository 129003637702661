import type { FunctionComponent } from 'react';
import React from 'react';
import type { IAddEditTaskForm, ITaskFields } from '../stageTasks.interface';
import priorityStarOnIcon from '../../../../../../assets/images/priority-star-on.svg';
import priorityStarOffIcon from '../../../../../../assets/images/priority-star-off.svg';
import quickWinsOnIcon from '../../../../../../assets/images/quick-win-on.svg';
import quickWinsOffIcon from '../../../../../../assets/images/quick-win-off.svg';
import { useFormContext } from 'react-hook-form';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

interface IPriorityAndQuickWinsSectionProps {
  onUpdateTask: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  priorityWatch: boolean;
  quickWinWatch: boolean;
  className?: string;
}

const PriorityAndQuickWinsSection: FunctionComponent<IPriorityAndQuickWinsSectionProps> = ({
  onUpdateTask,
  priorityWatch,
  quickWinWatch,
  className,
}) => {
  const taskForm = useFormContext<IAddEditTaskForm>();

  return (
    <section className={`priority-and-quick-win ${className}`}>
      <AppButton
        type="button"
        id="task-form-quickWin-toggle"
        className="quick-win"
        onClick={() => {
          taskForm.setValue('quickWin', !quickWinWatch);
          onUpdateTask({ quickWin: !quickWinWatch });
        }}
        shouldChangeBackgroundOnActive={false}
      >
        <img src={quickWinWatch ? quickWinsOnIcon : quickWinsOffIcon} alt="quick-win" />
      </AppButton>
      <AppButton
        type="button"
        id="task-from-priority-toggle"
        className="priority"
        onClick={() => {
          taskForm.setValue('priority', !priorityWatch);
          onUpdateTask({ priority: !priorityWatch });
        }}
        shouldChangeBackgroundOnActive={false}
      >
        <img src={priorityWatch ? priorityStarOnIcon : priorityStarOffIcon} alt="priority" />
      </AppButton>
    </section>
  );
};

export default PriorityAndQuickWinsSection;
