import type { FunctionComponent } from 'react';
import { memo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { DislikeFeedbackForm } from '../../../../chat.interfaces';

export interface IDislikeTagProps {
  option: string;
}

const DislikeTag: FunctionComponent<IDislikeTagProps> = ({ option }) => {
  const { register, getValues, setValue } = useFormContext<DislikeFeedbackForm>();
  const checkboxRef = useRef<HTMLInputElement | null>(null);
  const { ref } = register('feedbackTags');

  // for accessibility - when a user focuses on the label with the keyboard and presses the space key, toggle the checkbox.
  const handleLabelKeyPress = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.code.toLowerCase() === 'space' && checkboxRef.current) {
      event.preventDefault();
      setValue(
        'feedbackTags',
        checkboxRef.current.checked
          ? getValues('feedbackTags').filter((tagName: string) => tagName !== option)
          : [...getValues('feedbackTags'), option],
      );
    }
  };

  return (
    <div className="option-container">
      <label tabIndex={0} onKeyDown={(e) => handleLabelKeyPress(e)}>
        <input
          tabIndex={-1}
          type="checkbox"
          {...register(`feedbackTags`, { required: false })}
          value={option}
          data-testid={option}
          ref={(e) => {
            ref(e);
            checkboxRef.current = e;
          }}
        />
        {option}
      </label>
    </div>
  );
};
export default memo(DislikeTag);
