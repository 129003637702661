import { minutesToHoursAndMinutesStringRepresentation } from '../../../../../../shared/utils/utils';
import type { IRecurrenceValues } from '../stageTasks.interface';
import { ETaskRecurrenceType } from '../stageTasks.interface';
import type { ITaskEditorOverlayType } from './TaskEditOverlay';
import { getDayName } from '../../../../../../shared/utils/dateFormat';

export const reminderCustomOptions = [
  {
    name: '5m',
    value: getTimeInSeconds('minute', 5),
  },
  {
    name: '10m',
    value: getTimeInSeconds('minute', 10),
  },
  {
    name: '15m',
    value: getTimeInSeconds('minute', 15),
  },
  {
    name: '30m',
    value: getTimeInSeconds('minute', 30),
  },
  {
    name: '1 hour',
    value: getTimeInSeconds('hour', 1),
  },
  {
    name: '2 hour',
    value: getTimeInSeconds('hour', 2),
  },
  {
    name: '3 hour',
    value: getTimeInSeconds('hour', 3),
  },
  {
    name: '1 day',
    value: getTimeInSeconds('day', 1),
  },
  {
    name: '2 days',
    value: getTimeInSeconds('day', 2),
  },
  {
    name: '3 days',
    value: getTimeInSeconds('day', 3),
  },
  {
    name: '1 week',
    value: getTimeInSeconds('week', 1),
  },
  {
    name: '2 weeks',
    value: getTimeInSeconds('week', 2),
  },
  {
    name: '1 month',
    value: getTimeInSeconds('month', 1),
  },
];

export function getTimeInSeconds(
  type: 'minute' | 'hour' | 'day' | 'week' | 'month',
  quantity: number,
): number {
  const secondsInAMinute = 60;
  const secondsInAnHour = secondsInAMinute * 60;
  const secondsInADay = secondsInAnHour * 24;
  const secondsInAWeek = secondsInADay * 7;
  const secondsInAMonth = secondsInADay * 30; // Average month length

  switch (type) {
    case 'minute':
      return quantity * secondsInAMinute;
    case 'hour':
      return quantity * secondsInAnHour;
    case 'day':
      return quantity * secondsInADay;
    case 'week':
      return quantity * secondsInAWeek;
    case 'month':
      return quantity * secondsInAMonth;
    default:
      return quantity * secondsInAMinute;
  }
}

// adjust the date to subtract one day and then set the hours to 19 (7PM)
export function calculateEveningBeforeRelativeDate(relativeDate: Date) {
  const localRelativeDate = new Date(relativeDate);
  const nightBefore = new Date(relativeDate);

  // Subtract one day
  nightBefore.setDate(localRelativeDate.getDate() - 1);

  // Set to 7 PM
  nightBefore.setHours(19, 0, 0, 0); // 7 PM, 0 minutes, 0 seconds, 0 milliseconds

  return nightBefore;
}

export function calculateMorningOfRelativeDate(relativeDate: Date) {
  const morningOfDate = new Date(relativeDate);

  // Set to 7 AM on the same day
  morningOfDate.setHours(7, 0, 0, 0); // 7 AM, 0 minutes, 0 seconds, 0 milliseconds

  return morningOfDate;
}

export function calculateMinutesBeforeRelativeDate(
  relativeDate: Date,
  minutesBeforeAmount: number,
) {
  const localRelativeDate = new Date(relativeDate);
  const relativeDateMinutesBefore = new Date(relativeDate);

  // Subtract {minutesBeforeAmount} minutes
  relativeDateMinutesBefore.setMinutes(localRelativeDate.getMinutes() - minutesBeforeAmount);

  return relativeDateMinutesBefore;
}

export function secondsFromEveningBefore(relativeDate: Date): number {
  const localRelativeDate = new Date(relativeDate);
  const eveningBefore = new Date(relativeDate);

  // Adjust to the evening before at 7 PM
  eveningBefore.setDate(localRelativeDate.getDate() - 1);
  eveningBefore.setHours(19, 0, 0, 0); // Set to 7 PM, 0 minutes, 0 seconds, 0 milliseconds

  // Calculate the difference in milliseconds and convert to seconds
  const differenceInSeconds = (localRelativeDate.getTime() - eveningBefore.getTime()) / 1000;

  return differenceInSeconds;
}

export function secondsFromMorning(relativeDate: Date): number {
  const localRelativeDate = new Date(relativeDate);
  const morningOfDate = new Date(relativeDate);

  // Set to 7 AM on the same day
  morningOfDate.setHours(7, 0, 0, 0); // Set to 7 AM, 0 minutes, 0 seconds, 0 milliseconds

  // Calculate the difference in milliseconds and convert to seconds
  // If the date is before 7 AM, this value will be negative, indicating time until 7 AM
  const differenceInSeconds = (localRelativeDate.getTime() - morningOfDate.getTime()) / 1000;

  return differenceInSeconds;
}

export function isTheReminderInSameDay(relativeDate: Date, reminderInMinutes: number): boolean {
  const localRelativeDate = new Date(relativeDate);
  const newDate = calculateMinutesBeforeRelativeDate(localRelativeDate, reminderInMinutes);
  // Compare days
  return (
    newDate.getMonth() === localRelativeDate.getMonth() &&
    newDate.getDate() === localRelativeDate.getDate()
  );
}
// TODO: move all strings to translation files
export const getReminderCalendarText = (
  reminderInSeconds: number | null | undefined,
  overlayType: ITaskEditorOverlayType,
  relativeDate: Date | undefined,
) => {
  if (reminderInSeconds === null || reminderInSeconds === undefined || !relativeDate) return null;
  if (reminderInSeconds === 0) return 'At start';
  const reminderFromCustomOptions = reminderCustomOptions.find(
    (option) => option.value === reminderInSeconds,
  );
  if (reminderFromCustomOptions)
    return `${reminderFromCustomOptions.name} ${
      overlayType === 'dueDate' ? 'before this due' : 'before start'
    }`;
  if (secondsFromMorning(relativeDate) === reminderInSeconds) return 'Morning of (7:00AM)';
  if (secondsFromEveningBefore(relativeDate) === reminderInSeconds) return 'Night Before (7:00 PM)';
  return `${minutesToHoursAndMinutesStringRepresentation(reminderInSeconds / 60)} ${
    overlayType === 'dueDate' ? 'before this due' : 'before start'
  }`;
};

export const getRecurringFrequencyText = (recurrenceValues?: IRecurrenceValues): string => {
  const RecurrenceIntervalStr =
    !!recurrenceValues?.workTimeRecurrenceInterval &&
    recurrenceValues?.workTimeRecurrenceInterval !== 1
      ? recurrenceValues?.workTimeRecurrenceInterval
      : '';
  switch (recurrenceValues?.workTimeRecurrenceType) {
    case ETaskRecurrenceType.Daily:
      return `Every ${RecurrenceIntervalStr ? RecurrenceIntervalStr + ' Days' : 'Day'}`;
    case ETaskRecurrenceType.Monthly:
      return `Every ${RecurrenceIntervalStr ? RecurrenceIntervalStr + ' Months' : 'Month'}`;
    case ETaskRecurrenceType.Weekly:
      if (
        recurrenceValues?.workTimeRecurrenceWeekDays &&
        recurrenceValues?.workTimeRecurrenceWeekDays.length > 0
      ) {
        if (
          recurrenceValues?.workTimeRecurrenceWeekDays.length === 5 &&
          !recurrenceValues?.workTimeRecurrenceWeekDays.includes(0) &&
          !recurrenceValues?.workTimeRecurrenceWeekDays.includes(6)
        ) {
          return `Every ${RecurrenceIntervalStr ? RecurrenceIntervalStr + ' ' : ''}Mon-Fri`;
        }
        let daysNameStr = '';
        // get "Sun, Mon"
        recurrenceValues?.workTimeRecurrenceWeekDays.forEach((dayIndx, i) => {
          daysNameStr += `${i !== 0 ? ', ' : ''}${getDayName(dayIndx)}`;
        });
        return `Every ${RecurrenceIntervalStr ? RecurrenceIntervalStr + ' ' : ''}${daysNameStr}`;
      }
      return `Every ${RecurrenceIntervalStr ? RecurrenceIntervalStr + ' Weeks' : 'Week'}`;
    default:
      return '';
  }
};

export enum EComponentStep {
  PRESET_OPTIONS = 1,
  CUSTOM_SELECTOR = 2,
  REMINDER_PRESET_OPTIONS = 3,
  REMINDER_CUSTOM_CUSTOM_SELECTOR = 4,
  DURATION_PRESET_OPTIONS = 5,
  DURATION_CUSTOM_SELECTOR = 6,
  RECURRENCE_PRESET_OPTIONS,
  RECURRENCE_CUSTOM_SELECTOR,
}

export const recurrenceTypeCustomOptions = [
  {
    name: 'Week',
    value: ETaskRecurrenceType.Weekly,
  },
  {
    name: 'Day',
    value: ETaskRecurrenceType.Daily,
  },
  {
    name: 'Month',
    value: ETaskRecurrenceType.Monthly,
  },
];

export const maxRecurrenceInterval = 10;
// [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
export const recurrenceIntervalCustomOptions = Array.from(
  { length: maxRecurrenceInterval },
  (_, i) => i + 1,
);

export interface ITaskWorkBlockDetailsEditOverlay {
  workBlockId?: string | null;
  workBlockInstance?: string | Date | null;
}
