import type { FunctionComponent } from 'react';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './ConfirmModal.scss';
import AppButton from '../app-button/AppButton';

export interface IConfirmModalProps {
  title: string;
  informationText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
  confirmBtnText?: string;
  className?: string;
  isDisabled?: boolean;
}

export const ConfirmModal: FunctionComponent<IConfirmModalProps> = ({
  title,
  informationText,
  onCancel,
  onConfirm,
  confirmBtnText,
  className = '',
  isDisabled = false,
}) => {
  const { t } = useTranslation();

  // close the modal when clicking on the 'Escape' keyboard key
  const handleKeyup = useCallback(
    (e: unknown): void => {
      if ((e as React.KeyboardEvent).code === 'Escape' && !!onCancel) onCancel();
    },
    [onCancel],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyup);

    return () => {
      document.removeEventListener('keydown', handleKeyup);
    };
  }, [handleKeyup]);

  return (
    <>
      <div className="confirm-modal-background"></div>
      <div className={`confirm-modal fadeIn ${className}`} data-testid="confirm-modal">
        <div className="confirm-modal-content">
          <h4 className="confirm-modal-title">{title}</h4>
          {informationText && <p className="confirm-modal-info">{informationText}</p>}
        </div>
        <div className="confirm-modal-footer">
          {onCancel && (
            <AppButton
              type="button"
              id="cancel-button-modal"
              className="confirm-modal-cancel"
              data-testid="cancel-button-modal"
              onClick={onCancel}
            >
              {t('confirmModalCancelButtonText')}
            </AppButton>
          )}
          {onConfirm && (
            <AppButton
              type="button"
              disabled={isDisabled}
              id="confirm-button-modal"
              className="confirm-modal-confirm"
              data-testid="confirm-button-modal"
              onClick={onConfirm}
            >
              {confirmBtnText || t('confirmModalConfirmButtonText')}
            </AppButton>
          )}
        </div>
      </div>
    </>
  );
};
