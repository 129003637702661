import { ApplicationInsightsApi } from '../../application-insights';
import {
  EClientFlags,
  EUserSettingsFlags,
} from '../components/content-frame-wrapper/settings-menu/SettingsMenu.interfaces';

export class FlagUtils {
  // Static method to set (turn on) a specific flag
  static setFlag(flags: number, flag: EClientFlags | EUserSettingsFlags): number {
    // log name of flag
    ApplicationInsightsApi.trackTrace(
      `Set client flag: ${EClientFlags[flag]}, current flags: ${flags}`,
    );
    return flags | flag;
  }

  // Static method to unset (turn off) a specific flag
  static unsetFlag(flags: number, flag: EClientFlags | EUserSettingsFlags): number {
    ApplicationInsightsApi.trackTrace(
      `Unset client flag: ${EClientFlags[flag]}, current flags: ${flags}`,
    );
    return flags & ~flag;
  }

  // Static method to get the flag position (on -> true, off -> false)
  static getFlag(flags: number, flag: EClientFlags | EUserSettingsFlags): boolean {
    return (flags & flag) !== 0;
  }

  // Static method to toggle a specific flag
  static toggleFlag(flags: number, flag: EClientFlags | EUserSettingsFlags): number {
    // log name of flag
    ApplicationInsightsApi.trackTrace(
      `Toggle client flag: ${EClientFlags[flag]}, current flags: ${flags}`,
    );
    // The XOR operation will flip the bit
    return flags ^ flag;
  }
}
