import { useTranslation } from 'react-i18next';
import backIcon from '../../../../../../assets/images/thicker-2px-stroke-back-icon.svg';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import './TaskEditOverlayHeaderAndAction.scss';

interface TaskEditOverlayHeaderAndActionProps {
  onSet: () => void;
  onClear?: () => void;
  onClickBackArrow?: (() => void) | null;
  headerText?: string;
  shouldHideSet?: boolean;
}

const TaskEditOverlayHeaderAndAction = ({
  onSet,
  onClear,
  onClickBackArrow,
  headerText,
  shouldHideSet,
}: TaskEditOverlayHeaderAndActionProps) => {
  const { t } = useTranslation();
  return (
    <div className="task-edit-overlay-header-container">
      <section className="title-and-back-section">
        {!!onClickBackArrow && (
          <AppButton
            id="edit-overlay-header-back-button"
            type="button"
            className="task-edit-overlay-header-back"
            onClick={onClickBackArrow}
          >
            <img src={backIcon} alt="back" />
          </AppButton>
        )}
        {headerText && (
          <h4 className="task-edit-overlay-header-text static-string">{headerText}</h4>
        )}
      </section>
      <div className="task-edit-overlay-header-actions-container">
        {!!onClear && (
          <AppButton
            id="edit-overlay-header-clear-button"
            type="button"
            className="task-edit-overlay-header-action-button task-edit-overlay-header-action-button--clear"
            onClick={onClear}
            aria-label="Clear"
          >
            {t('taskDetailsOverlaysClearButtonText')}
          </AppButton>
        )}
        {!shouldHideSet && (
          <AppButton
            id="edit-overlay-header-set-button"
            type="button"
            className="task-edit-overlay-header-action-button task-edit-overlay-header-action-button--set"
            onClick={onSet}
            aria-label="Set"
          >
            {t('taskDetailsOverlaysSaveButtonText')}
          </AppButton>
        )}
      </div>
    </div>
  );
};

export default TaskEditOverlayHeaderAndAction;
