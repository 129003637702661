import { useTranslation } from 'react-i18next';
import closeIcon from '../../../../../../../assets/images/close-popup.svg';
import './TimePickerContext.scss';
import React from 'react';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';
import { timePickerContextOverlayDefaultWidth } from '../../../../../../../app/constants';

interface IProps {
  onClose: () => void;
  taskName: string;
  parentWidth?: number;
  onSet?: React.ReactNode;
  selectedTimeStr?: string | null;
}

export const TimePickerContext = ({
  onClose,
  taskName,
  parentWidth = timePickerContextOverlayDefaultWidth,
  onSet,
  selectedTimeStr,
}: IProps) => {
  const { t } = useTranslation();
  return (
    <div className="time-picker-context-container">
      <div className="time-picker-context-close-container">
        <AppButton
          id="close-time-picker-popup"
          onClick={onClose}
          className="time-picker-close-button no-style-button"
        >
          <img src={closeIcon} alt={t('close')} />
        </AppButton>
      </div>
      <div className="time-picker-context-contend-container">
        <span className="time-picker-context-status static-string">
          {selectedTimeStr ? t('myPlanDayScheduled') : t('pickTime')}
        </span>
        <p className="time-picker-context-name">{taskName}</p>
        {!!selectedTimeStr && <p className="time-picker-selected-time">{selectedTimeStr}</p>}
      </div>
      {onSet && (
        <div
          className="time-picker-context-action-container"
          style={{ top: 20, left: parentWidth - 70 }}
        >
          {onSet}
        </div>
      )}
    </div>
  );
};
