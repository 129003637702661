import { Link } from 'react-router-dom';
import { ContentFrameWrapper } from '../../../../shared/components/content-frame-wrapper/ContentFrameWrapper';
import { APP_ROUTING_PATHS } from '../../../constants';
import { useTranslation } from 'react-i18next';
import { handleLogOut } from '../../../../shared/utils/logOut';
import './WaitList.scss';
import waitListImage from '../../../../assets/images/stage-tasks-empty-state.png';
import { ContactUs } from '../contact-us/ContactUs';
import { useAppSelector } from '../../../store';

export const WaitList = () => {
  const { t } = useTranslation();
  const { user: userInfo } = useAppSelector((store) => store.userReducer);

  return (
    <div className="wait-list-page auth-screen">
      <ContentFrameWrapper>
        <div className="wait-list-content-container">
          <div className="wait-list-content">
            <h1 data-testid="wait-list-title auth-title" className="wait-list-title">
              {t('waitListTitle')}
            </h1>
            <div className="image-container">
              <img src={waitListImage} alt={t('waitListImageAltText')} />
            </div>
            <p className="wait-list-paragraph">{t('waitListText')}</p>
            <div className="wait-list-email" data-testid="user-email">
              {userInfo?.email ?? ''}
            </div>
          </div>
          <ContactUs />
        </div>
      </ContentFrameWrapper>
      <Link
        to={APP_ROUTING_PATHS.SIGN_UP}
        data-testid="stay-logged-in-back"
        onClick={() => handleLogOut()}
        className="back-link"
      >
        {t('registerScreenBackButtonText')}
      </Link>
    </div>
  );
};
