let scrollTimer: NodeJS.Timeout | null = null;

export const createCssVhVariableByViewportHeight = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const viewportHeight = window.visualViewport ? window.visualViewport.height : window.innerHeight;
  const vh = viewportHeight * 0.01;
  // Then we set the value in the --availableViewportHeight custom property to the root of the document
  document.documentElement.style.setProperty('--availableViewportHeight', `${vh}px`);
  // This variable is used to calculate the offset height of the window when the keyboard is opened. The offset will be the entire window height(this var) minus the viewport height
  const maxWindowHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );
  // calc offset-h by visualViewport height to use in position:fixed bottom property https://github.com/adamjgrant/a-viewport-that-works
  document.documentElement.style.setProperty('--offset-h', `${maxWindowHeight - viewportHeight}px`);
  // when keyboard open scroll to the last message
  if (scrollTimer) clearTimeout(scrollTimer);
  scrollTimer = setTimeout(() => {
    const chatEl = document.querySelector('.chat-container .conversation');
    chatEl?.scrollTo(0, chatEl?.scrollHeight);
  }, 150);
};
