import createAppOverlayPopover from '../../app-overlay-popover/createAppOverlayPopover';
import type { IAppPopUpToastProps } from '../AppPopUpToast';
import AppPopUpToast from '../AppPopUpToast';
import removeAppOverlayPopover from '../../app-overlay-popover/removeAppOverlayPopover';
import { store } from '../../../../app/store';
import { FlagUtils } from '../../../utils/flagUtils';

export const openAppToastPopUp = (appPopUpToastComponentProps: IAppPopUpToastProps) => {
  const dontShowAgainFlag = appPopUpToastComponentProps.dontShowAgainFlag;
  const user = store.getState().userReducer.user;

  // if the dontShowAgainFlag props is true -> return
  if (
    !!dontShowAgainFlag &&
    (!!user?.clientFlags || user?.clientFlags === 0) &&
    FlagUtils.getFlag(user.clientFlags, dontShowAgainFlag)
  ) {
    !!appPopUpToastComponentProps.onClose && appPopUpToastComponentProps.onClose();
    return;
  }

  createAppOverlayPopover(
    <AppPopUpToast
      {...appPopUpToastComponentProps}
      onClose={() => {
        !!appPopUpToastComponentProps.onClose && appPopUpToastComponentProps.onClose();
        removeAppOverlayPopover();
      }}
    />,
    null,
    null,
    undefined,
    { isAppToast: true },
  );
};
