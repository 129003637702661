import { useEffect, useRef, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { getTasksListPollingReqAction } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import { selectIsAuthenticated } from '../../../app/auth/auth.store';
import { EAPIStatus } from '../../api/models';

const POLLING_INTERVAL_MS = 5000;
const USER_INACTIVITY_TIMEOUT_MS = 60000; // User considered inactive after 60 seconds of no interaction

export const PollingHandler = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const { tasksListPollingResponse } = useAppSelector((store) => store.StageTasksReducer);
  const pollingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const userActivityTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isActive, setIsActive] = useState(true); // Tracks both visibility and user interaction

  const fetchTasks = useCallback(() => {
    if (!isAuthenticated) return;
    if (sessionResponse.status === EAPIStatus.PENDING) return;
    if (tasksListPollingResponse.status === EAPIStatus.PENDING) return;
    dispatch(getTasksListPollingReqAction());
  }, [dispatch, sessionResponse.status, tasksListPollingResponse.status]);

  // Handle visibility changes
  const handleVisibilityChange = () => {
    setIsActive(!document.hidden);
  };

  // Handle user interaction
  const resetUserActivityTimeout = () => {
    setIsActive(true); // Set user as active on interaction

    if (userActivityTimeoutRef.current) {
      clearTimeout(userActivityTimeoutRef.current);
    }

    // Set a timeout to mark user as inactive if no interaction happens
    userActivityTimeoutRef.current = setTimeout(() => {
      setIsActive(false);
    }, USER_INACTIVITY_TIMEOUT_MS);
  };

  const startPolling = useCallback(() => {
    if (pollingIntervalRef.current) return; // Prevent multiple intervals
    pollingIntervalRef.current = setInterval(fetchTasks, POLLING_INTERVAL_MS);
  }, [fetchTasks]);

  const stopPolling = useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated && isActive) {
      startPolling();
    } else {
      stopPolling();
    }

    return () => {
      stopPolling();
    };
  }, [isAuthenticated, isActive, fetchTasks, startPolling, stopPolling]);

  // Add listeners for user interaction and visibility changes
  useEffect(() => {
    // Visibility API
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // User interaction tracking
    const events = ['mousemove', 'mousedown', 'keydown', 'touchstart'];
    events.forEach((event) => window.addEventListener(event, resetUserActivityTimeout));

    // Initial user activity timeout setup
    resetUserActivityTimeout();

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      events.forEach((event) => window.removeEventListener(event, resetUserActivityTimeout));

      if (userActivityTimeoutRef.current) {
        clearTimeout(userActivityTimeoutRef.current);
      }
    };
  }, []);

  return null;
};
