import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../app/store';
import {
  setSelectedMainTaskForEditing,
  setShouldOpenAddEditTaskFrom,
} from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.store';
import removeAppOverlayPopover from '../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { ETaskFormType } from '../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import {
  setShouldOpenWorkBlockDetails,
  setWorkBlockForEdit,
} from '../../../chat-wrapper/resizable-container/stage-container/work-block-details/workBlock.store';

interface IPlusBtnOverlaySingleOption {
  type: 'task' | 'event' | 'workBlock';
  SvgComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const PlusBtnOverlaySingleOption: FunctionComponent<IPlusBtnOverlaySingleOption> = ({
  type,
  SvgComponent,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getTitle = () => {
    if (type === 'task') return t('plannerPlusOverlayTaskOptionTitle');
    else if (type === 'event') return t('plannerPlusOverlayEventOptionTitle');
    return t('plannerPlusOverlayWorkBlockOptionTitle');
  };

  const onClickOption = () => {
    if (type === 'workBlock') {
      dispatch(setWorkBlockForEdit(null));
      dispatch(setShouldOpenWorkBlockDetails(true));
    } else {
      dispatch(setSelectedMainTaskForEditing(null));
      dispatch(
        setShouldOpenAddEditTaskFrom(type === 'task' ? ETaskFormType.Task : ETaskFormType.Event),
      );
    }
    removeAppOverlayPopover({
      shouldSlideOutMobileAnimation: true,
      shouldFadeOutDesktopAnimation: true,
    });
  };

  return (
    <section
      className={`plus-overlay-single-option plus-overlay-single-option--${type}`}
      onClick={onClickOption}
    >
      <h4 className="single-option-title">{getTitle()}</h4>
      <SvgComponent />
    </section>
  );
};

export default PlusBtnOverlaySingleOption;
