import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { discordLinkForOpenUsers } from '../../../app/constants';
import './DiscordLink.scss';
import { openExternalLinkOnMobileApp } from '../../../mobile-application-utils';

const DiscordLink: FunctionComponent<{ className?: string; textFollowTheDiscordLink?: string }> = ({
  className,
  textFollowTheDiscordLink,
}) => {
  const { t } = useTranslation();

  return (
    <section className={`discord-link ${className}`}>
      <span>🗣 </span>
      <a
        href={discordLinkForOpenUsers}
        target="_blank"
        onClick={(e) => openExternalLinkOnMobileApp(e, discordLinkForOpenUsers)}
        rel="noreferrer"
        data-testid="discord-link"
      >
        {t('joinOurDiscordLinkPart1')}{' '}
      </a>
      {textFollowTheDiscordLink || t('joinOurDiscordLinkPart2')}
    </section>
  );
};
export default DiscordLink;
