import type { CSSProperties, FunctionComponent, ReactNode } from 'react';
import { isMobileDevice } from '../../../../shared/utils/isMobileDevice';
import useLongPress from '../../../../shared/hooks/useLongPress';
import { calendarHourLineElementClassName } from '../../../../app/constants';

interface ICalendarHourLineElement {
  className?: string;
  keyComponent: number;
  style: CSSProperties;
  children?: ReactNode;
  handleClickOnHourLine: (hours: number, minutes: number) => void;
  lineIndex: number;
  isHalfHour?: boolean;
  onOpenCreateOverlay: (hours: number, minutes: number) => void;
  dayIndx: number;
}

const CalendarHourLineElement: FunctionComponent<ICalendarHourLineElement> = ({
  className = '',
  keyComponent,
  style,
  children,
  handleClickOnHourLine,
  lineIndex,
  onOpenCreateOverlay,
  isHalfHour = false,
  dayIndx,
}) => {
  const longPressBind = useLongPress();

  return (
    <div
      key={keyComponent}
      className={`${calendarHourLineElementClassName} ${className} dayIndx-${dayIndx}`}
      data-hour={lineIndex % 24}
      data-minutes={isHalfHour ? 30 : 0}
      style={{ ...style }}
      onClick={() => handleClickOnHourLine(lineIndex % 24, isHalfHour ? 30 : 0)}
      onDoubleClick={() =>
        !isMobileDevice() && onOpenCreateOverlay(lineIndex % 24, isHalfHour ? 30 : 0)
      }
      {...longPressBind(() => onOpenCreateOverlay(lineIndex % 24, isHalfHour ? 30 : 0))}
    >
      {children}
    </div>
  );
};

export default CalendarHourLineElement;
