import type { EnhancedStore } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { AxiosInstance, AxiosResponse } from 'axios';
import axios from 'axios';
import type { IAPIError } from './models';
import {
  API_ROUTES,
  loginTypeStorageKey,
  operatingSystemLocalStorageKey,
  refreshTokenLocalStorageKey,
  tokenLocalStorageKey,
} from '../../app/constants';
import type { ILoginResponse } from '../../app/auth/auth.interfaces';
import { EOperatingSystem } from '../../app/auth/auth.interfaces';
import {
  addApplicationInsightsTraces,
  addAuthorizationHeaderInterceptor,
  updatePendingIndicationToWindowOnRequest,
  updatePendingIndicationToWindowOnResponse,
  rejectionApplicationInsightTraces,
  rejectionUpdatePendingIndicationToWindow,
} from './interceptors';
import { ApplicationInsightsApi } from '../../application-insights';
import { getItemFromLocalStorage, keysExistInLocalStorage } from '../utils/localStorage.utils';
import { handleLocalLogOut, handleLogOut } from '../utils/logOut';
import { sendRefreshTokenToMobileApp } from '../../mobile-application-utils';

export const InternalError = {
  message: 'Internal error during request.',
  code: 500,
};

let activeRefreshToken: Promise<AxiosResponse<ILoginResponse, unknown>> | null = null; // holder for one refresh token function to prevent multiple calls to refresh token
/**
 * Return the exception payload using the message and code returned from axios
 * @param ex The exception
 */
export const getExceptionPayload = (ex: unknown): IAPIError => {
  if (typeof ex !== 'object' || !ex) {
    return InternalError;
  }
  const typedException = ex as IAPIError;
  if (!!typedException?.message && !!typedException?.code) {
    return {
      message: typedException.message,
      code: typedException.code,
    };
  }
  return InternalError;
};

/**
 * The api thunk callback type (because we cannot import it from redux toolkit
 */
type ApiThunkCallback<TData, TArgs> = (args?: TArgs) => Promise<AxiosResponse<TData> | TData>;

/**
 * Create an api thunk, which will run the provided promise and await it
 * @param typePrefix The type prefix of the action
 * @param requestCallback A callback function which returns an api request using axios
 */
export const createApiThunk = <TData, TArgs = void>(
  typePrefix: string,
  requestCallback: ApiThunkCallback<TData, TArgs>,
) => {
  return createAsyncThunk<TData, TArgs, { rejectValue: IAPIError }>(
    typePrefix,
    async (args, { rejectWithValue }) => {
      try {
        const response = await requestCallback(args);
        return (response as AxiosResponse<TData>)?.data ?? (response as TData);
        // eslint-disable-next-line
      } catch (e: any) {
        ApplicationInsightsApi.trackException(e);
        return rejectWithValue({
          message: e?.response?.data?.message,
          code: e?.response?.data?.status,
        });
      }
    },
  );
};

/**
 * An axios instance using our base url, and later our token
 */
// Create the main apiService with the default base URL
export const apiServiceCSharp = createAxiosInstance(`${process.env.REACT_APP_BASE_URL_CSHARP}`);

/**
 * Create an Axios instance with the specified base URL
 * @param baseURL The base URL
 */
function createAxiosInstance(baseURL: string): AxiosInstance {
  const instance = axios.create({
    baseURL,
  });

  // Add authorization header interceptor
  instance.interceptors.request.use(updatePendingIndicationToWindowOnRequest);
  instance.interceptors.request.use(
    addApplicationInsightsTraces,
    rejectionApplicationInsightTraces,
  );
  instance.interceptors.request.use(addAuthorizationHeaderInterceptor);
  // Response interceptors
  instance.interceptors.response.use(
    updatePendingIndicationToWindowOnResponse,
    rejectionUpdatePendingIndicationToWindow,
  );
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error?.response?.status;
      // we have acess to the ApiResponse
      //const {data} = error.response as IAPIRequestState<IApiResponseModel<any>>;
      // we can dispatch actions to set the error message and display errors to user
      // const {dispatch,} = store;
      const originalRequest = error?.config;
      switch (status) {
        case UNAUTHORIZED:
        case FORBIDDEN:
          if (!originalRequest._retry) {
            originalRequest._retry = true;
            try {
              activeRefreshToken = activeRefreshToken ? activeRefreshToken : refreshToken();
              const res = await activeRefreshToken;
              activeRefreshToken = null;
              if (res?.data?.token && res?.data?.refreshToken) {
                localStorage.setItem(tokenLocalStorageKey, JSON.stringify(res.data?.token));
                localStorage.setItem(
                  refreshTokenLocalStorageKey,
                  JSON.stringify(res?.data?.refreshToken),
                );
                sendRefreshTokenToMobileApp({
                  token: res.data?.token,
                  refreshToken: res?.data?.refreshToken,
                });
                // delete originalRequest.headers.Authorization;
                // apiService.defaults.headers.common['Authorization'] = 'Bearer ' + res?.data?.data?.accessToken;
                return instance(originalRequest);
              } else {
                await handleLogOut();
                return Promise.reject(error);
              }
            } catch (error) {
              ApplicationInsightsApi.trackException(error);
              handleLocalLogOut();
              return Promise.reject(error);
            }
          }
          ApplicationInsightsApi.trackException(error);
          await handleLogOut();
          return Promise.reject(error);
        case SERVER_ERROR || NOT_FOUND:
          // Add dispatch to error toaster / notification
          break;
        case BAD_REQUEST:
          // Add dispatch to error toaster / notification
          break;
        case APP_BUILD_NUMBER_CHANGED:
          ApplicationInsightsApi.trackTrace(
            `Version is outdated. current client build number: ${process.env.REACT_APP_BUILD_NUMBER} - reloading.`,
          );
          window.location.reload();
          break;
        case SESSION_NOT_FOUND:
          ApplicationInsightsApi.trackTrace('Session not found. Reloading application.');
          window.location.reload();
          break;
        default:
          break;
      }
      ApplicationInsightsApi.trackException(error);
      return Promise.reject(error);
    },
  );

  return instance;
}

async function refreshToken() {
  try {
    if (
      !keysExistInLocalStorage([
        tokenLocalStorageKey,
        refreshTokenLocalStorageKey,
        loginTypeStorageKey,
      ])
    ) {
      handleLocalLogOut();
      return Promise.reject('missing keys in localstorage');
    }
    const loginType = getItemFromLocalStorage<string>(loginTypeStorageKey);
    const operatingSystem = getItemFromLocalStorage<string>(operatingSystemLocalStorageKey);
    return axios.post<ILoginResponse>(
      process.env.REACT_APP_BASE_URL_CSHARP + API_ROUTES.AUTH.REFRESHTOKEN + loginType,
      {
        accessToken: getItemFromLocalStorage<string>(tokenLocalStorageKey),
        refreshToken: getItemFromLocalStorage<string>(refreshTokenLocalStorageKey),
        isFromIosMobileApp: operatingSystem === EOperatingSystem.IOS,
      },
      {
        headers: {
          'x-client-version': process.env.REACT_APP_BUILD_VERSION,
          'x-client-build-number': process.env.REACT_APP_BUILD_NUMBER,
        },
      },
    );
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    handleLocalLogOut();
    return Promise.reject(error);
  }
}

/**
 * Injecting the store to be able to use it inside axios interceptors
 * https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
 */

// eslint-disable-next-line
let store: EnhancedStore | undefined;
export const injectStore = (_store: EnhancedStore) => {
  store = _store;
};
export const FORBIDDEN = 403;
export const UNAUTHORIZED = 401;
const SERVER_ERROR = 500;
const NOT_FOUND = 404;
const BAD_REQUEST = 400;
const APP_BUILD_NUMBER_CHANGED = 900;
const SESSION_NOT_FOUND = 901;
