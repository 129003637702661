import type { IOverlayStyle } from '../../app-overlay-popover/AppOverlayPopover';
export const userPhoneFormatter = (phone: string): string => {
  return phone.replaceAll(' ', '');
};

export const defaultHourDailyReminder = 7;
export const defaultHourEveningReminder = 17;

export const settingOverlaysPositionStyle: IOverlayStyle = {
  left: 'unset',
  right: 100,
};
