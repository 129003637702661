import { useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { CalendarSyncPopover } from '../calendar-sync/CalendarSyncPopover';
import createAppOverlayPopover from '../../../../app-overlay-popover/createAppOverlayPopover';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import GoogleLogo from '../../../../../../assets/images//Google-logo.png';
import MicrosoftOutlookLogo from '../../../../../../assets/images//Microsoft-Outlook-logo.png';
import GlobeLogo from '../../../../../../assets/images//globe-logo.svg';
import penEditIcon from '../../../../../../assets/images/pen-edit.svg';
import './CalendarSync.scss';
import { chatSessionIdLocalStorageKey } from '../../../../../../app/constants';
import { useLocalStorage } from '../../../../../utils/useLocalStorage';
import type { IUserProfileReqPayload } from '../../SettingsMenu.interfaces';
import { updateUserProfileReq } from '../../../../../../app/useAppData/user.store';
import { EExternalCalendarProvider } from '../../../../../../app/auth/auth.interfaces';
import AppButton from '../../../../app-button/AppButton';
import { useApiData } from '../../../../../hooks/useApiData';
import removeAppOverlayPopover from '../../../../app-overlay-popover/removeAppOverlayPopover';

export const CalendarSync = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { user, iCalUpdateRequest } = useAppSelector((store) => store.userReducer);
  const [calendarProvider, setCalendarProvider] = useState<null | string>(null);
  const { sessionId } = useAppSelector((store) => store.chatReducer);
  const [sessionIdLocalStorage] = useLocalStorage(chatSessionIdLocalStorageKey, '');

  useEffect(() => {
    setCalendarProvider(user?.calendarProvider || null);
  }, [user]);

  useApiData(iCalUpdateRequest, {
    onFulfilled: (response) => {
      if (response) {
        setCalendarProvider(response.calendarProvider);
        removeAppOverlayPopover();
      }
    },
  });

  const handleRemoveCalendarSync = () => {
    const reqPayload: IUserProfileReqPayload = {
      shouldRemoveCalendar: true,
      sessionId: sessionId?.data?.sessionId || sessionIdLocalStorage,
    };
    dispatch(updateUserProfileReq(reqPayload))
      .unwrap()
      .then((userData) => {
        if (userData) setCalendarProvider(null);
      });
  };

  const renderProviderButton = () => {
    switch (calendarProvider) {
      case EExternalCalendarProvider.GOOGLE:
        return (
          <button type="button" className="calendar-sync-google-button calendar-synced">
            <img src={GoogleLogo} alt={t('loginGoogleLogoImageAltText')} />
            {t('google')}
          </button>
        );
      case EExternalCalendarProvider.OUTLOOK:
        return (
          <button type="button" className="calendar-sync-outlook-synced calendar-synced">
            <img src={MicrosoftOutlookLogo} alt={t('loginMicrosoftOutlookLogoImageAltText')} />
            {t('outlook')}
          </button>
        );
      case EExternalCalendarProvider.ICAL:
        return (
          <button type="button" className="calendar-sync-ical-synced calendar-synced">
            <img src={GlobeLogo} alt={t('iCalSyncAltText')} />
            {t('iCalSyncedText')}
          </button>
        );
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <section className="data">
        <div>
          <h3 className="title static-string">{t('settingsPersonalInfoCalendarTitle')}</h3>
          <p>{t('settingsPersonalInfoCalendarSubTitle')}</p>
        </div>

        {calendarProvider ? (
          <div className="flex calendar-sync-flex">
            <AppButton
              id="remove-calendar-sync"
              type="button"
              className="remove calendar-sync-remove"
              shouldChangeBackgroundOnActive={false}
              onClick={() => {
                handleRemoveCalendarSync();
              }}
            >
              <img src={penEditIcon} alt="pen" />
              {t('remove')}
            </AppButton>
            {renderProviderButton()}
          </div>
        ) : (
          <AppButton
            className="personal-info-btn"
            data-testid="calendar-sync"
            id="open-calendar-sync-options-popup"
            onClick={(e) => createAppOverlayPopover(<CalendarSyncPopover />)}
          >
            {t('settingsPersonalInfoCalendarButton')}
          </AppButton>
        )}
      </section>
    </Fragment>
  );
};
