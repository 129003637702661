import { createAction, createReducer } from '@reduxjs/toolkit';
import type { ApiDataStateType } from '../../shared/store/utils';
import type { IToolMessage } from '../chat-wrapper/chat.interfaces';

export interface IAIControlUXState extends ApiDataStateType {
  aiControl?: IToolMessage | null;
}

const initialState: IAIControlUXState = {
  aiControl: null,
};

const createReducerKey = (subKey: string): string => {
  return 'aiControlUX/' + subKey;
};

export const setAIControl = createAction<IToolMessage | null>(createReducerKey('setAIControl'));

export const aiControlUXReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAIControl, (state, action) => {
    state.aiControl = action.payload;
  });
});
