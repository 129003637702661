import { useNavigate } from 'react-router-dom';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import {
  APP_ROUTING_PATHS,
  loginTypeStorageKey,
  operatingSystemLocalStorageKey,
  refreshTokenLocalStorageKey,
  registrationParametersStorageKey,
  tokenLocalStorageKey,
} from '../../constants';
import { useAppDispatch } from '../../store';
import type { ELoginType, EOperatingSystem } from '../auth.interfaces';
import { useCallback, useEffect, useRef } from 'react';
import { ApplicationInsightsApi, setAppInsightsUserContext } from '../../../application-insights';
import './MobileAppCheckLogin.scss';
import { getUserAppData, registrationReqAction } from '../../useAppData/user.store';
import { setAppModalContentType } from '../../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../../shared/components/app-modals/appModals.interfaces';

interface IProps {
  loginType: ELoginType;
  operatingSystem?: EOperatingSystem;
}

export const MobileAppCheckLogin = ({ loginType, operatingSystem }: IProps) => {
  const [, setAccessToken] = useLocalStorage(tokenLocalStorageKey, '');
  const [, setRefreshToken] = useLocalStorage(refreshTokenLocalStorageKey, '');
  const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');
  const [, setOperatingSystem] = useLocalStorage(operatingSystemLocalStorageKey, '');
  const isFetchingDataRef = useRef(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const extractMobileAppData = useCallback(() => {
    try {
      ApplicationInsightsApi.trackTrace(`Mobile App login - ${window.location.href}}`);
      setLoginType(loginType);
      if (operatingSystem?.length) setOperatingSystem(operatingSystem);
      const queryParams = new URLSearchParams(window.location.search);
      // support native app passing deep link url that can be processed by the web app
      // e.g http://dev.maximallearning.com/n/1 (for handling notifications entry points) the value of deepLink will be 'n/1/'
      const deepLink = queryParams.get('deepLink');
      if (!queryParams.has('token') || !queryParams.has('refreshToken')) {
        navigate(APP_ROUTING_PATHS.LOGOUT);
        return;
      }
      const params: { [key: string]: string } = {};
      queryParams.forEach((value, key) => {
        if (key === 'token') {
          setAccessToken(value);
        } else if (key === 'refreshToken') {
          setRefreshToken(value);
        } else if (value) {
          params[key] = value;
        }
      });
      if (Object.keys(params).length > 0) {
        localStorage.setItem(registrationParametersStorageKey, JSON.stringify(params));
      }
      dispatch(getUserAppData())
        .unwrap()
        .then((data) => {
          const { user } = data;
          if (user) {
            setAppInsightsUserContext();
          }
          if (data.releaseNotes)
            dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
          if (!user?.isActive) {
            dispatch(
              registrationReqAction({
                registrationParameters:
                  localStorage.getItem(registrationParametersStorageKey) || null,
              }),
            );
          } else {
            if (deepLink) {
              navigate(`/${deepLink}`, { replace: true });
            } else {
              navigate(APP_ROUTING_PATHS.REDIRECT);
            }
          }
        })
        .catch((e) => {
          ApplicationInsightsApi.trackException(e);
          navigate(APP_ROUTING_PATHS.LOGOUT);
        });
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
    }
  }, [
    setAccessToken,
    setRefreshToken,
    loginType,
    setLoginType,
    dispatch,
    navigate,
    operatingSystem,
    setOperatingSystem,
  ]);

  useEffect(() => {
    if (!isFetchingDataRef.current) {
      isFetchingDataRef.current = true;
      extractMobileAppData();
    }
  }, [extractMobileAppData]);

  return (
    <div className="mobile-app-sso">
      <LottieAppLoader testId="lottie-sso-loader" />
    </div>
  );
};
