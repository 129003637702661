import type { FunctionComponent, ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { tokenLocalStorageKey } from '../constants';
import { useAppSelector } from '../store';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';

interface IProps {
  children: ReactElement;
}

// used to protect routes that require uses to be authenticated and logged in.
export const PrivateRoute: FunctionComponent<IProps> = ({ children }) => {
  const [tokenLocalStorage] = useLocalStorage(tokenLocalStorageKey, '');
  const { loginRes } = useAppSelector((store) => store.authReducer);
  const { user } = useAppSelector((store) => store.userReducer);
  const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;

  return !!token && !!user?.phoneNumber ? children : <Navigate to="/" />;
};
