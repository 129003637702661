import { useTranslation } from 'react-i18next';
import { inviteFriendsLink } from '../../../../../../app/constants';
import { useEffect, useRef, useState } from 'react';
import { ApplicationInsightsApi } from '../../../../../../application-insights';
import type { IUser } from '../../../../../../app/auth/auth.interfaces';
import './CopyLink.scss';
import { useAppSelector } from '../../../../../../app/store';
import AppButton from '../../../../app-button/AppButton';

export const CopyLink = () => {
  const { t } = useTranslation();
  const [isCopied, setIsCopied] = useState(false);
  const { user: userInfo } = useAppSelector((store) => store.userReducer);
  const copyTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);

  const clearTimer = () => {
    if (copyTimerRef.current) clearTimeout(copyTimerRef.current);
  };

  useEffect(() => {
    return clearTimer();
  }, []);

  const copyLinkToClipboard = async () => {
    // remove error if exist
    if (shouldDisplayError) setShouldDisplayError(false);
    if ('clipboard' in navigator) {
      navigator.clipboard
        .writeText(inviteFriendsLink((userInfo as IUser)?.id))
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          clearTimer();
          copyTimerRef.current = setTimeout(() => {
            setIsCopied(false);
          }, 2000);
        })
        // The promise is rejected if the caller does not have permission to write to the clipboard.
        .catch((err) => {
          setShouldDisplayError(true);
          ApplicationInsightsApi.trackException(err);
        });
    } else setShouldDisplayError(true);
  };

  return (
    <div className="copy-link-wrapper">
      <div className="copy-link-flex-container">
        <h3 className="copy-link-header">{t('settingsInviteFriendsTitle')}</h3>
        <p className="copy-link-text">{t('settingsCopyLinkParagraph')}</p>
        <div className="flex-desktop">
          <div className="link-content">{inviteFriendsLink((userInfo as IUser)?.id)}</div>
        </div>
        <AppButton
          id="copy-link-action-button-desktop"
          className={`copy-button ${isCopied && 'copied'}`}
          onClick={copyLinkToClipboard}
          disabled={isCopied}
        >
          {isCopied ? t('settingsLinkCopiedButtonText') : t('settingsCopyLinkButtonText')}
        </AppButton>
      </div>
      {shouldDisplayError && (
        <small className="app-error error">{t('settingsPageCopyLinkError')}</small>
      )}
    </div>
  );
};
