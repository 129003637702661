import { forwardRef } from 'react';
import './AppInput.scss';

interface IAppInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string; // mandatory
  error?: string;
}
const AppInput = forwardRef<HTMLInputElement, IAppInputProps>(
  ({ className, error, ...attributes }, ref) => {
    return (
      <div className="input-container">
        <input
          ref={ref} // Pass the ref to add the option to connect it to react-hook-form
          className={`app-input ${error ? 'app-input--error' : ''} ${className || ''}`}
          {...attributes}
        />
        {error && (
          <small className="input-error" data-testid="input-error">
            {error}
          </small>
        )}
      </div>
    );
  },
);

// Add a displayName to satisfy ESLint and improve debugging in React DevTools
AppInput.displayName = 'AppInput';

export default AppInput;
