import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import React from 'react';
import createAppOverlayPopover from '../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { InstructionsModal } from '../../../shared/components/instructions-modal/InstructionsModal';
import removeAppOverlayPopover from '../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import type { IOverlayStyle } from '../../../shared/components/app-overlay-popover/AppOverlayPopover';
import { ELoginType } from '../auth.interfaces';
import SassVariables from '../../../styles/style.module.scss';
import { API_ROUTES, APP_ROUTING_PATHS } from '../../constants';
import AppleLogo from '../../../assets/images/Apple-logo-white.png';
import GoogleLogo from '../../../assets/images/Google-logo.png';
import DiscordLogo from '../../../assets/images/Discord-logo.png';
import { useTranslation } from 'react-i18next';
import { baseUrl, isEmbeddedBrowser } from '../auth.utils';
import './LoginActionsButtons.scss';
import { LoginWithPhoneButton } from '../login/login-buttons/LoginWithPhoneButton';
import { ELoginPageViewType } from '../login/Login';
import { LoginWithEmailButton } from '../login/login-buttons/LoginWithEmailButton';

interface ILoginActionsButtonsProps {
  isPageLoading: boolean;
  setIsPageLoading: Dispatch<SetStateAction<boolean>>;
  viewType: ELoginPageViewType;
}

const LoginActionsButtons: FunctionComponent<ILoginActionsButtonsProps> = ({
  isPageLoading,
  setIsPageLoading,
  viewType,
}) => {
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const fbclidParam = queryParams.get('fbclid');
  let fbclidIsOdd = false;
  try {
    fbclidIsOdd =
      fbclidParam !== null && fbclidParam.charAt(fbclidParam.length - 2).charCodeAt(0) % 2 !== 0;
  } catch (e) {
    console.error(e);
  }
  const hideButton =
    viewType === ELoginPageViewType.NEW_USER_VIEW && fbclidIsOdd && isEmbeddedBrowser();

  const authRedirect = (provider: ELoginType) => {
    setIsPageLoading(true);
    window.location.href = `${process.env.REACT_APP_BASE_URL_CSHARP}/${API_ROUTES.AUTH.REDIRECT}/${provider}?redirectUri=${baseUrl}${APP_ROUTING_PATHS.SSO}`;
  };

  const showEmbeddedBrowserInstructions = (instructionsText: string) => {
    const overlayStyle: IOverlayStyle = {
      bottom: `calc(${SassVariables.overlayMobileBottomHeight} + 20px)`,
      left: '50%',
      transform: 'translate(-50%)',
    };

    createAppOverlayPopover(
      <InstructionsModal onClose={() => removeAppOverlayPopover()} text={instructionsText} />,
      'instructions-modal-overlay',
      null,
      overlayStyle,
      {
        isCustomStyle: true,
        shouldOverrideDefaultStyles: true,
        closeOnClickOutside: true,
        allowInteraction: false,
      }, // Pass config object
    );
  };
  return (
    <div className="login-options">
      <div className="google-and-apple-flex-container">
        {!hideButton && (
          <button
            className="google-login"
            disabled={isPageLoading}
            onClick={() => {
              !isEmbeddedBrowser()
                ? authRedirect(ELoginType.GOOGLE)
                : showEmbeddedBrowserInstructions(t('embeddedBrowserInstructionsForGoogle'));
            }}
            data-testid={`google-login`}
          >
            <div className="action-btn-img-container">
              <img src={GoogleLogo} alt={t('loginGoogleLogoImageAltText')} />
            </div>
            {viewType === ELoginPageViewType.RETURNING_USER_VIEW
              ? t('loginWithGoogle')
              : t('signUpWithGoogle')}
          </button>
        )}
        <button
          className="apple-login"
          disabled={isPageLoading}
          onClick={() => authRedirect(ELoginType.APPLE)}
          data-testid="apple-login"
        >
          <div className="action-btn-img-container">
            <img className="sign-up-apple" src={AppleLogo} alt={t('loginAppleLogoImageAltText')} />
          </div>
          {viewType === ELoginPageViewType.RETURNING_USER_VIEW
            ? t('loginWithApple')
            : t('signUpWithApple')}
        </button>
      </div>
      <div className="flex-container">
        <LoginWithEmailButton viewType={viewType} />
        <LoginWithPhoneButton viewType={viewType} />
        {!hideButton && viewType === ELoginPageViewType.RETURNING_USER_VIEW && (
          <button
            className="discord-login"
            disabled={isPageLoading}
            onClick={() => {
              !isEmbeddedBrowser()
                ? authRedirect(ELoginType.DISCORD)
                : showEmbeddedBrowserInstructions(t('embeddedBrowserInstructionsForDiscord'));
            }}
            data-testid={`discord-login`}
          >
            <div className="action-btn-img-container">
              <img src={DiscordLogo} alt={t('loginDiscordLogoImageAltText')} />
            </div>
            {t('loginWithDiscord')}
          </button>
        )}
      </div>
    </div>
  );
};

export default LoginActionsButtons;
