import type { FunctionComponent } from 'react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import type { IWorkBlockFormControllerProps, IWorkBlockFormData } from '../../workBlock.interface';
import { useTranslation } from 'react-i18next';
import '../../../stage-tasks/create-or-edit-task/task-name-controller/TaskNameController.scss';
import './WorkBlockNameController.scss';
import { handleInputKeyDown } from '../../../../../../../shared/utils/utils';
import { ReactComponent as WorkBlockIcon } from '../../../../../../../assets/images/task-details/work-block-details-main-icon.svg';

const WorkBlockNameController: FunctionComponent<IWorkBlockFormControllerProps> = ({
  onChangeFields,
}) => {
  const workBlockForm = useFormContext<IWorkBlockFormData>();
  const { t } = useTranslation();
  const nameWatch = workBlockForm.watch('name');

  const onBlurName = (updatedName: string) => {
    if (workBlockForm.getFieldState('name').isDirty && !!updatedName) {
      onChangeFields({ name: updatedName });
      // will reset isDirty back to false
      workBlockForm.resetField('name', { defaultValue: updatedName });
    }
  };

  return (
    <section className="work-block-name-controller work-block-controller work-block-detail-section">
      <WorkBlockIcon
        className={`task-detail-icon ${(nameWatch || []).length ? 'task-detail-icon--active' : ''}`}
      />
      <input
        {...workBlockForm.register('name', {
          required: true,
          minLength: 1,
          onBlur: (e) => onBlurName(e.target.value),
        })}
        className="name-text"
        type="text"
        placeholder={t('workBlockDetailsNameControllerPlaceholder')}
        defaultValue={workBlockForm.getValues('name')}
        onKeyDown={handleInputKeyDown}
        autoFocus
        autoComplete="off"
      />
    </section>
  );
};

export default WorkBlockNameController;
