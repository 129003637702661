import { useState } from 'react';
import { ApplicationInsightsApi } from '../../application-insights';
import { isNoneBrowserEnvironment } from './localStorage.utils';

// add types support
export const useLocalStorage = <T>(key: string, initialValue: T) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (isNoneBrowserEnvironment()) {
      return initialValue;
    }
    try {
      // Get from local storage by key
      let item;
      item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
      // If error also return initialValue
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  // eslint-disable-next-line
  const setValue = (value: T) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (isNoneBrowserEnvironment()) return;
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      ApplicationInsightsApi.trackException(error);
      // A more advanced implementation would handle the error case
    }
  };
  return [storedValue, setValue] as const;
};
