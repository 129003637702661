import {
  plannerModeOnLocalStorageKey,
  selectedPlanViewTypeLocalStorageKey,
} from '../../../../../app/constants';
import { store } from '../../../../../app/store';
import {
  removeDataAttributeOnBody,
  setDataAttributeOnBody,
} from '../../../../../shared/utils/utils';
import { uxEventEnterForm } from '../../../../../shared/utils/uxEventsUtils';
import { EAIFormTypes } from '../../../../ai-control-ux/AIControlUX.interface';
import { setQueueMessageData } from '../../../chat.store';
import { EPlanViewSelection } from './stagePlanner.interface';
import {
  EPlannerMode,
  setPlannerCurrentTaskPlacement,
  setPlannerState,
  setPlanView,
} from './stagePlanner.store';

let timerRef: NodeJS.Timeout | null = null;

export const activatePlannerTimePicker = () => {
  const dispatch = store.dispatch;
  setDataAttributeOnBody('data-planner-state', EPlannerMode[EPlannerMode.TIMEPICKER].toLowerCase());
  dispatch(setPlannerState(EPlannerMode.TIMEPICKER));
  dispatch(setPlanView(EPlanViewSelection.MY_WEEK));
};

export const activePlannerUnscheduledTasksPlacer = (isManuallyTriggered = false) => {
  const dispatch = store.dispatch;
  if (isManuallyTriggered) {
    if (timerRef) clearTimeout(timerRef); // throttle if the user opens and closes the planner mode too quickly
    timerRef = setTimeout(() => {
      dispatch(
        setQueueMessageData({
          type: 'automatic',
          botRequestJson: JSON.stringify(uxEventEnterForm(EAIFormTypes.PLANNER)),
        }),
      );
    }, 500);
    localStorage.setItem(plannerModeOnLocalStorageKey, '1');
  }
  dispatch(setPlannerState(EPlannerMode.UNSCHEDULEDTASKSPLACER));
  dispatch(
    setPlanView(
      (localStorage.getItem(selectedPlanViewTypeLocalStorageKey) as EPlanViewSelection) ||
        EPlanViewSelection.MY_WEEK,
    ),
  );
  setDataAttributeOnBody(
    'data-planner-state',
    EPlannerMode[EPlannerMode.UNSCHEDULEDTASKSPLACER].toLowerCase(),
  );
};

export const resetPlannerState = (isManuallyTriggered = false) => {
  const dispatch = store.dispatch;
  removeDataAttributeOnBody('data-planner-state');
  dispatch(setPlannerState(null));
  dispatch(setPlannerCurrentTaskPlacement(null));
  if (isManuallyTriggered) localStorage.removeItem(plannerModeOnLocalStorageKey);
};
