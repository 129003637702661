import {
  activePlannerUnscheduledTasksPlacer,
  resetPlannerState,
} from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.utils';
import { useAppSelector } from '../../../app/store';
import { EPlannerMode } from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { ReactComponent as PlannerNotificationIcon } from '../../../assets/images/planner/planner-notification-icon.svg';
import { ReactComponent as EmptyPlannerIcon } from '../../../assets/images/planner/empty-planner-icon.svg';
import { useEffect, useState } from 'react';
import { EAPIStatus } from '../../../shared/api/models';
import { filterForUnscheduledTasks } from '../plan.utils';
import AppButton from '../../../shared/components/app-button/AppButton';

export const PlanUnscheduledFab = ({ planTourDataAttr }: { planTourDataAttr: string }) => {
  const { sessionResponse } = useAppSelector((store) => store.chatReducer);
  const { plannerMode } = useAppSelector((store) => store.StagePlannerReducer);
  const { allTasks } = useAppSelector((store) => store.StageTasksReducer);
  const [totalUnscheduledTasks, setTotalUnscheduledTasks] = useState<number>(0);

  const onTogglePlanner = () => {
    switch (plannerMode) {
      case null:
        activePlannerUnscheduledTasksPlacer(true);
        break;
      case EPlannerMode.TIMEPICKER:
        resetPlannerState(true);
        activePlannerUnscheduledTasksPlacer(true);
        break;
      case EPlannerMode.UNSCHEDULEDTASKSPLACER:
        resetPlannerState(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const totalUnscheduledTasks = (allTasks || []).filter(filterForUnscheduledTasks).length;
    setTotalUnscheduledTasks(totalUnscheduledTasks);
  }, [allTasks]);

  const getImageBasedOnPlannerModeAndUnscheduledTasks = () => {
    return totalUnscheduledTasks > 0 ? <PlannerNotificationIcon /> : <EmptyPlannerIcon />;
  };

  return (
    <AppButton
      data-plan-tour={planTourDataAttr}
      id="toggle-planner-mode"
      disabled={[EAPIStatus.PENDING, EAPIStatus.REJECTED].includes(sessionResponse.status)}
      type="button"
      className="planner-fab no-style-button"
      onClick={onTogglePlanner}
      shouldChangeBackgroundOnActive={false}
    >
      {getImageBasedOnPlannerModeAndUnscheduledTasks()}
    </AppButton>
  );
};
