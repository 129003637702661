import type { FunctionComponent, MouseEvent } from 'react';
import React from 'react';
import EditButton from '../../../../edit-button/EditButton';
import type { IProactiveReminders } from '../../../../../../app/auth/auth.interfaces';
import createAppOverlayPopover, {
  EAppOverlaySlideInMobileAnimation,
} from '../../../../app-overlay-popover/createAppOverlayPopover';
import ProactiveReminderTimerOverlay from './ProactiveReminderTimerOverlay';
import { settingOverlaysPositionStyle } from '../../SettingsMenu.utils';
import { useTranslation } from 'react-i18next';
import './EditReminderTimeValue.scss';
import { ECloseSwipeDirection } from '../../../../../hooks/swipe-hooks/swipe.utils';

interface IEditReminderTimeValueProps {
  reminderHour: number;
  handleUpdateUserProfile: (
    proactiveReminderProperty: keyof IProactiveReminders,
    updatedValue: boolean | number | null,
  ) => void;
  proactiveReminderProperty: keyof IProactiveReminders;
}

const EditReminderTimeValue: FunctionComponent<IEditReminderTimeValueProps> = ({
  reminderHour,
  handleUpdateUserProfile,
  proactiveReminderProperty,
}) => {
  const { t } = useTranslation();

  const get12HourFormat = () => {
    if (reminderHour % 12 === 0) return 12;
    return reminderHour % 12;
  };

  const getTimeType = (hour: number) => {
    if (hour < 12) return 'AM';
    return 'PM';
  };

  const getTimerOverlayHeaderText = () => {
    switch (proactiveReminderProperty) {
      case 'proactiveDailyReminderHour':
        return t('proactiveDailyReminderTimerOverlayHeader');
      case 'proactiveEveningReminderHour':
        return t('proactiveEveningReminderTimerOverlayHeader');
      default:
        return '';
    }
  };

  const onClickEdit = (e: MouseEvent<HTMLElement, any>) => {
    const proactiveReminderOverlayClassName = 'background-none';
    createAppOverlayPopover(
      <ProactiveReminderTimerOverlay
        currentHour={reminderHour}
        onChange={(hour) => handleUpdateUserProfile(proactiveReminderProperty, hour)}
        headerText={getTimerOverlayHeaderText()}
      />,
      proactiveReminderOverlayClassName,
      e,
      settingOverlaysPositionStyle,
      {
        // overlay config
        slideInMobileAnimation: EAppOverlaySlideInMobileAnimation.HALF_SCREEN,
        shouldCloseBySwipeOnMobile: true,
        closeSwipeDirection: ECloseSwipeDirection.DOWN,
      },
    );
  };

  return (
    <div className="edit-reminder-time-value-container">
      <EditButton testId="daily-reminder-edit" onClick={onClickEdit} />
      <span className="reminder-time-text">
        {get12HourFormat()}:00 {getTimeType(reminderHour)}
      </span>
    </div>
  );
};

export default EditReminderTimeValue;
