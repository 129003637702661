import type { FunctionComponent } from 'react';
import { useCallback, useEffect } from 'react';
import './ProactiveSurveyPopup.scss';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { updateUserProfileReq } from '../../../app/useAppData/user.store';
import { EFeedbackSurveyType } from '../../../features/chat-wrapper/chat/Chat.utils';
import { lastSurveyOfferedDayOfWeekKey } from '../../../app/constants';
import { ApplicationInsightsApi } from '../../../application-insights';
import { FlagUtils } from '../../utils/flagUtils';
import { EClientFlags } from '../content-frame-wrapper/settings-menu/SettingsMenu.interfaces';
import { setItemInLocalStorage } from '../../utils/localStorage.utils';
import AppPopUpToast from '../app-pop-up-toast/AppPopUpToast';
import removeAppOverlayPopover from '../app-overlay-popover/removeAppOverlayPopover';

interface IProactiveSurveyPopupProps {
  onClose: () => void;
  surveyType: EFeedbackSurveyType;
}

const ProactiveSurveyPopup: FunctionComponent<IProactiveSurveyPopupProps> = ({
  onClose,
  surveyType,
}) => {
  const { user } = useAppSelector((store) => store.userReducer);
  const dispatch = useAppDispatch();
  const userType = user?.isInternalUser ? 'internal' : 'open';
  const newUserFeedbackSurveyUrl = `https://maximallearning.typeform.com/NewMaxUser?utm_content=inapp&utm_campaign=${userType}&utm_source=${user?.id}`;
  const superhumanFeedbackSurveyUrl = `https://maximallearning.typeform.com/SHSurvey?utm_content=inapp&utm_campaign=${userType}&utm_source=${user?.id}`;
  const proactiveSurveyPopupId =
    surveyType === EFeedbackSurveyType.NEW_USER
      ? 'new-user-survey-toast'
      : 'superhuman-survey-toast';
  const currentSurveyUrl =
    surveyType === EFeedbackSurveyType.NEW_USER
      ? newUserFeedbackSurveyUrl
      : superhumanFeedbackSurveyUrl;
  const title =
    surveyType === EFeedbackSurveyType.NEW_USER
      ? 'proactiveSurveyTitleNewUser'
      : 'proactiveSurveyTitleSuperhuman';
  const body =
    surveyType === EFeedbackSurveyType.NEW_USER
      ? 'proactiveSurveyBodyNewUser'
      : 'proactiveSurveyBodySuperhuman';
  const clientFlagToUpdate =
    surveyType === EFeedbackSurveyType.NEW_USER
      ? EClientFlags.DONT_SHOW_NEW_USER_FEEDBACK
      : EClientFlags.DONT_SHOW_SUPERHUMAN_SURVEY;

  const setSurveyOfferedDayOfWeek = useCallback(() => {
    setItemInLocalStorage(lastSurveyOfferedDayOfWeekKey, new Date().getDay());
  }, []);

  useEffect(() => {
    setSurveyOfferedDayOfWeek();
  }, [setSurveyOfferedDayOfWeek]);

  const handleSurvey = () => {
    const clientFlags = user?.clientFlags || 0;
    const updatedClientFlags = FlagUtils.setFlag(clientFlags, clientFlagToUpdate);
    dispatch(updateUserProfileReq({ clientFlags: updatedClientFlags })).catch(() => {
      ApplicationInsightsApi.trackException(new Error('Failed to close proactive survey popup'));
    });
  };

  return (
    <AppPopUpToast
      id={proactiveSurveyPopupId}
      title={title}
      body={body}
      CTA_Text={'proactiveSurveyButton'}
      CTA_Href={currentSurveyUrl}
      dontShowAgainFlag={clientFlagToUpdate}
      dontShowAgainBtnText={'proactiveSurveyButtonDontShowAgain'}
      onClose={() => removeAppOverlayPopover()}
      CTA_action={handleSurvey}
    />
  );
};

export default ProactiveSurveyPopup;
