import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/store';
import { useWebsiteTabVisibility } from '../../hooks/useWebsiteTabVisibility';
import { createOrContinueSession } from '../../../features/chat-wrapper/chat.store';
import { useLocalStorage } from '../../utils/useLocalStorage';
import { tokenLocalStorageKey } from '../../../app/constants';

export const TabSwitchingEvenHandler = () => {
  const { isVisible, lastVisibleTime } = useWebsiteTabVisibility();
  const dispatch = useAppDispatch();
  const elapsedTimeToStartNewSession = 60;
  const [tokenLocalStorage] = useLocalStorage(tokenLocalStorageKey, '');
  const { loginRes } = useAppSelector((store) => store.authReducer);
  const { user } = useAppSelector((store) => store.userReducer);
  const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;
  const isLoggedInIntoTheChat = !!token && !!user?.phoneNumber;

  const onStartNewSession = useCallback(() => {
    dispatch(createOrContinueSession());
  }, [dispatch]);

  useEffect(() => {
    if (isVisible && isLoggedInIntoTheChat) {
      const currentTime = Date.now();
      const timeElapsed = currentTime - lastVisibleTime;
      // convert the number of milliseconds to minutes(there are 60,000ms in 1 minute) - if more than elapsedTimeToStartNewSession -> start a new session
      if (timeElapsed / 60000 > elapsedTimeToStartNewSession) {
        onStartNewSession();
      }
    }
  }, [isVisible, lastVisibleTime, onStartNewSession, isLoggedInIntoTheChat]);

  return <></>;
};
