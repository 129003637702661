import type { FunctionComponent, MouseEvent } from 'react';
import React from 'react';
import penEditIcon from '../../../assets/images/pen-edit.svg';
import { useTranslation } from 'react-i18next';
import './EditButton.scss';

interface IEditButton {
  testId: string;
  onClick: (e: MouseEvent<HTMLElement, any>) => void;
}

const EditButton: FunctionComponent<IEditButton> = ({ testId, onClick }) => {
  const { t } = useTranslation();

  return (
    <button type="button" className="edit-button" onClick={onClick} data-testid={testId}>
      <img src={penEditIcon} alt="pen" />
      {t('edit')}
    </button>
  );
};

export default EditButton;
