import { ApplicationInsightsApi } from './application-insights';
import { isMobileAppWebView } from './mobile-application-utils';
import { getTimeZoneOffsetInMinutes } from './shared/utils/timezoneUtils';

export const initializeGTM = () => {
  const isGDPRCountry = checkIfGDPRCountry();
  const isWebView = isMobileAppWebView();

  if (isGDPRCountry || isWebView) {
    ApplicationInsightsApi.trackTrace('GTMDisabled', {
      reason: isGDPRCountry ? 'GDPR Country' : 'WebView Environment',
    });
  } else {
    loadGTM();
  }
};

// Utility function to check GDPR countries
const checkIfGDPRCountry = (): boolean => {
  try {
    // Get the user's timezone offset in minutes
    const timezoneOffsetMinutes = getTimeZoneOffsetInMinutes();

    // Check if the offset is between -180 and +180 minutes (inclusive)
    const isWithinGDPRRange = timezoneOffsetMinutes >= -180 && timezoneOffsetMinutes <= 180;

    if (isWithinGDPRRange) {
      return true; // Disable cookies
    } else {
      return false; // Enable cookies
    }
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    return false; // Default to non-GDPR in case of errors
  }
};

// Function to load Google Tag Manager
const loadGTM = () => {
  (function (w: any, d: Document, s: string, l: string, i: string) {
    (w as any)[l] = (w as any)[l] || [];
    (w as any)[l].push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' });

    const f = d.getElementsByTagName(s)[0] as HTMLScriptElement | null;
    if (!f || !f.parentNode) {
      return;
    }

    const j = d.createElement(s) as HTMLScriptElement;
    const dl = l !== 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-W564STQV');
};
