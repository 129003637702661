import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { getTasksListReqAction } from '../stage-tasks/stageTasks.store';
import { ConfirmModal } from '../../../../../shared/components/confirm-modal/ConfirmModal';
import {
  clearScratchpadTasksListReqAction,
  setShouldOpenClearConfirmModal,
} from './stageScratchpad.store';
import ScratchpadNotification from './scratchpad-notification/ScratchpadNotification';
import ScratchpadTaskList from './scratchpad-task-list/ScratchpadTaskList';
import './StageScratchpad.scss';

const StageScratchpad = () => {
  const { t } = useTranslation();
  const { shouldOpenClearConfirmModal } = useAppSelector((store) => store.StageScratchpadReducer);
  const [shouldDisplayClearNotification, setShouldDisplayClearNotification] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onClearScratchpadList = () => {
    dispatch(setShouldOpenClearConfirmModal(false));
    dispatch(clearScratchpadTasksListReqAction())
      .unwrap()
      .then((isClear) => {
        if (isClear) {
          dispatch(getTasksListReqAction());
          setShouldDisplayClearNotification(true);
        }
      });
  };

  return (
    <div className="stage-child-component stage-scratchpad-container">
      <div className="scrollable-frame-container">
        <ScratchpadTaskList />
        {shouldDisplayClearNotification && (
          <ScratchpadNotification
            notificationText={t('scratchpadClearNotificationText')}
            onDestroy={() => setShouldDisplayClearNotification(false)}
            className="clear-notification fadeIn"
            destroyAnimationType="fadeOut"
          />
        )}
      </div>
      {shouldOpenClearConfirmModal && (
        <ConfirmModal
          title={t('deleteScratchpadConfirmModalTitle')}
          informationText={t('deleteTaskConfirmModalSubTitle')}
          confirmBtnText={t('settingsResetProfileConfirmModalConfirmButtonText') + '.'}
          onConfirm={() => onClearScratchpadList()}
          onCancel={() => dispatch(setShouldOpenClearConfirmModal(false))}
        />
      )}
    </div>
  );
};

export default StageScratchpad;
