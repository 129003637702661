import { FunctionComponent, ReactElement, useRef } from 'react';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import { APP_ROUTING_PATHS, tokenLocalStorageKey } from '../constants';
import { useAppDispatch, useAppSelector } from '../store';
import { LottieAppLoader } from '../../shared/components/lottie-loader/LottieLoader';
import { getUserAppData } from '../useAppData/user.store';
import { handleLocalLogOut } from '../../shared/utils/logOut';
import { formatUrlWithParams } from '../../shared/utils/routingUtils';
import { setAppInsightsUserContext } from '../../application-insights';
import { setAppModalContentType } from '../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../shared/components/app-modals/appModals.interfaces';
import { Navigate } from 'react-router-dom';
import './InitUserGuard.scss';

interface IProps {
  children: ReactElement;
}

export const InitUserGuard: FunctionComponent<IProps> = ({ children }) => {
  const componentShouldLoad = useRef(true);
  const [tokenLocalStorage] = useLocalStorage(tokenLocalStorageKey, '');
  const { loginRes } = useAppSelector((store) => store.authReducer);
  const token = loginRes.data ? loginRes.data.token : tokenLocalStorage;
  const { user } = useAppSelector((store) => store.userReducer);
  const dispatch = useAppDispatch();

  if (!token) return <Navigate to={formatUrlWithParams(APP_ROUTING_PATHS.SIGN_IN)} />;
  if (!user && componentShouldLoad.current) {
    componentShouldLoad.current = false;
    dispatch(getUserAppData())
      .unwrap()
      .then((appData) => {
        const { user, releaseNotes } = appData;
        if (user) setAppInsightsUserContext();
        if (releaseNotes) dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
      })
      .catch(() => {
        handleLocalLogOut(true);
      });
  }
  return (
    <>
      {user ? (
        children
      ) : (
        <div className="init-user-guard-container">
          <LottieAppLoader testId="init-user-guard" />
        </div>
      )}
    </>
  );
};
