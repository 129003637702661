import { HTMLAttributes } from 'react';

interface IProps extends HTMLAttributes<SVGElement> {
  className?: string;
}

export const DefaultAvatar = ({ className, ...attributes }: IProps) => {
  return (
    <svg
      className={className}
      {...attributes}
      xmlns="http://www.w3.org/2000/svg"
      width="103"
      height="104"
      viewBox="0 0 103 104"
      fill="none"
    >
      <path
        d="M51.5605 102.059C79.1748 102.059 101.561 79.6737 101.561 52.0595C101.561 24.4452 79.1748 2.05948 51.5605 2.05948C23.9463 2.05948 1.56055 24.4452 1.56055 52.0595C1.56055 79.6737 23.9463 102.059 51.5605 102.059Z"
        fill="#7748FF"
        stroke="#FCFBFF"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M81.4451 91.0559C81.4451 83.0113 78.2494 75.2962 72.561 69.6078C66.8726 63.9195 59.1575 60.7238 51.1129 60.7238C43.0683 60.7238 35.3532 63.9195 29.6648 69.6078C23.9765 75.2962 20.7808 83.0113 20.7808 91.0559"
        stroke="#FCFBFF"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.114 60.7238C62.282 60.7238 71.3354 51.6704 71.3354 40.5024C71.3354 29.3344 62.282 20.2809 51.114 20.2809C39.946 20.2809 30.8926 29.3344 30.8926 40.5024C30.8926 51.6704 39.946 60.7238 51.114 60.7238Z"
        stroke="#FCFBFF"
        strokeWidth="2.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
