import { createAction, createReducer } from '@reduxjs/toolkit';
import type { ApiDataStateType } from '../../store/utils';
import type { EAppModalContentType } from './appModals.interfaces';

export interface IAppModalsStore extends ApiDataStateType {
  appModalContentType: EAppModalContentType | null;
}

const initialStateUserStore: IAppModalsStore = {
  appModalContentType: null,
};

const createReducerKey = (subKey: string): string => {
  return 'appModal/' + subKey;
};

export const setAppModalContentType = createAction<EAppModalContentType | null>(
  createReducerKey('setAppModalContentType'),
);

export const appModalsReducer = createReducer(initialStateUserStore, (builder) => {
  builder.addCase(setAppModalContentType, (state, action) => {
    state.appModalContentType = action.payload;
  });
});
