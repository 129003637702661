import { useEffect, useState } from 'react';
import timeIndicatorIcon from '../../../../assets/images/planner/time_indicator.svg';

interface IProps {
  hourHeight: number;
  startingHour: number;
}

export const TimeIndicator = ({ hourHeight, startingHour }: IProps) => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  const getTop = () => {
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();
    return (
      currentHour * hourHeight + (currentMinute * hourHeight) / 60 - startingHour * hourHeight - 5
    );
  };

  return (
    <img
      className="time-indicator"
      src={timeIndicatorIcon}
      alt=""
      style={{ position: 'absolute', top: getTop(), left: -5 }}
    />
  );
};
