import React from 'react';
import { useApiData } from '../../shared/hooks/useApiData';
import { useAppDispatch, useAppSelector } from '../store';
import { ELoginType, type IUser } from './auth.interfaces';
import { useLocalStorage } from '../../shared/utils/useLocalStorage';
import {
  APP_ROUTING_PATHS,
  loginTypeStorageKey,
  refreshTokenLocalStorageKey,
  registrationParametersStorageKey,
  routeParamsLocalStorageKey,
  tokenLocalStorageKey,
} from '../constants';
import { ApplicationInsightsApi, setAppInsightsUserContext } from '../../application-insights';
import { getUserAppData, registrationReqAction } from '../useAppData/user.store';
import { setAppModalContentType } from '../../shared/components/app-modals/appModals.store';
import { EAppModalContentType } from '../../shared/components/app-modals/appModals.interfaces';
import { getItemFromLocalStorage } from '../../shared/utils/localStorage.utils';
import {
  applySearchParamsFromLocalStorage,
  useNavigateWithSearchParams,
} from '../../shared/utils/routingUtils';

export const Auth: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { loginRes } = useAppSelector((store) => store.authReducer);
  const { registrationRes } = useAppSelector((store) => store.userReducer);
  const [, setAccessToken] = useLocalStorage(tokenLocalStorageKey, '');
  const [, setRefreshToken] = useLocalStorage(refreshTokenLocalStorageKey, '');
  const navigate = useNavigateWithSearchParams();
  const dispatch = useAppDispatch();

  useApiData(loginRes, {
    onFulfilled(loginData) {
      if (loginData.token && loginData.refreshToken) {
        const { token, refreshToken } = loginData;
        setAccessToken(token);
        setRefreshToken(refreshToken);
        // apply important search params from local storage if exist during the login flow
        applySearchParamsFromLocalStorage(['routing'], routeParamsLocalStorageKey);
        dispatch(getUserAppData())
          .unwrap()
          .then((data) => {
            const { user } = data;
            if (user) {
              setAppInsightsUserContext();
            }
            if (data.releaseNotes)
              dispatch(setAppModalContentType(EAppModalContentType.RELEASE_NOTES));
            newSignUpFlowLogic(user, data.isNewUser);
          })
          .catch(() => {
            const loginType = getItemFromLocalStorage(loginTypeStorageKey);
            if (loginType !== 'phone') navigate(`${APP_ROUTING_PATHS.REDIRECT}`);
          });
      }
    },
  });

  const newSignUpFlowLogic = (user: IUser | null, isNewUser: boolean) => {
    // registration of inactive open user using registration parameters
    if (!user?.isActive) {
      // user can be a returning user that was previously deactivated (isActive = false)
      // this indicates that the user is trying to get reactivated
      if (isNewUser) ApplicationInsightsApi.trackEvent('*SignUpUserCreated');
      const loginType = getItemFromLocalStorage(loginTypeStorageKey);
      if (loginType === ELoginType.PHONE) {
        ApplicationInsightsApi.trackEvent('*SignUpPhoneNumberEntered');
        ApplicationInsightsApi.trackEvent('*SignUpPhoneNumberValidated');
      }
      dispatch(
        registrationReqAction({
          registrationParameters: localStorage.getItem(registrationParametersStorageKey) || null,
        }),
      );
    } else {
      navigate(APP_ROUTING_PATHS.REDIRECT);
    }
  };

  useApiData(registrationRes, {
    onFulfilled(registrationData) {
      setAppInsightsUserContext();
      if (registrationData.id) {
        navigate(APP_ROUTING_PATHS.REDIRECT);
      }
    }, // on reject, the error message will display to the user on the registration screen
  });

  return <>{children}</>;
};
