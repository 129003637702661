import { createAction, createReducer } from '@reduxjs/toolkit';
import type { IMessageDataTask } from '../stage-tasks/stageTasks.interface';

export interface WorkBlockStoreState {
  shouldOpenWorkBlockDetails: boolean;
  workBlockForEdit: IMessageDataTask | null;
}

const initialStateSharedStore: WorkBlockStoreState = {
  shouldOpenWorkBlockDetails: false,
  workBlockForEdit: null,
};

const createReducerKey = (subKey: string): string => {
  return 'workBlock/' + subKey;
};

export const setShouldOpenWorkBlockDetails = createAction<boolean>(
  createReducerKey('setShouldOpenWorkBlockDetails'),
);

export const setWorkBlockForEdit = createAction<IMessageDataTask | null>(
  createReducerKey('setWorkBlockForEdit'),
);

export const workBlockReducer = createReducer(initialStateSharedStore, (builder) => {
  builder.addCase(setShouldOpenWorkBlockDetails, (state, action) => {
    state.shouldOpenWorkBlockDetails = action.payload;
  });
  builder.addCase(setWorkBlockForEdit, (state, action) => {
    state.workBlockForEdit = action.payload;
  });
});
