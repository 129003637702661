import type { FunctionComponent } from 'react';
import { UserAvatarHeader } from './user-avatar/UserAvatarHeader';
import { ToggleHistoryHeaderIcon } from './toggle-history-icon/ToggleHistoryHeaderIcon';
import { StageTasksDropdownFilters } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import './Header.scss';
import ClearScratchpadButton from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-scratchpad/clear-scratchpad-button/ClearScratchpadButton';
import { PlanUnscheduledFab } from '../../../../features/plan/plan-unscheduled-fab/PlanUnscheduledFab';
import { useSearchParams } from 'react-router-dom';
import {
  planTourDataAttrChangeViewsMobile,
  planTourDataAttrPlannerMobile,
  stageParam,
} from '../../../../app/constants';
import { useTranslation } from 'react-i18next';
import { EAppStageContentType } from '../../../../features/chat-wrapper/resizable-container/stage-container/stageContainer.interfaces';
import { PlanViewSelectionDropdown } from '../../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/plan-view-selection/PlanViewSelectionDropdown';

export const Header: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const displayPlannerFabOrAvatar = () => {
    if (searchParams.get(stageParam) === EAppStageContentType.PLANNER) {
      return <PlanUnscheduledFab planTourDataAttr={planTourDataAttrPlannerMobile} />;
    }
    return null;
  };

  const displayTasksTitle = () => {
    if (searchParams.get(stageParam) === EAppStageContentType.TASKS) {
      return <h1 className="header-title header-title--tasks">{t('stageMyTasksTitle')}</h1>;
    }
    return null;
  };

  return (
    <header className="header-container">
      <div className="header-content">
        {displayTasksTitle()}
        {searchParams.get(stageParam) === EAppStageContentType.PLANNER ? (
          <PlanViewSelectionDropdown data-plan-tour={planTourDataAttrChangeViewsMobile} />
        ) : (
          <StageTasksDropdownFilters />
        )}
        <ClearScratchpadButton />
        <ToggleHistoryHeaderIcon />
        {displayPlannerFabOrAvatar()}
        <UserAvatarHeader />
      </div>
    </header>
  );
};
