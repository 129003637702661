import type { FunctionComponent } from 'react';
import React from 'react';
import { Header } from './header/Header';
import './ContentFrameWrapper.scss';

interface IContentFrameWrapperProps {
  children: React.ReactNode[] | React.ReactNode;
  showHeader?: boolean;
  className?: string;
  shouldDisplayHeader?: boolean;
}

export const ContentFrameWrapper: FunctionComponent<IContentFrameWrapperProps> = ({
  children,
  className,
  shouldDisplayHeader = false,
}) => {
  return (
    <div className={`content-frame-wrapper ${className ? className : ''}`}>
      {shouldDisplayHeader && <Header />}
      {children}
    </div>
  );
};
