import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useState } from 'react';
import type { LoginPageProps } from '../auth.interfaces';
import {
  APP_ROUTING_PATHS,
  isSignUpLocalStorageKey,
  registrationParametersStorageKey,
} from '../../constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { EAPIStatus } from '../../../shared/api/models';
import { LottieAppLoader } from '../../../shared/components/lottie-loader/LottieLoader';
import signInMascot from '../../../assets/images/mascot/onboarding/sign-in-mascot.png';
import { useLocation } from 'react-router-dom';
import './Login.scss';
import LoginActionsButtons from '../login-actions-buttons/LoginActionsButtons';
import { useNavigateWithSearchParams } from '../../../shared/utils/routingUtils';
import { resetLoginResState } from '../auth.store';
import { TermsOfService } from '../register/terms-of-service/TermsOfService';
import { setItemInLocalStorage } from '../../../shared/utils/localStorage.utils';
import { AuthHeroCard } from '../../../shared/components/auth-hero-card/AuthHeroCard';

export enum ELoginPageViewType {
  NEW_USER_VIEW = 1,
  RETURNING_USER_VIEW,
}

export const baseUrl = window.location.origin;
export const Login: FunctionComponent<LoginPageProps> = ({ viewType }: LoginPageProps) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { loginRes } = useAppSelector((store) => store.authReducer);
  const location = useLocation(); // Use useLocation to access URL parameters
  const navigate = useNavigateWithSearchParams();
  const dispatch = useAppDispatch();

  const shouldDisplayError = (): boolean => {
    return (
      // if there is an error from the server(status rejected) or token/refreshToken are not exist in the response(status fulfilled) -> display an error.
      loginRes.status === EAPIStatus.REJECTED ||
      (loginRes.status === EAPIStatus.FULFILLED &&
        (!loginRes.data?.refreshToken || !loginRes.data?.token))
    );
  };

  const storeRegistrationParameters = useCallback(() => {
    localStorage.removeItem(registrationParametersStorageKey);
    const queryParams = new URLSearchParams(location.search);
    const params: { [key: string]: string } = {};
    queryParams.forEach((value, key) => {
      if (value) {
        // Check if the parameter has a value
        params[key] = value;
      }
    });

    if (Object.keys(params).length > 0) {
      // Store only if there are non-empty parameters
      localStorage.setItem(registrationParametersStorageKey, JSON.stringify(params));
    }
  }, [location.search]);

  useEffect(() => {
    storeRegistrationParameters();
  }, [storeRegistrationParameters]);

  useEffect(() => {
    if (viewType === ELoginPageViewType.NEW_USER_VIEW) {
      setItemInLocalStorage(isSignUpLocalStorageKey, true);
    } else {
      localStorage.removeItem(isSignUpLocalStorageKey);
    }
  }, [viewType]);

  const updateLoginPageContent = () => {
    dispatch(resetLoginResState());
    switch (viewType) {
      case ELoginPageViewType.NEW_USER_VIEW:
        navigate(APP_ROUTING_PATHS.SIGN_IN);
        break;
      case ELoginPageViewType.RETURNING_USER_VIEW:
        navigate(APP_ROUTING_PATHS.SIGN_UP);
        break;
      default:
        return;
    }
  };

  const getPageClassNameByViewType = () => {
    switch (viewType) {
      case ELoginPageViewType.NEW_USER_VIEW:
        return 'new-user-view';
      case ELoginPageViewType.RETURNING_USER_VIEW:
        return 'returning-user-view';
      default:
        return '';
    }
  };

  return (
    <div className={`login-container login-container--${getPageClassNameByViewType()} auth-screen`}>
      {isLoading ? (
        <LottieAppLoader testId="lottie-loader" />
      ) : (
        <div className="flex-column">
          <AuthHeroCard mascot={signInMascot} title={t('signInTitle')} />
          <LoginActionsButtons
            viewType={viewType}
            isPageLoading={isLoading}
            setIsPageLoading={setIsLoading}
          />
          {shouldDisplayError() && (
            <small className="app-error login-error">{t('somethingWentWrongTryAgainError')}</small>
          )}
          <div className="login-redirect-user-container">
            <p className="login-redirect-user-text">
              {viewType === ELoginPageViewType.NEW_USER_VIEW
                ? t('loginReturningUserAsk')
                : t('loginNewUserAsk')}
            </p>
            <p
              className="login-redirect-user-text login-redirect-user-text--link"
              onClick={updateLoginPageContent}
            >
              {viewType === ELoginPageViewType.NEW_USER_VIEW ? t('loginSignIn') : t('loginSignUp')}
            </p>
          </div>
          <TermsOfService shouldAddVerifiedAge={true} />
        </div>
      )}
    </div>
  );
};
