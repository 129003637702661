import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { APP_ROUTING_PATHS } from '../../constants';
import './CompleteLink.scss';
import phoneMascotImg from '../../../assets/images/mascot/phone-mascot.png';
import { AuthHeroCard } from '../../../shared/components/auth-hero-card/AuthHeroCard';
import { handleLocalLogOut } from '../../../shared/utils/logOut';

const CompleteLink = () => {
  const { t } = useTranslation();

  return (
    <div className="complete-link-page auth-screen">
      <div className="complete-link-content-container">
        <AuthHeroCard mascot={phoneMascotImg} title={t('completeLinkTitle')} />
        <p className="complete-link-description">{t('completeLinkDescription')}</p>
        <Link
          to={APP_ROUTING_PATHS.SIGN_IN}
          className="auth-next-btn"
          id="complete-link-submit"
          data-testid="complete-link-submit"
          onClick={() => handleLocalLogOut()}
        >
          {t('completeLinkButtonText')}
        </Link>
      </div>
    </div>
  );
};

export default CompleteLink;
