import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { chatHistoryParam, messageTestId } from '../../../../app/constants';
import Feedback from './feedback/Feedback';
import { uuid } from '../../../../shared/utils/uuid';
import MessageDateTime from './message-date-time/MessageDateTime';
import { useAppDispatch, useAppSelector } from '../../../../app/store';
import { setScrollIntoMessageId } from '../../chat.store';
import { useSearchParams } from 'react-router-dom';
import { ApplicationInsightsApi } from '../../../../application-insights';
import type { IMessageProps } from './MessageWrapper';
import './Message.scss';

const Message: FunctionComponent<IMessageProps> = ({ msgItem, hideMsgTime = false }) => {
  const { scrollIntoMessageId } = useAppSelector((store) => store.chatReducer);
  const dispatch = useAppDispatch();
  const messageRef = useRef<HTMLDivElement | null>(null);
  const [, setSearchParams] = useSearchParams();

  const closeSummariesPopUp = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete(chatHistoryParam);
      return prev;
    });
  }, [setSearchParams]);

  useEffect(() => {
    if (scrollIntoMessageId && msgItem.msgId === scrollIntoMessageId && messageRef.current) {
      ApplicationInsightsApi.trackTrace(
        'Message useEffect - scroll into message after it displayed in the DOM',
        {
          scrollIntoMessageId: msgItem.msgId,
          scrollIntoMessageParty: msgItem.party,
        },
      );
      // close the summaries popup and scroll to the current message
      dispatch(setScrollIntoMessageId(null));
      closeSummariesPopUp();
      messageRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
    }
  }, [dispatch, msgItem.msgId, scrollIntoMessageId, closeSummariesPopUp, msgItem.party]);

  const getContentByType = () => {
    switch (msgItem.party) {
      case 'Bot':
        return <Feedback msgItem={msgItem} />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`message ${msgItem.party.toLowerCase()}`}
      id={`${msgItem?.msgId || uuid()}`}
      data-testid={`message-${msgItem?.msgId}` || 'message-testid'}
      ref={messageRef}
    >
      <div className="message-content-container">
        {msgItem.shouldDisplayTime && !hideMsgTime && (
          <MessageDateTime messageTime={msgItem.messageTime} messageType={msgItem.party} />
        )}
        <div className="msg-content" data-testid={`${messageTestId}`}>
          {msgItem.msg}
          {getContentByType()}
        </div>
      </div>
    </div>
  );
};
export default Message;
