import type { AnimationEvent, ReactNode } from 'react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { setClickNavbarConfirmModalText } from '../../store/shared.store';
import { useAppDispatch } from '../../../app/store';
import AppButton from '../app-button/AppButton';
import { useSearchParams } from 'react-router-dom';
import './DialogWrapperWithAnimation.scss';

interface IDialogWrapperWithAnimationProps {
  children: ReactNode | ReactNode[];
  onDestroyAnimationEnd: () => void;
  SvgCloseIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  id: string;
  className?: string;
  searchParamToUpdate?: string;
  onClose?: () => void;
  shouldCloseDialog?: boolean;
  shouldDestroyOnClose?: boolean;
  testId?: string;
}

export type TAnimationClassName = 'fadeInSlideUp' | 'slideOutDown' | 'endSlideAnimation';

const DialogWrapperWithAnimation: React.FunctionComponent<IDialogWrapperWithAnimationProps> = ({
  onClose,
  SvgCloseIcon,
  searchParamToUpdate,
  onDestroyAnimationEnd,
  children,
  shouldCloseDialog = false,
  id = '',
  className = '',
  shouldDestroyOnClose = true,
  testId = '',
}) => {
  const [animationClassName, setAnimationClassName] =
    useState<TAnimationClassName>('fadeInSlideUp');
  const [, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const prevSearchParamToUpdateRef = useRef<string | null>(searchParamToUpdate || null);

  useEffect(() => {
    // to ensure the AppEventListenerHandler component render its focus/blur app events on the controllers in this component
    if (searchParamToUpdate) {
      setSearchParams((prev) => {
        if (prevSearchParamToUpdateRef.current) prev.delete(prevSearchParamToUpdateRef.current);
        prev.delete(searchParamToUpdate);
        prev.append(searchParamToUpdate, 'open');
        return prev;
      });
      prevSearchParamToUpdateRef.current = searchParamToUpdate;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParamToUpdate]);

  const onClickClose = useCallback(() => {
    if (shouldDestroyOnClose) {
      dispatch(setClickNavbarConfirmModalText(null));
      setAnimationClassName('slideOutDown');
      if (searchParamToUpdate) {
        setSearchParams((prev) => {
          prev.delete(searchParamToUpdate);
          return prev;
        });
      }
    }
    !!onClose && onClose();
  }, [dispatch, onClose, searchParamToUpdate, setSearchParams, shouldDestroyOnClose]);

  useEffect(() => {
    // close dialog by props.children
    if (shouldCloseDialog) onClickClose();
  }, [onClickClose, shouldCloseDialog]);

  const onAnimationEnd = (animationData: AnimationEvent<HTMLDivElement>) => {
    if (animationData.animationName === 'slideOutDownAnimation') {
      //when animation end destroy the component
      onDestroyAnimationEnd();
    }
    setAnimationClassName('endSlideAnimation');
  };

  return (
    <div
      id={id}
      data-testid={testId}
      className={`details-dialog-animation-container ${animationClassName}`}
      onAnimationEnd={onAnimationEnd}
    >
      <div className={`details-dialog-inner-container ${className}`}>
        <AppButton
          id={`close-${id}-dialog`}
          className="close-dialog"
          onClick={() => onClickClose()}
          data-testid="close"
        >
          <SvgCloseIcon />
        </AppButton>
        {children}
      </div>
    </div>
  );
};

export default DialogWrapperWithAnimation;
