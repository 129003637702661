import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../../../../app/store';
import type { IProfileForm } from '../SettingsMenu';
import { useFormContext } from 'react-hook-form';
import { updateUserProfileReq } from '../../../../../app/useAppData/user.store';
import { TextAndToggle } from '../../../Text-And-Toggle/TextAndToggle';
import { ErrorMessage } from '../error-message/ErrorMessage';
import { FlagUtils } from '../../../../utils/flagUtils';
import { EClientFlags } from '../SettingsMenu.interfaces';

const AudioControl = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const form = useFormContext<IProfileForm>();

  const watchShouldDisplayAudioError = form.watch('shouldDisplayAudioError');
  const watchClientFlags = form.watch('clientFlags');

  const onUpdateNotificationEnabled = (updatedClientFlags: number) => {
    const reqPayload = { clientFlags: updatedClientFlags };
    dispatch(updateUserProfileReq(reqPayload))
      .unwrap()
      .then()
      .catch(() => {
        // display error and revert value
        form.setValue('shouldDisplayAudioError', true);
        form.setValue(
          'clientFlags',
          FlagUtils.toggleFlag(updatedClientFlags, EClientFlags.AUDIO_OFF),
        );
      });
  };

  const onChangeAudioSwitch = () => {
    const updatedClientFlags = FlagUtils.toggleFlag(watchClientFlags, EClientFlags.AUDIO_OFF);
    if (watchShouldDisplayAudioError) form.setValue('shouldDisplayAudioError', false);
    form.setValue('clientFlags', updatedClientFlags);
    onUpdateNotificationEnabled(updatedClientFlags);
  };

  return (
    <section className="notification">
      <TextAndToggle
        header={t('settingsAudioControlHeader')}
        subHeader={t('settingsAudioControlSubHeader')}
        toggleClassName="app-toggle app-toggle-active-dark"
        toggleActive={!FlagUtils.getFlag(watchClientFlags, EClientFlags.AUDIO_OFF)}
        onToggle={() => onChangeAudioSwitch()}
      />
      {watchShouldDisplayAudioError && <ErrorMessage />}
    </section>
  );
};

export default AudioControl;
