import type { FunctionComponent } from 'react';
import React from 'react';
import { useAppSelector } from '../../../../../../../../app/store';
import { getDateWithoutYear } from '../../../../../../../../shared/utils/dateFormat';
import { ReactComponent as BellIconComponent } from '../../../../../../../../assets/images/task-bell-icon.svg';
import { ReactComponent as WorkBlockIconComponent } from '../../../../../../../../assets/images/work-block-icon.svg';
import { useTranslation } from 'react-i18next';
import { isTaskAssignToThisWorkBlock } from '../../../../../../../../shared/utils/utils';

interface IWorkBlockDataContent {
  workBlockId: string;
  workBlockInstance: string | Date | null;
  onClickTaskWorkBlock: (e: React.MouseEvent<HTMLElement, any>) => void;
}

const WorkBlockDataContent: FunctionComponent<IWorkBlockDataContent> = ({
  workBlockId,
  workBlockInstance,
  onClickTaskWorkBlock,
}) => {
  const { t } = useTranslation();
  const { allWorkBlocks } = useAppSelector((store) => store.StageTasksReducer);
  const workBlock =
    allWorkBlocks.find((w) =>
      isTaskAssignToThisWorkBlock(w.id!, w?.workTime || '', workBlockId, workBlockInstance),
    ) || null;

  return (
    <div
      data-testid="work-block-item"
      className="scheduling-section-data-content-container scheduling-section-data-content-container--work-block"
      onClick={onClickTaskWorkBlock}
    >
      <div className="scheduling-icon-and-label-container">
        <WorkBlockIconComponent className={`scheduling-icon task-detail-icon`} />
        <p>
          {workBlock?.name || t('workBlockDefaultName')}{' '}
          {workBlock?.workTime ? getDateWithoutYear(workBlock?.workTime) : ''}
        </p>
      </div>
      <div>
        {(!!workBlock?.workTimeReminder || workBlock?.workTimeReminder === 0) && (
          <div id="work-block-bell-reminder" className={`bell-reminder`}>
            <BellIconComponent className={`task-detail-icon task-detail-icon--right`} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkBlockDataContent;
