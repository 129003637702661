import { useEffect } from 'react';
import { EPlanDayCardDisplayType, PlanDayCard } from '../plan-day-card/PlanDayCard';

interface MyWeekProps {
  myPlanDays?: number;
}

export const MyWeek = ({ myPlanDays }: MyWeekProps) => {
  useEffect(() => {
    document.body.setAttribute('data-plan', 'my-week');
    const element = document.getElementById('plan-scroll-container');
    if (element) element.scroll({ left: 0, behavior: 'auto' });
    return () => {
      document.body.removeAttribute('data-plan');
    };
  }, []);

  return (
    <>
      {Array.from({ length: myPlanDays || 0 }).map((_, index) => (
        <PlanDayCard key={index} index={index} displayType={EPlanDayCardDisplayType.MY_WEEK} />
      ))}
    </>
  );
};
