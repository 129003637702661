import { Navigate, useParams } from 'react-router-dom';
import { APP_ROUTING_PATHS, sessionSourceTypeStorageKey } from '../../app/constants';

export const SessionSourceExtraction = () => {
  const { sessionSourceTypeId } = useParams();
  if (sessionSourceTypeId) {
    localStorage.setItem(sessionSourceTypeStorageKey, JSON.stringify(sessionSourceTypeId));
  }
  return <Navigate to={APP_ROUTING_PATHS.REDIRECT} />;
};
