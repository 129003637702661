import { useEffect } from 'react';
import { getOperatingSystem } from '../../shared/utils/getMobileOperatingSystem';
import { EOperatingSystem } from '../../app/auth/auth.interfaces';
import { useNavigate } from 'react-router-dom';
import {
  APP_ROUTING_PATHS,
  linkToAppInStoreAndroid,
  linkToAppInStoreiOS,
} from '../../app/constants';

export const OpenAppStore = () => {
  const navigate = useNavigate();

  useEffect(() => {
    switch (getOperatingSystem()) {
      case EOperatingSystem.ANDROID:
        window.location.href = linkToAppInStoreAndroid;
        break;
      case EOperatingSystem.IOS:
        window.location.href = linkToAppInStoreiOS;
        break;
      default:
        navigate(APP_ROUTING_PATHS.SIGN_UP);
        break;
    }
  });

  return null;
};
