import { useTranslation } from 'react-i18next';
import '../Message.scss';
import { useAppDispatch } from '../../../../../app/store';
import { createOrContinueSession } from '../../../chat.store';

export const CreateSessionErrorMessage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onCreateSession = () => {
    dispatch(createOrContinueSession());
  };

  return (
    <div className="message reject-error create-session-error">
      <div className="msg-content" data-testid="create-session-error">
        {t('somethingWentWrongCanYouError')}{' '}
        <button
          className="try-again-create-session"
          data-testid="retry-create-session"
          onClick={onCreateSession}
        >
          {t('tryAgainError')}
        </button>
        ?
      </div>
    </div>
  );
};
