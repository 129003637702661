import { useTranslation } from 'react-i18next';
import './ErrorMessage.scss';

export const ErrorMessage = () => {
  const { t } = useTranslation();

  return (
    <small className="update-settings-error app-error" data-testid="settings-error">
      {t('pleaseTryAgainError')}
    </small>
  );
};
