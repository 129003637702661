import type { FunctionComponent } from 'react';
import type { IChatMessageData, ISingleMessageDataRes } from '../../../chat.interfaces';
import { EChatMessageDataType } from '../../../chat.interfaces';
import CardWrapper from '../../../../../shared/components/card-wrapper/CardWrapper';
import TasksList from './tasks-list/TasksList';
import SingleTask from './single-task/SingleTask';
import { aiToolNames } from '../../../../../app/constants';

interface IMessageDataCardProps {
  msgData: IChatMessageData | ISingleMessageDataRes;
  msgToolName: string | null | undefined;
}

const MessageDataCard: FunctionComponent<IMessageDataCardProps> = ({ msgData, msgToolName }) => {
  const switchCardsByDataType = () => {
    switch (msgData.type) {
      case EChatMessageDataType.TASKS_LIST:
        return <TasksList msgData={msgData as IChatMessageData} />;
      case EChatMessageDataType.SINGLE_TASK:
        return <SingleTask msgData={msgData as ISingleMessageDataRes} />;
      default:
        return <></>;
    }
  };

  const shouldDisplayCard = () => {
    // if there is content - display the card inside the chat
    return Object.keys(msgData.content || {}).length > 0 && msgToolName === aiToolNames.LIST_TASKS;
  };

  return <>{shouldDisplayCard() && <CardWrapper>{switchCardsByDataType()}</CardWrapper>}</>;
};

export default MessageDataCard;
