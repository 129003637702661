import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { resetUserProfileRequest } from '../../settingsMenu.store';
import { ConfirmModal } from '../../../../confirm-modal/ConfirmModal';
import { useApiData } from '../../../../../hooks/useApiData';
import { isErrorCodeNotForbiddenOrUnauthorized } from '../../../../../utils/isErrorCodeNotForbiddenOrUnauthorized';
import { ErrorMessage } from '../../error-message/ErrorMessage';
import { LottieAppLoader } from '../../../../lottie-loader/LottieLoader';
import { EAPIStatus } from '../../../../../api/models';
import type { IResetUserReqPayload } from '../../SettingsMenu.interfaces';
import { handleLocalLogOut } from '../../../../../utils/logOut';
import AppButton from '../../../../app-button/AppButton';

export const ResetProfile = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [shouldOpenConfirmReset, setShouldOpenConfirmReset] = useState(false);
  const [isPartialReset, setIsPartialReset] = useState(false);
  const [shouldDisplayError, setShouldDisplayError] = useState(false);
  const { resetUserProfile } = useAppSelector((store) => store.settingsMenuReducer);
  const { user: userInfo } = useAppSelector((store) => store.userReducer);

  useApiData(resetUserProfile, {
    onRejected(error) {
      if (isErrorCodeNotForbiddenOrUnauthorized(error.code)) setShouldDisplayError(true);
    },
  });

  const handleReset = async () => {
    const resetPayload: IResetUserReqPayload = { isPartialReset };
    setShouldOpenConfirmReset(false);
    await dispatch(resetUserProfileRequest(resetPayload));
    handleLocalLogOut();
  };

  return (
    <Fragment>
      <section className="data">
        <div>
          <h3 className="title static-string">{t('settingsPersonalInfoDataTitle')}</h3>
          <p>{t('settingsPersonalInfoDataSubTitle')}</p>
        </div>
        <div className="button-container">
          <AppButton
            className="personal-info-btn"
            data-testid="reset-profile"
            id="reset-profile"
            onClick={() => setShouldOpenConfirmReset(true)}
          >
            {t('settingsPersonalInfoDeleteProfileButton')}
          </AppButton>
          {userInfo?.isInternalUser && (
            <AppButton
              className="personal-info-btn"
              data-testid="partial-reset-profile"
              id="partial-reset-profile"
              disabled={resetUserProfile.status === EAPIStatus.PENDING}
              onClick={() => {
                setIsPartialReset(true);
                setShouldOpenConfirmReset(true);
              }}
            >
              {t('settingsPersonalInfoPartialResetProfileButton')}
            </AppButton>
          )}
        </div>
        {shouldDisplayError && <ErrorMessage />}
      </section>
      {shouldOpenConfirmReset && (
        <ConfirmModal
          title={t('settingsResetProfileConfirmModalTitleText')}
          informationText={t('settingsResetProfileConfirmModalSubTitleText')}
          onCancel={() => setShouldOpenConfirmReset(false)}
          onConfirm={handleReset}
          confirmBtnText={t('settingsResetProfileConfirmModalConfirmButtonText')}
          isDisabled={resetUserProfile.status === EAPIStatus.PENDING}
        />
      )}
      {resetUserProfile.status === EAPIStatus.PENDING && (
        <LottieAppLoader testId="reset-profile-loader" className="reset-profile-loader" />
      )}
    </Fragment>
  );
};
