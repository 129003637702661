import React from 'react';
import closeIcon from '../../../../../../../assets/images/small-close-icon.svg';
import type { ITaskTag } from '../../stageTasks.interface';
import SassVariables from '../../../../../../../styles/style.module.scss';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';

interface ISingleTagProps {
  tag: ITaskTag;
  index: number;
  onDeleteTag?: (index: number) => void;
  onClickTag?: () => void;
}

const SingleTag = ({ tag, index, onDeleteTag, onClickTag }: ISingleTagProps) => {
  const isColorExist = !!tag?.color && tag.color !== 'transparent';

  const taskStyle = {
    backgroundColor: tag.color || 'transparent',
    color: isColorExist ? 'white' : SassVariables.MaxDarkColor,
    borderColor: isColorExist ? SassVariables.Neutral1Color : SassVariables.MaxDarkColor,
  };

  const closeImgStyle = {
    filter: isColorExist ? 'none' : 'invert(1)',
  };

  return (
    <div
      className={`single-tag ${!tag.name ? 'without-name' : ''}`}
      style={taskStyle}
      onClick={() => !!onClickTag && onClickTag()}
    >
      {tag.name}
      {!!onDeleteTag && index >= 0 && (
        <AppButton
          id="delete-tag"
          className="delete-tag"
          type="button"
          onClick={() => onDeleteTag(index)}
        >
          <img src={closeIcon} alt="close" style={closeImgStyle} />
        </AppButton>
      )}
    </div>
  );
};

export default SingleTag;
