import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { setSeoDocumentTitle } from '../../utils/seoDocumentTitle';
import { APP_ROUTING_PATHS } from '../../../app/constants';
import './Page404.scss';

export const Page404 = () => {
  const { t } = useTranslation();

  useEffect(() => {
    setSeoDocumentTitle(t('titlePage404'));
  }, [t]);

  return (
    <div className="page-404">
      <div className="flex-content-container">
        <h1>404</h1>
        <h3>{t('page404')}</h3>
        <Link to={`${APP_ROUTING_PATHS.HOME}/${APP_ROUTING_PATHS.CHAT}`}>{t('goToHomePage')}</Link>
      </div>
    </div>
  );
};
