import { noRedirectLocalStorageKey } from '../../app/auth/auth.utils';
import {
  API_ROUTES,
  lastSurveyOfferedDayOfWeekKey,
  plannerModeOnLocalStorageKey,
  recentTaskTagsLocalStorageKey,
  refreshTokenLocalStorageKey,
  selectedPlanViewTypeLocalStorageKey,
  shouldShowDiscordToastInCurrentSessionKey,
  turnsCounterStorageKey,
} from '../../app/constants';
import { ApplicationInsightsApi } from '../../application-insights';
import { apiServiceCSharp } from '../api/axios';
import { clearSearchParamsFromCurrentUrl } from './routingUtils';

const clearClientSession = () => {
  try {
    Object.keys(localStorage).forEach((key) => {
      if (
        ![
          recentTaskTagsLocalStorageKey,
          noRedirectLocalStorageKey,
          selectedPlanViewTypeLocalStorageKey,
          plannerModeOnLocalStorageKey,
          turnsCounterStorageKey,
          shouldShowDiscordToastInCurrentSessionKey,
          lastSurveyOfferedDayOfWeekKey,
        ].includes(key)
      ) {
        localStorage.removeItem(key);
      }
    });
    clearSearchParamsFromCurrentUrl();
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
    console.error('Client session cleanup failed.', error);
  }
};

const clearBackendSession = async () => {
  try {
    // Retrieve the refreshToken from localStorage, remove any surrounding whitespace with `trim`,
    // and strip wrapping double quotes (") using a regular expression, if present.
    const refreshToken = localStorage
      .getItem(refreshTokenLocalStorageKey)
      ?.trim()
      .replace(/^"|"$/g, '');
    if (!refreshToken) {
      return;
    }
    await apiServiceCSharp.post(API_ROUTES.AUTH.LOGOUT, { refreshToken });
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  }
};

export const handleLogOut = async (shouldReload = true) => {
  try {
    await clearBackendSession();
  } catch (error) {
    ApplicationInsightsApi.trackException(error);
  } finally {
    clearClientSession();
  }

  // Reload the page if required
  if (shouldReload) {
    try {
      window.location.reload();
    } catch (error) {
      // Handle errors during page reload
      console.error('Page reload failed.', error);
      ApplicationInsightsApi.trackException(error);
    }
  }
};

export const handleLocalLogOut = (shouldReload = true) => {
  clearClientSession();
  if (shouldReload) window.location.reload();
};
