import { usePreview } from 'react-dnd-multi-backend';
import type { CSSProperties } from 'react';
import { useRef } from 'react';
import { useAppSelector } from '../../../app/store';
import {
  EDragAndDropType,
  getDomElementByQuerySelector,
  isCursorInElement,
} from '../../utils/utils';
import type { IDragAndDropPreviewCalendarDimensions } from './DragAndDrop.utils';
import { getPreviewTransformStyle } from './DragAndDrop.utils';
import { plannerTaskListContainerClassName } from '../../../app/constants';
import type { IDragItem } from '../../../features/plan/calendar/calendar-day/CalendarDragAndDrop.util';
import SassVariables from '../../../styles/style.module.scss';
import { EPlanViewSelection } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';
import { ReactComponent as CircleIcon } from '../../../assets/images/plan-calendar/calendar-event-circle-icon.svg';
import { ReactComponent as WorkBlockIcon } from '../../../assets/images/work-block-icon.svg';
import { ReactComponent as EventIcon } from '../../../assets/images/single-task/event-icon.svg';
import { ETaskStatus } from '../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import {
  calcEventTitleVisibleLines,
  getCalendarEventIconColor,
  getCalendarEventInnerContainerClassName,
} from '../../../features/plan/calendar/calendar-day/CalendarDay.util';

export const DraggedCalendarPlanDestinationPreview = () => {
  const ref = useRef(null);
  const preview = usePreview();
  const { dragAndDropCalendarDimensions, calendarFirstCellVisiblePortion } = useAppSelector(
    (store) => store.StagePlannerReducer,
  );
  const { planView } = useAppSelector((store) => store.StagePlannerReducer);

  if (!preview.display) {
    return null;
  }

  const { item, itemType, style, monitor } = preview;
  const draggedEvent = (item as IDragItem).event;
  const previewStyle = (item as IDragItem)?.previewStyle || {};
  let shouldSnap: boolean = itemType === EDragAndDropType.CALENDAR_EVENT;
  let width = previewStyle.width;
  let height = previewStyle.height;
  let backgroundColor = SassVariables.planScheduledItemBackground;
  let shouldMoveOnXAxis = true;
  let left = 0;
  let titleColor = SassVariables.appDarkColor;
  const iconColor = getCalendarEventIconColor(draggedEvent.backgroundColor);
  const calendarCellWidth = dragAndDropCalendarDimensions?.cellWidth;

  if (itemType === EDragAndDropType.PLANNER_TO_CALENDAR_EVENT) {
    const plannerContainerEl = getDomElementByQuerySelector(
      `.${plannerTaskListContainerClassName}`,
    );
    const isOverPlanner = isCursorInElement(
      plannerContainerEl,
      monitor.getClientOffset()?.x,
      monitor.getClientOffset()?.y,
    );
    shouldSnap = !isOverPlanner;
    if (isOverPlanner) {
      width = previewStyle.width;
      height = previewStyle.height;
      shouldMoveOnXAxis = false;
    }
  }

  if (shouldSnap) {
    width = calendarCellWidth ? `${calendarCellWidth}px` : previewStyle?.width;
    height = draggedEvent?.height ? `${draggedEvent?.height}px` : previewStyle?.height;

    if (
      [EDragAndDropType.CALENDAR_EVENT, EDragAndDropType.PLANNER_TO_CALENDAR_EVENT].includes(
        itemType as EDragAndDropType,
      ) &&
      planView === EPlanViewSelection.MY_DAY &&
      calendarCellWidth
    ) {
      left = 40;
      width = `${calendarCellWidth - left}px`;
    }
  }

  const getCalendarDimensionsForCalcPreviewPosition =
    (): IDragAndDropPreviewCalendarDimensions | null => {
      if (!dragAndDropCalendarDimensions || !calendarFirstCellVisiblePortion) return null;
      return {
        ...dragAndDropCalendarDimensions,
        firstCellVisiblePortion: calendarFirstCellVisiblePortion,
      };
    };

  const previewTransformAndPosition = getPreviewTransformStyle(
    monitor.getInitialSourceClientOffset(),
    monitor.getClientOffset(),
    shouldSnap,
    getCalendarDimensionsForCalcPreviewPosition(),
    shouldMoveOnXAxis,
    previewStyle?.left,
    planView === EPlanViewSelection.MY_WEEK
      ? parseInt(SassVariables?.calendarDistanceBetweenDaysOnMyWeek) - 1
      : 0,
  );

  const localStyle: CSSProperties = {
    ...style,
    ...previewStyle,
    ...previewTransformAndPosition.style,
    left: `${left}px`,
    width,
    height,
    backgroundColor,
    cursor: 'move',
    color: titleColor,
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    borderRadius: '8px',
    textAlign: 'start',
  };

  (item as IDragItem).snapYDroppedPosition = previewTransformAndPosition.snapY;

  const getCalendarEventIcon = () => {
    if (draggedEvent.isWorkBlock) {
      return <WorkBlockIcon className="work-block-icon" />;
    } else if (draggedEvent.isEvent) {
      return (
        <div className="event-icon-circle-background">
          <EventIcon className={`event-icon ${iconColor ? 'event-icon--white' : ''}`} />
        </div>
      );
    }
    return <CircleIcon className="task-circle-icon" />;
  };

  return (
    <div className={`calendar-event calendar-event--${draggedEvent.durationType}`}>
      <div
        ref={ref}
        style={localStyle}
        className={getCalendarEventInnerContainerClassName(planView, draggedEvent.durationType)}
      >
        <div className="event-text-container">
          <h3
            style={
              {
                ...(iconColor && {
                  '--calendar-event-first-label-color': iconColor,
                }),
              } as React.CSSProperties
            }
            className={`calendar-event-title calendar-event-title--${
              draggedEvent.isEvent && !draggedEvent.isWorkBlock ? 'event' : ''
            } ${draggedEvent.status === ETaskStatus.DONE ? 'completed-task' : ''}`}
          >
            {getCalendarEventIcon()}{' '}
            <span
              className="title-text"
              style={{
                WebkitLineClamp: calcEventTitleVisibleLines(draggedEvent), // Calculate visible lines
              }}
            >
              {draggedEvent?.title}
            </span>
          </h3>
        </div>
      </div>
    </div>
  );
};
