import type { HTMLAttributes } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { setTasksFilter } from '../stageTasks.store';
import { ETasksGroupsType } from '../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { ReactComponent as FilterIcon } from '../../../../../../assets/images/filter.svg';
import './StageTasksDropdownFilters.scss';
import { useSearchParams } from 'react-router-dom';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  filters?: ETasksGroupsType[];
}

export const StageTasksDropdownFilters = ({ ...attributes }: IProps) => {
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const { tasksFilter } = useAppSelector((store) => store.StageTasksReducer);
  const filterOptionsArr: ETasksGroupsType[] = [
    ETasksGroupsType.ALL_TASKS,
    ETasksGroupsType.QUICK_WINS,
    ETasksGroupsType.UNSCHEDULED,
    ETasksGroupsType.OVERDUE,
    ETasksGroupsType.DONE,
  ];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = () => {
      // if click at the document and the dropDown is open, close it
      if (dropdownRef.current && dropdownRef.current.clientHeight > 0) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleFilter = (filter: ETasksGroupsType) => {
    dispatch(setTasksFilter(filter));
  };

  // const handleBlur = () => {
  //   if (isOpen) {
  //     setTimeout(() => {
  //       setIsOpen(false);
  //     }, 20);
  //   }
  // }

  return (
    <div className={`stage-tasks-dropdown-filter ${isOpen ? 'open' : ''} `} {...attributes}>
      <AppButton
        id="open-stage-filter-dropdown-menu"
        type="button"
        className="dropdown-toggle"
        // onBlur={handleBlur}
        onClick={() => setIsOpen(!isOpen)}
        shouldChangeBackgroundOnActive={false}
      >
        <FilterIcon />
      </AppButton>
      <div className={`dropdown-menu`} ref={dropdownRef}>
        <div className="dropdown-view">
          <FilterIcon />
        </div>
        {filterOptionsArr.map((filterItem) => (
          <AppButton
            id={`${filterItem?.trim()}-filter-stage-dropdown-menu`}
            key={filterItem}
            className={`dropdown-item ${filterItem === tasksFilter && 'active'}`}
            onClick={() => {
              handleFilter(filterItem);
            }}
            shouldChangeBackgroundOnActive={false}
          >
            {filterItem}
          </AppButton>
        ))}
      </div>
    </div>
  );
};
