import { getItemFromLocalStorage } from '../../shared/utils/localStorage.utils';
import { APP_ROUTING_PATHS, tokenLocalStorageKey } from '../constants';
import { matchPath } from 'react-router';

export const baseUrl = window.location.origin;
export const noRedirectLocalStorageKey = 'noRedirect';
const authUrls = [
  baseUrl + APP_ROUTING_PATHS.SIGN_IN,
  baseUrl + APP_ROUTING_PATHS.SIGN_UP,
  baseUrl + APP_ROUTING_PATHS.SSO,
  baseUrl + APP_ROUTING_PATHS.SSO_MAGIC_LINK,
  baseUrl + APP_ROUTING_PATHS.MAGIC_LINK_PHONE,
  baseUrl + APP_ROUTING_PATHS.MORE_INFO,
  baseUrl + APP_ROUTING_PATHS.MAGIC_LINK_EMAIL,
  baseUrl + APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_APPLE,
  baseUrl + APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_GOOGLE,
  baseUrl + APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_EMAIL,
  baseUrl + APP_ROUTING_PATHS.MOBILE_APP_CHECK_LOGIN_PHONE,
  baseUrl + APP_ROUTING_PATHS.OPEN_APP_STORE,
];

export const setNoRedirectFlagInLocalStorage = () => {
  localStorage.setItem(noRedirectLocalStorageKey, '1');
};

export const redirectNewUserToOnboarding = () => {
  const token = getItemFromLocalStorage<string>(tokenLocalStorageKey);
  if (token) {
    setNoRedirectFlagInLocalStorage();
    return;
  }
  const incomingUrlPath = window.location.pathname.split('?')[0];
  const incomingUrl = window.location.href.split('?')[0];
  // session source is a dynamic parameter in the /n/:sessionSourceTypeId path
  // try to match the incoming url path e.g /n/x with the session source type path, if not matched value will be null
  const matchDynamicSessionSourcePath = matchPath(
    { path: APP_ROUTING_PATHS.SESSION_SOURCE_TYPE, end: true },
    incomingUrlPath,
  );
  if (
    noRedirectLocalStorageKey in localStorage ||
    authUrls.includes(incomingUrl) ||
    matchDynamicSessionSourcePath
  ) {
    return;
  }
  window.location.href = 'https://join.maximallearning.com/';
};

export const isEmbeddedBrowser = (): boolean => {
  const ua = navigator.userAgent;
  return ua.includes('Instagram') || ua.includes('FBAN') || ua.includes('FBAV');
};
