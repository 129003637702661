import type { CSSProperties, HTMLAttributes, MutableRefObject, ReactNode } from 'react';
import type { ConnectableElement, DragSourceMonitor } from 'react-dnd';
import { useDrag } from 'react-dnd';
import type { IUseDragCollectedProps } from '../../../features/plan/calendar/calendar-day/CalendarEvent';
import type { EDragAndDropType, IPreviewStyleForDestinationPreview } from '../../utils/utils';
import { TransparentDefaultPreview } from './TransparentDefaultDraggedPreview';

export interface IDraggedPreviewStyle {
  previewStyle: IPreviewStyleForDestinationPreview;
}

interface IDraggableWrapper<TDragItem extends IDraggedPreviewStyle, TDropResult>
  extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode | ReactNode[];
  id: string;
  item: TDragItem;
  type: EDragAndDropType;
  canDrag?: () => boolean;
  onEndDrag?: (draggedItem: TDragItem, monitor: DragSourceMonitor<TDragItem, TDropResult>) => void;
  wrapperRef?: MutableRefObject<HTMLDivElement | null>;
  keyAttr: string;
  // in case the dragged element is also a droppable area
  dropRef?: (elementOrNode: ConnectableElement, options?: any) => React.ReactElement | null;
}

const DraggableWrapper = <TDragItem extends IDraggedPreviewStyle, TDropResult>({
  item,
  type,
  onEndDrag,
  canDrag,
  children,
  wrapperRef,
  id,
  dropRef,
  keyAttr,
  ...attributes
}: IDraggableWrapper<TDragItem, TDropResult>) => {
  const [{ opacity }, dragRef, dragPreview] = useDrag<
    TDragItem,
    TDropResult,
    IUseDragCollectedProps
  >({
    type: type,
    item: { ...item }, // Data about the event being dragged
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
    canDrag: () => (canDrag ? canDrag() : true),
    end(draggedItem, monitor) {
      if (onEndDrag) onEndDrag(draggedItem, monitor);
    },
  });

  let containerStyle: CSSProperties = {
    opacity,
  };

  if (attributes.style) containerStyle = { ...containerStyle, ...attributes.style };

  const getAttr = () => {
    const tempAttributes = { ...attributes };
    delete tempAttributes?.className;
    delete tempAttributes?.style;
    return { ...tempAttributes };
  };

  return (
    <>
      <div
        ref={(node) => {
          dragRef(node);
          if (wrapperRef !== undefined) wrapperRef.current = node;
          if (dropRef) dropRef(node);
        }}
        className={`draggable-wrapper ${attributes.className ? attributes.className : ''}`}
        style={containerStyle}
        key={keyAttr}
        {...getAttr()}
      >
        {children}
      </div>
      {/* overriding the default preview with transparent preview */}
      <TransparentDefaultPreview dragPreview={dragPreview} />
    </>
  );
};

export default DraggableWrapper;
