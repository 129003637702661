import { planTourDataAttrNavigateDesk } from '../../../app/constants';
import './PlanDesktopNavigation.scss';

export const PlanDesktopNavigation = () => {
  const handleNext = () => {
    const planContainers = document.querySelectorAll('.plan-scroll-container');
    if (!planContainers?.length) return;
    const lastPlanContainer = planContainers[planContainers.length - 1];
    if (!lastPlanContainer) return;
    lastPlanContainer.scroll({
      left: lastPlanContainer.scrollLeft + lastPlanContainer.clientWidth,
      behavior: 'smooth',
    });
  };

  const handlePrevious = () => {
    const planContainers = document.querySelectorAll('.plan-scroll-container');
    if (!planContainers?.length) return;
    const lastPlanContainer = planContainers[planContainers.length - 1];
    if (!lastPlanContainer) return;
    lastPlanContainer.scroll({
      left: lastPlanContainer.scrollLeft - lastPlanContainer.clientWidth,
      behavior: 'smooth',
    });
  };

  return (
    <div className="my-plan-navigation" data-plan-tour={planTourDataAttrNavigateDesk}>
      <button
        id="my-plan-navigation-next"
        aria-label="Next"
        onClick={(e) => {
          e.stopPropagation();
          handleNext();
        }}
        className="no-style-button my-plan-navigation-button"
      />
      <button
        id="my-plan-navigation-prev"
        aria-label="Previous"
        onClick={(e) => {
          e.stopPropagation();
          handlePrevious();
        }}
        className="no-style-button my-plan-navigation-button"
      />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="29"
        height="15"
        viewBox="0 0 29 15"
        fill="none"
        className="my-plan-navigation-arrows-icon"
      >
        <path
          d="M22.9263 13L28 7.375L22.9263 1.75"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="miter"
        />
        <path
          d="M6.69019 2L1.61644 7.625L6.69019 13.25"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="square"
          strokeLinejoin="miter"
        />
      </svg>
    </div>
  );
};
