import type { FunctionComponent } from 'react';
import React, { useState } from 'react';
import { AppTimePicker } from '../../../../app-timers/AppTimePicker';
import TaskEditOverlayHeaderAndAction from '../../../../../../features/chat-wrapper/resizable-container/stage-container/stage-tasks/task-edit-overlays/TaskEditOverlayHeaderAndAction';
import removeAppOverlayPopover from '../../../../app-overlay-popover/removeAppOverlayPopover';

interface IProactiveReminderTimerOverlay {
  onChange: (hour: number) => void;
  currentHour: number;
  headerText: string;
}

const ProactiveReminderTimerOverlay: FunctionComponent<IProactiveReminderTimerOverlay> = ({
  onChange,
  currentHour,
  headerText,
}) => {
  const currentHoursToSend = new Date();
  currentHoursToSend.setHours(currentHour);
  currentHoursToSend.setMinutes(0);
  const [selectedDate, setSelectedDate] = useState<Date>(currentHoursToSend);

  const handleSet = () => {
    onChange(selectedDate.getHours());
    removeAppOverlayPopover({ shouldSlideOutMobileAnimation: true });
  };

  return (
    <div className="proactive-reminder-timer-overlay-container">
      <TaskEditOverlayHeaderAndAction onSet={handleSet} headerText={headerText} />
      <AppTimePicker
        date={selectedDate}
        className="proactive-reminder-timer"
        onChange={(newDate) => setSelectedDate(newDate)}
      />
    </div>
  );
};

export default ProactiveReminderTimerOverlay;
