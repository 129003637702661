import { createReducer } from '@reduxjs/toolkit';
import { API_ROUTES } from '../../../../app/constants';
import { apiServiceCSharp, createApiThunk } from '../../../api/axios';
import type { ApiDataStateType } from '../../../store/utils';
import { createAPIReducerCases } from '../../../store/utils';
import type { IAPIRequestState } from '../../../api/models';
import { APIRequestState } from '../../../api/models';
import type { IUser } from '../../../../app/auth/auth.interfaces';
import type {
  IResetUserReqPayload,
  IUserFeedbackReqPayload,
  IUserFeedbackResponse,
} from './SettingsMenu.interfaces';

export interface IChatState extends ApiDataStateType {
  resetUserProfile: IAPIRequestState<IUser>;
  userFeedback: IAPIRequestState<IUserFeedbackResponse>;
}

const initialStateChatStore: IChatState = {
  resetUserProfile: APIRequestState.create(),
  userFeedback: APIRequestState.create(),
};

const createReducerKey = (subKey: string): string => {
  return 'settings/' + subKey;
};

export const resetUserProfileRequest = createApiThunk(
  createReducerKey('resetUserProfileRequest'),
  (reqPayload?: IResetUserReqPayload) =>
    apiServiceCSharp.post<IUser>(API_ROUTES.SETTINGS.RESET_USER_PROFILE, reqPayload),
);

export const sendUserFeedbackRequest = createApiThunk(
  createReducerKey('sendUserFeedbackRequest'),
  (reqPayload?: IUserFeedbackReqPayload) =>
    apiServiceCSharp.post<IUserFeedbackResponse>(API_ROUTES.SETTINGS.USER_FEEDBACK, reqPayload),
);

export const settingsMenuReducer = createReducer(initialStateChatStore, (builder) => {
  createAPIReducerCases(resetUserProfileRequest, 'resetUserProfile', builder);
  createAPIReducerCases(sendUserFeedbackRequest, 'userFeedback', builder);
});
