import { useTranslation } from 'react-i18next';
import { contactUsLink } from '../../../constants';
import './ContactUs.scss';

export const ContactUs = () => {
  const { t } = useTranslation();

  const contactUsText = (): string => {
    const checkboxHtmlString = t('registrationContactUsSection').replace(
      '{contactUs}',
      `<a href=${contactUsLink} target="_blank" rel="noreferrer">${t('contactUsLinkText')}</a>`,
    );
    return checkboxHtmlString;
  };

  return (
    <div className="contact-us-container">
      <p className="contact-us-content" dangerouslySetInnerHTML={{ __html: contactUsText() }}></p>
    </div>
  );
};
