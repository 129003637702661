import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import { TimePickerContext } from '../../task-edit-overlays/time-picker-context/TimePickerContext';
import { useAppDispatch, useAppSelector } from '../../../../../../../app/store';
import AppButton from '../../../../../../../shared/components/app-button/AppButton';
import { useTranslation } from 'react-i18next';
import { getDateWithoutYear } from '../../../../../../../shared/utils/dateFormat';
import {
  setPlannerOutputEventType,
  setPlannerOutputPayload,
} from '../../../stage-planner/stagePlanner.store';
import { EPlannerClickEvent } from '../../../stage-planner/stagePlanner.interface';
import './SelectWorkTimeUsingCalendar.scss';
import { getTimeRange } from '../../../../../chat/chat-conversation/message-data-card/tasks-list/TasksList.utils';
import type { IPlannerCurrentTaskPlacement } from '../../stageTasks.interface';
import { isTaskAssignToThisWorkBlock } from '../../../../../../../shared/utils/utils';

interface ISelectWorkTimeUsingCalendarPlanner {
  onClose: () => void;
  taskName: string;
}

const SelectWorkTimeUsingCalendarPlanner: FunctionComponent<
  ISelectWorkTimeUsingCalendarPlanner
> = ({ onClose, taskName }) => {
  const { currentTaskPlacement } = useAppSelector((store) => store.StagePlannerReducer);
  const { allWorkBlocks } = useAppSelector((store) => store.StageTasksReducer);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getSelectedTime = () => {
    if (currentTaskPlacement?.workBlockId) {
      let workBlockName = currentTaskPlacement?.workBlockName;
      let workBlockStartTime: any = currentTaskPlacement?.workBlockStartTime;
      if (!workBlockName) {
        const workBlock =
          allWorkBlocks.find((w) =>
            isTaskAssignToThisWorkBlock(
              w.id!,
              w?.workTime || '',
              currentTaskPlacement?.workBlockId,
              currentTaskPlacement?.workBlockInstance,
            ),
          ) || null;
        workBlockName = workBlock?.name;
        workBlockStartTime = workBlock?.workTime;
      }
      return `${workBlockName || t('workBlockDefaultName')} ${getDateWithoutYear(
        workBlockStartTime,
      )}`;
    } else if (currentTaskPlacement?.workTime) {
      return `${getTimeRange(
        new Date(currentTaskPlacement.workTime),
        currentTaskPlacement?.duration,
        true,
      )}, ${getDateWithoutYear(currentTaskPlacement?.workTime)}`;
    }
    return '';
  };

  const onClickSet = () => {
    dispatch(setPlannerOutputEventType(EPlannerClickEvent.TIME_PICKER));
    dispatch(setPlannerOutputPayload({ ...currentTaskPlacement } as IPlannerCurrentTaskPlacement));
  };

  const renderSetBtn = (): ReactNode => {
    return (
      <AppButton
        id="set-work-time-via-calendar"
        onClick={onClickSet}
        disabled={!currentTaskPlacement?.workTime && !currentTaskPlacement?.workBlockId}
        className="set-task-time-picker-btn"
      >
        {t('setButtonText')}
      </AppButton>
    );
  };

  return (
    <TimePickerContext
      onClose={onClose}
      taskName={taskName}
      onSet={renderSetBtn()}
      selectedTimeStr={getSelectedTime()}
    />
  );
};

export default SelectWorkTimeUsingCalendarPlanner;
