import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store';
import { Link, useNavigate } from 'react-router-dom';
import {
  APP_ROUTING_PATHS,
  loginTypeStorageKey,
  userPhoneNumberLocalStorageKey,
} from '../../constants';
import { useTranslation } from 'react-i18next';
import phoneMascotImg from '../../../assets/images/mascot/phone-mascot.png';
import './LinkAccounts.scss';
import { linkAccountsReqAction } from '../../useAppData/user.store';
import AppButton from '../../../shared/components/app-button/AppButton';
import { EAPIStatus } from '../../../shared/api/models';
import { ApplicationInsightsApi } from '../../../application-insights';
import { AuthHeroCard } from '../../../shared/components/auth-hero-card/AuthHeroCard';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { getItemFromLocalStorage } from '../../../shared/utils/localStorage.utils';
import { ELoginType } from '../auth.interfaces';
import { handleLocalLogOut } from '../../../shared/utils/logOut';

const LinkAccounts = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState('');
  const { linkAccounts } = useAppSelector((store) => store.userReducer);
  const [getUserPhoneNumber, _] = useLocalStorage<string | null>(
    userPhoneNumberLocalStorageKey,
    null,
  );
  const getLinkAccountsParagraphText = () => {
    const loginType = getItemFromLocalStorage<ELoginType>(loginTypeStorageKey);
    if (loginType) {
      const loginTypeFirstLetterUppercase = loginType.charAt(0).toUpperCase() + loginType.slice(1);
      return t('linkAccountsDescription').replace('{login_type}', loginTypeFirstLetterUppercase);
    }
    return t('linkAccountsDescription').replace(' with {login_type}', '');
  };

  const onSubmit = () => {
    setErrorText('');
    dispatch(linkAccountsReqAction({ newUserPhoneNumber: getUserPhoneNumber || '' }))
      .then(() => {
        ApplicationInsightsApi.trackEvent('*SignUpLinkedAccounts');
        handleLocalLogOut(false);
        navigate(APP_ROUTING_PATHS.COMPLETE_LINK);
      })
      .catch((error: any) => {
        // Handle error response and set the error message
        const errorMessage = 'An unexpected error occurred. Please try again.';
        ApplicationInsightsApi.trackException(error);
        setErrorText(errorMessage);
      });
  };

  return (
    <div
      className="link-accounts-page link-accounts-page--view-type shorten-onboarding-flow auth-screen"
      id="link-accounts-page"
    >
      <div className="link-accounts-content-container scroll-y-container-hidden-scroll-bar">
        <AuthHeroCard mascot={phoneMascotImg} title={t('linkAccountsTitle')} />
        <p className="link-accounts-description white-space-pre-wrap">
          {getLinkAccountsParagraphText()}
        </p>
        {errorText && <p className="error-text">{errorText}</p>}
        <AppButton
          className="auth-next-btn"
          id="link-accounts-submit"
          onClick={onSubmit}
          disabled={linkAccounts.status === EAPIStatus.PENDING}
        >
          {t('linkAccountsButtonText')}
        </AppButton>
      </div>
      <Link to={APP_ROUTING_PATHS.SIGN_IN} className="back-link">
        {t('linkAccountsNoButtonText')}
      </Link>
    </div>
  );
};

export default LinkAccounts;
