import React from 'react';
import { useAppDispatch } from '../../../../../../app/store';
import { setShouldOpenClearConfirmModal } from '../stageScratchpad.store';
import { useTranslation } from 'react-i18next';
import './ClearScratchpadButton.scss';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

const ClearScratchpadButton = ({ className }: { className?: string }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  return (
    <AppButton
      id="clear-scratchpad"
      type="button"
      className={`clear-scratchpad static-string ${className}`}
      onClick={() => dispatch(setShouldOpenClearConfirmModal(true))}
    >
      {t('clearButtonText')}
    </AppButton>
  );
};

export default ClearScratchpadButton;
