import React from 'react';
import AppInput from '../app-input/AppInput';
import { useTranslation } from 'react-i18next';

interface OtpInputProps {
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

const OtpInput: React.FC<OtpInputProps> = ({ value, onChange, error }) => {
  const { t } = useTranslation();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
    if (inputValue.length > 6) inputValue = inputValue.slice(0, 6);
    onChange(inputValue);
  };

  const formatOtp = (otp: string) => {
    // This regex captures the first 3 digits and the next 0 to 3 digits.
    // It then formats them with a space in between, e.g., "123456" becomes "123 456".
    return otp.replace(/(\d{3})(\d{0,3})/, '$1 $2').trim();
  };

  return (
    <AppInput
      id="otp-input"
      type="text"
      data-testid="otp-input"
      className="auth-input"
      value={formatOtp(value)}
      onChange={handleChange}
      maxLength={7} // 6 digits + 1 space
      error={error}
      placeholder={t('otpInputPlaceholder')}
    />
  );
};

export default OtpInput;
