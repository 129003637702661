// returns the traditional IANA time zone identifier, as listed here: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
// returns null if Intl API is not supported by the user's browser
export const getIanaTimeZone = (): string | null => {
  if (window.Intl) {
    return Intl.DateTimeFormat().resolvedOptions().timeZone ?? null;
  } else {
    return null;
  }
};

// returns UTC offset in minutes, can be a positive or negative integer
export const getTimeZoneOffsetInMinutes = (): number => {
  return new Date().getTimezoneOffset();
};
