import { Outlet } from 'react-router-dom';
import { setAppInsightsUserContext } from '../../application-insights';

export const Main = () => {
  setAppInsightsUserContext();

  return (
    <div className="app-main">
      <Outlet />
    </div>
  );
};
