import { EPlanDayCardDisplayType, PlanDayCard } from '../plan-day-card/PlanDayCard';

interface MyPlanProps {
  myPlanDays?: number;
}

export const MyPlan = ({ myPlanDays }: MyPlanProps) => {
  return (
    <>
      {Array.from({ length: myPlanDays || 0 }).map((_, index) => (
        <PlanDayCard key={index} index={index} displayType={EPlanDayCardDisplayType.MY_PLAN} />
      ))}
    </>
  );
};
