import type { FunctionComponent, ReactNode } from 'react';
import './CardWrapper.scss';

interface ICardWrapperProps {
  children?: ReactNode[] | ReactNode;
  className?: string;
}

const CardWrapper: FunctionComponent<ICardWrapperProps> = ({ children, className }) => {
  return <div className={`card-wrapper ${className ? className : ''}`}>{children}</div>;
};

export default CardWrapper;
