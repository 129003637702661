import type { ICalendarEvent } from '../CalendarDay';
import { useAppDispatch } from '../../../../../app/store';
import WorkBlockTaskItem from './WorkBlockTaskItem';
import WorkBlockSassVariables from './WorkBlockTasksList.module.scss';
import type { MouseEvent } from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { IMessageDataTask } from '../../../../chat-wrapper/resizable-container/stage-container/stage-tasks/stageTasks.interface';
import './WorkBlockTasksList.scss';
import { ReactComponent as RecurrenceIconComponent } from '../../../../../assets/images/single-task/task_recurrence_icon.svg';
import { EPlanDayCardDisplayType } from '../../../plan-day-card/PlanDayCard';
import { setPlanView } from '../../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { EPlanViewSelection } from '../../../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.interface';

interface IWorkBlockTasksList {
  workBlock: ICalendarEvent;
  onClickWorkBlockTask: (
    clickEvent: MouseEvent<HTMLElement, any>,
    event: ICalendarEvent,
    workBlockTask?: IMessageDataTask,
  ) => void;
  planViewType: EPlanDayCardDisplayType;
  relatedTasks: IMessageDataTask[];
  eventsFlatArray: ICalendarEvent[];
  dayIndex: number;
  divideEventByGroups: (events: ICalendarEvent[]) => void;
}

const WorkBlockTasksList = ({
  workBlock,
  onClickWorkBlockTask,
  planViewType,
  relatedTasks,
  eventsFlatArray,
  dayIndex,
  divideEventByGroups,
}: IWorkBlockTasksList) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const calcMaxTasksAmountToDisplay = () => {
    const workBlockTaskHeight = Number(
      WorkBlockSassVariables.workBlockTaskItemHeight.replace('px', ''),
    );
    const workBlockTasksListGap =
      parseInt(WorkBlockSassVariables.workBlockTaskListGap) +
      parseInt(WorkBlockSassVariables.workBlockTaskListMarginTop);
    const taskItemHeightWithOffset = workBlockTaskHeight + workBlockTasksListGap;
    const workBlockRecurrenceHeight = workBlock.isRecurring ? taskItemHeightWithOffset : 0;
    const workBlockContainerHeight =
      workBlock.height -
      workBlockRecurrenceHeight -
      Number(WorkBlockSassVariables.workBlockContainerPadding.replace('px', ''));
    return Math.floor(workBlockContainerHeight / taskItemHeightWithOffset);
  };

  const maxTasksAmountToDisplay = calcMaxTasksAmountToDisplay();

  const taskAmount = relatedTasks.length;

  const getMoreBtnText = () => {
    const tasksNumber = taskAmount - maxTasksAmountToDisplay;
    return t('moreWorkBlockTasksButtonText').toString().replace('{tasks_amount}', `${tasksNumber}`);
  };

  const onClickMoreTasksBtn = (e: MouseEvent<HTMLElement, any>) => {
    // Todo navigate to the correct day
    dispatch(setPlanView(EPlanViewSelection.MY_PLAN));
    e.preventDefault();
    e.stopPropagation();
  };

  const getClassByPlanViewType = () => {
    switch (planViewType) {
      case EPlanDayCardDisplayType.MY_DAY:
        return 'my-day';
      case EPlanDayCardDisplayType.MY_WEEK:
        return 'my-week';
      default:
        return '';
    }
  };

  return (
    <div
      className={`work-block-calendar-details work-block-calendar-details--${getClassByPlanViewType()}`}
    >
      <div className={`work-block-tasks-list work-block-tasks-list--${getClassByPlanViewType()}`}>
        {relatedTasks.slice(0, maxTasksAmountToDisplay).map((task, i) => (
          <Fragment key={task.id! + i}>
            <WorkBlockTaskItem
              task={task}
              onClickTask={(e) => onClickWorkBlockTask(e, workBlock, task)}
              eventsFlatArray={eventsFlatArray}
              dayIndex={dayIndex}
              divideEventByGroups={divideEventByGroups}
            />
          </Fragment>
        ))}
      </div>
      <div className="recurrence-and-more-btn-container">
        {maxTasksAmountToDisplay < taskAmount && (
          <button
            id="work-block-calendar-show-more-tasks"
            onClick={onClickMoreTasksBtn}
            className="work-block-more-tasks-btn"
          >
            {getMoreBtnText()}
          </button>
        )}
        {workBlock?.isRecurring && <RecurrenceIconComponent className="recurrence" />}
      </div>
    </div>
  );
};

export default WorkBlockTasksList;
