import type { ButtonHTMLAttributes, FunctionComponent, MouseEvent, ReactNode } from 'react';
import './AppButton.scss';

interface IAppButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  onClick?: (e: MouseEvent<HTMLButtonElement, any>) => void;
  children?: ReactNode | ReactNode[];
  shouldChangeBackgroundOnActive?: boolean;
  id: string; // mandatory
}

const AppButton: FunctionComponent<IAppButtonProps> = ({
  shouldChangeBackgroundOnActive = true,
  ...attributes
}) => {
  const getButtonAttr = () => {
    const tempAttributes = { ...attributes };
    delete tempAttributes.type;
    delete tempAttributes.className;
    delete tempAttributes.onClick;
    delete tempAttributes.children;
    return { ...tempAttributes };
  };

  return (
    <button
      {...getButtonAttr()}
      type={attributes.type || 'button'}
      className={`app-button ${shouldChangeBackgroundOnActive ? 'change-on-active' : ''} ${
        attributes.className
      }`}
      onClick={attributes?.onClick}
    >
      {attributes?.children}
    </button>
  );
};

export default AppButton;
