import Lottie from 'lottie-react';
import pageLoaderAnimation from '../../animation-json/PageLoaderAnimation.json';
import type { FunctionComponent } from 'react';
import { uuid } from '../../utils/uuid';

interface ILottieAppLoaderProps {
  testId?: string;
  className?: string;
}

export const LottieAppLoader: FunctionComponent<ILottieAppLoaderProps> = ({
  testId,
  className = 'page-loader-animation',
}) => {
  return (
    <Lottie
      animationData={pageLoaderAnimation}
      loop={true}
      className={className}
      data-testid={testId || uuid()}
    />
  );
};
