import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import DiscordLink from '../../../../../../shared/components/discord-link/DiscordLink';
import { useFormContext } from 'react-hook-form';
import type { IChatMessage } from '../../../../chat.interfaces';
import { EFeedbackType } from '../../../../chat.interfaces';
import DislikeTag from './DislikeTag';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { resetFeedbackResponse } from '../../../../chat.store';
import { FORBIDDEN, UNAUTHORIZED } from '../../../../../../shared/api/axios';
import { EAPIStatus } from '../../../../../../shared/api/models';
import { useSearchParams } from 'react-router-dom';
import { dislikeFeedbackParam } from '../../../../../../app/constants';
import { useApiData } from '../../../../../../shared/hooks/useApiData';
import './DislikeFeedback.scss';
import AppButton from '../../../../../../shared/components/app-button/AppButton';

interface IDislikeFeedbackProps {
  msgItem: IChatMessage;
  onDislikeFeedbackSubmit: Dispatch<SetStateAction<EFeedbackType | null>>;
}

export const DislikeFeedback: FunctionComponent<IDislikeFeedbackProps> = ({
  msgItem,
  onDislikeFeedbackSubmit,
}) => {
  const dislikeForm = useFormContext();
  const { feedbackResponse } = useAppSelector((store) => store.chatReducer);
  const [, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const onCancel = () => {
    dispatch(resetFeedbackResponse());
    removeSearchParam();
  };

  const removeSearchParam = () => {
    setSearchParams((prev) => {
      prev.delete(dislikeFeedbackParam);
      return prev;
    });
  };

  useApiData(feedbackResponse, {
    onFulfilled: () => {
      removeSearchParam();
    },
  });

  return (
    <div className={`dislike-feedback-container`} data-testid="dislike-form-screen">
      <div className="dislike-frame">
        <AppButton
          id="cancel-dislike-feedback-form-button"
          className="cancel"
          onClick={onCancel}
          data-testid="dislike-cancel-button"
        >
          {t('dislikeFeedbackScreenCancelButtonText')}
        </AppButton>
        <h2 data-testid="dislike-screen-title">{t('dislikeFeedbackScreenTitleText')}</h2>
        <p data-testid="dislike-message">“{msgItem.msg}”</p>
        <form
          id="dislike-form"
          onSubmit={dislikeForm.handleSubmit(() => onDislikeFeedbackSubmit(EFeedbackType.DISLIKE))}
          data-testid="dislike-form"
        >
          <section className="optional-buttons">
            <DislikeTag option={t('dislikeFeedbackScreenTag1Text')} />
            <DislikeTag option={t('dislikeFeedbackScreenTag2Text')} />
            <DislikeTag option={t('dislikeFeedbackScreenTag3Text')} />
          </section>
          <section className="textarea-error-container">
            <textarea
              {...dislikeForm.register('feedbackText', { required: false })}
              placeholder={t('dislikeFeedbackScreenTextareaPlaceholderText')}
              data-testid="dislike-feedback-textarea"
              id="dislike-feedback-textarea"
            />
            {feedbackResponse.status === EAPIStatus.REJECTED &&
              feedbackResponse.error?.code !== FORBIDDEN &&
              feedbackResponse.error?.code !== UNAUTHORIZED && (
                <small className="app-error" data-testid="dislike-error">
                  {t('somethingWentWrongCanYouTryAgainError')}
                </small>
              )}
          </section>
          <section className="d-flex">
            <AppButton
              id="submit-dislike-feedback-form-button"
              form="dislike-form"
              type="submit"
              data-testid="dislike-form-submit-btn"
            >
              {t('submit')}
            </AppButton>
            <DiscordLink />
          </section>
        </form>
      </div>
    </div>
  );
};
