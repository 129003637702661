import type { HTMLAttributes } from 'react';
import { useState, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../app/store';
import { useTranslation } from 'react-i18next';
import AppButton from '../../../../../../shared/components/app-button/AppButton';
import { EPlanViewSelection } from '../stagePlanner.interface';
import { setPlanView } from '../stagePlanner.store';
import { ReactComponent as HamburgerIcon } from '../../../../../../assets/images/hamburger.svg';
import './PlanViewSelectionDropdown.scss';

interface IProps extends HTMLAttributes<HTMLDivElement> {}

export const PlanViewSelectionDropdown = ({ ...attributes }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { planView } = useAppSelector((store) => store.StagePlannerReducer);
  const filterOptionsArr: EPlanViewSelection[] = [
    EPlanViewSelection.MY_PLAN,
    EPlanViewSelection.MY_DAY,
    EPlanViewSelection.MY_WEEK,
  ];
  const dropdownRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const handleClickOutside = () => {
      // if click at the document and the dropDown is open, close it
      if (dropdownRef.current && dropdownRef.current.clientHeight > 0) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const handleFilter = (filter: EPlanViewSelection) => {
    dispatch(setPlanView(filter));
  };

  return (
    <div className={`plan-view-selection ${isOpen ? 'open' : ''}`} {...attributes}>
      <AppButton
        id="open-stage-filter-dropdown-menu"
        type="button"
        className="dropdown-toggle"
        onClick={() => setIsOpen(!isOpen)}
        shouldChangeBackgroundOnActive={false}
      >
        <HamburgerIcon className="hamburger-icon" />
        <span>{planView}</span>
      </AppButton>
      <div className={`dropdown-menu`} ref={dropdownRef}>
        <div className="dropdown-view">
          <HamburgerIcon className="hamburger-icon" />
          {t('stageTasksDropDownTitle')}
        </div>
        {filterOptionsArr.map((filterItem) => (
          <AppButton
            id={`${filterItem?.trim()}-filter-stage-dropdown-menu`}
            key={filterItem}
            className={`dropdown-item ${planView === filterItem ? 'active' : ''}`}
            onClick={() => {
              handleFilter(filterItem);
            }}
            shouldChangeBackgroundOnActive={false}
          >
            {filterItem}
          </AppButton>
        ))}
      </div>
    </div>
  );
};
