import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import './TextAndToggle.scss';

interface IProps {
  header: string;
  toggleActive: boolean;
  onToggle: (active: boolean) => void;
  subHeader?: string;
  icon?: React.ReactNode;
  className?: string;
  toggleClassName?: string;
  children?: React.ReactNode | React.ReactNode[];
}

export const TextAndToggle = ({
  header,
  toggleActive,
  onToggle,
  subHeader,
  icon,
  className,
  toggleClassName,
  children,
}: IProps) => {
  const [active, setActive] = useState(toggleActive);

  useEffect(() => {
    setActive(toggleActive);
  }, [toggleActive]);

  const handleToggle = (active: boolean) => {
    setActive(active);
    onToggle(active);
  };

  return (
    <div className={`text-and-toggle-container ${className}`}>
      <div className="text-and-toggle-text-container">
        <div className="text-and-toggle-icon-and-header-container">
          {icon && <span className="text-and-toggle-icon">{icon}</span>}
          <h3 className="text-and-toggle-header-text">{header}</h3>
        </div>
        {subHeader && <p className="text-and-toggle-sub-header">{subHeader}</p>}
      </div>
      <div className={`text-and-toggle-switch-container`}>
        {children ? children : <></>}
        <Switch className={`${toggleClassName}`} checked={active} onChange={handleToggle} />
      </div>
    </div>
  );
};
