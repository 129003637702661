import { apiServiceCSharp, createApiThunk } from '../../shared/api/axios';
import type { IAPIRequestState } from '../../shared/api/models';
import { APIRequestState } from '../../shared/api/models';
import type { ApiDataStateType } from '../../shared/store/utils';
import { createAPIReducerCases } from '../../shared/store/utils';
import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  ELoginType,
  type ILoginReqPayload,
  type ILoginResponse,
  type IMagicLinkReqPayload,
} from './auth.interfaces';
import { API_ROUTES, tokenLocalStorageKey } from '../constants';
import { setNoRedirectFlagInLocalStorage } from './auth.utils';
import type { RootState } from '../store';
import { getItemFromLocalStorage } from '../../shared/utils/localStorage.utils';

export interface IAuthState extends ApiDataStateType {
  loginRes: IAPIRequestState<ILoginResponse>;
  sendOtpRes: IAPIRequestState<boolean>;
  magicLinkRes: IAPIRequestState<boolean>;
}

const initialStateAuthStore: IAuthState = {
  loginRes: APIRequestState.create(),
  sendOtpRes: APIRequestState.create(),
  magicLinkRes: APIRequestState.create(),
};

export const selectIsAuthenticated = (state: RootState): boolean => {
  const tokenFromState = !!state.authReducer.loginRes.data?.token;
  const tokenFromStorage = !!getItemFromLocalStorage(tokenLocalStorageKey);
  return tokenFromState || tokenFromStorage;
};

const createReducerKey = (subKey: string): string => {
  return 'auth/' + subKey;
};

const PHONE_EMAIL_LOGIN_API_VERSION = '/v2';
export const loginReqAction = createApiThunk(
  createReducerKey('loginReqAction'),
  (req?: { payload: ILoginReqPayload; loginType: string; signal?: AbortSignal }) => {
    const route =
      req?.loginType === ELoginType.PHONE || req?.loginType === ELoginType.EMAIL
        ? API_ROUTES.AUTH.LOGIN + req?.loginType + PHONE_EMAIL_LOGIN_API_VERSION
        : API_ROUTES.AUTH.LOGIN + req?.loginType;

    return apiServiceCSharp.post<ILoginResponse>(route, req?.payload, { signal: req?.signal });
  },
);

export const sendOtpReqAction = createApiThunk(
  createReducerKey('sendOtpReqAction'),
  (payload?: { phoneNumber: string }) =>
    apiServiceCSharp.post<boolean>(API_ROUTES.AUTH.SEND_OTP, payload),
);

export const requestMagicLink = createApiThunk(
  createReducerKey('requestMagicLink'),
  (req?: { payload: IMagicLinkReqPayload; api: string }) =>
    apiServiceCSharp.post<boolean>(req?.api as string, req?.payload),
);

export const resetLoginResState = createAction(createReducerKey('resetLoginResState'));

export const authReducer = createReducer(initialStateAuthStore, (builder) => {
  createAPIReducerCases(loginReqAction, 'loginRes', builder, {
    onFulfilled() {
      setNoRedirectFlagInLocalStorage();
    },
  });
  createAPIReducerCases(sendOtpReqAction, 'sendOtpRes', builder);
  createAPIReducerCases(requestMagicLink, 'magicLinkRes', builder);
  builder.addCase(resetLoginResState, (state) => {
    state.loginRes = APIRequestState.create();
  });
});
