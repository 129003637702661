import type {
  CSSProperties,
  HTMLAttributes,
  ReactNode,
  MutableRefObject,
  AnimationEvent,
} from 'react';
import React, { useState } from 'react';
import { useSwipeToClose } from '../../hooks/swipe-hooks/useSwipeToClose';
import { ECloseSwipeDirection } from '../../hooks/swipe-hooks/swipe.utils';

interface ISwipeToCloseProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  swipeDirection: ECloseSwipeDirection;
  onCloseWhenSwipeOutEnd?: () => void;
  canCloseOnSwipe?: boolean;
  wrapperRef?: MutableRefObject<HTMLDivElement | null>;
  onAnimationEnd?: (animationData: AnimationEvent<HTMLDivElement>) => void;
  className?: string;
  id: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  style?: CSSProperties;
  threshold?: number;
  onStartCloseSwipeAnimation?: (direction: 'down' | 'left' | 'right') => void;
}

type TAnimationClassName =
  | 'fadeOutSlideUpClose'
  | 'fadeOutSlideDownClose'
  | 'fadeOutSlideLeftClose'
  | 'fadeOutSlideRightClose'
  | '';

const SwipeToCloseWrapper = ({
  id,
  className = '',
  children,
  swipeDirection,
  onCloseWhenSwipeOutEnd,
  canCloseOnSwipe = true,
  wrapperRef,
  onAnimationEnd,
  onClick,
  style = {},
  threshold,
  onStartCloseSwipeAnimation,
  ...attributes
}: ISwipeToCloseProps) => {
  const [animateOutClass, setAnimateOutClass] = useState<TAnimationClassName>('');
  const { handleTouchStart, handleTouchMove, handleTouchEnd, translate, setTranslate } =
    useSwipeToClose({
      swipeDirection: swipeDirection,
      onSwipeEnd: onSwipeEnd,
      threshold: threshold,
    });

  function onSwipeEnd(direction: 'down' | 'left' | 'right') {
    if (onStartCloseSwipeAnimation) {
      onStartCloseSwipeAnimation(direction);
      setTranslate(null);
      return;
    }
    switch (direction) {
      case 'down':
        setAnimateOutClass('fadeOutSlideDownClose');
        break;
      case 'left':
        setAnimateOutClass('fadeOutSlideLeftClose');
        break;
      case 'right':
        setAnimateOutClass('fadeOutSlideRightClose');
        break;
    }
  }

  const handleAnimationEnd = (animationData: React.AnimationEvent<HTMLDivElement>) => {
    if (animateOutClass) {
      setTranslate(null);
      setAnimateOutClass('');
      if (onCloseWhenSwipeOutEnd) onCloseWhenSwipeOutEnd();
    }
    if (onAnimationEnd) onAnimationEnd(animationData);
  };

  const getTransformStyle = (): CSSProperties => {
    if (translate) {
      if (swipeDirection === 'down') return { transform: `translateY(${translate.y}px)` };
      else if (swipeDirection === 'rightLeft') return { transform: `translateX(${translate.x}px)` };
    }
    return {};
  };

  const shouldSwipe = (e: React.TouchEvent) => {
    return (
      canCloseOnSwipe &&
      (swipeDirection !== ECloseSwipeDirection.DOWN || e.currentTarget.scrollTop <= 0)
    );
  };

  return (
    <div
      ref={(node) => {
        if (wrapperRef) wrapperRef.current = node;
      }}
      id={id}
      onTouchStart={(e) => shouldSwipe(e) && handleTouchStart(e)}
      onTouchMove={(e) => shouldSwipe(e) && handleTouchMove(e)}
      onTouchEnd={(e) => shouldSwipe(e) && handleTouchEnd(e)}
      onAnimationEnd={(animationData) => handleAnimationEnd(animationData)}
      className={`swipe-to-close-wrapper ${className} ${animateOutClass}`}
      style={{
        ...style,
        ...getTransformStyle(),
      }}
      onClick={(e) => {
        if (onClick) onClick(e);
      }}
      {...attributes}
    >
      {children}
    </div>
  );
};

export default SwipeToCloseWrapper;
