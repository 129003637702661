import type { FunctionComponent } from 'react';
import React from 'react';
import calendarIcon from '../../../../../../assets/images/calendar-icon.svg';
import bellIcon from '../../../../../../assets/images/reminder-task-icon.svg';
import type { ISingleMessageDataRes } from '../../../../chat.interfaces';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import './SingleTask.scss';
import {
  EDayjsFormatter,
  getDateAndTimeWithoutYear,
  getTimeByDate,
} from '../../../../../../shared/utils/dateFormat';
import { taskDueDateDefaultTime } from '../../../../../../app/constants';

interface ISingleTaskProps {
  msgData: ISingleMessageDataRes;
}

const SingleTask: FunctionComponent<ISingleTaskProps> = ({ msgData }) => {
  const { t } = useTranslation();

  return (
    <div className="single-task" data-testid="single-task-card">
      <section>
        <h3 className="single-task-title" data-testid="task-name">
          {msgData.content?.name}
        </h3>
        {/* task description will goes here */}
      </section>
      <section className="flex">
        {msgData.content?.workTime && (
          <div className="working">
            <img src={calendarIcon} alt="calendar" />
            {t('taskWorking')}
            <section className="working-time">
              {getDateAndTimeWithoutYear(msgData.content.workTime)}
            </section>
          </div>
        )}
        <div>
          {msgData.content?.dueDate && (
            <div className="due-date">
              {/* TODO: ask Joe for alt text to this img tag */}
              <img src={calendarIcon} alt="calendar" />
              {t('taskDueDate')}
              <div className="flex">
                <section className="date" data-testid="due-date">
                  {dayjs(new Date(msgData.content.dueDate))
                    .format(
                      `${EDayjsFormatter.PartOfMonthWord} ${EDayjsFormatter.DayOfMonthNumberWithoutZero}`,
                    )
                    .toUpperCase()}
                </section>
                {getTimeByDate(msgData.content.dueDate) !== taskDueDateDefaultTime && (
                  <section className="time" data-testid="due-time">
                    {getTimeByDate(msgData.content.dueDate)}
                  </section>
                )}
              </div>
            </div>
          )}
          {msgData.content?.reminderTime && (
            <div className="reminder">
              {/* TODO: ask Joe for alt text to this img tag */}
              <img src={bellIcon} alt="bell" />
              {t('taskReminder')}
              <section className="date-time" data-testid="reminder-date-time">
                {getDateAndTimeWithoutYear(msgData.content.reminderTime)}
              </section>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default SingleTask;
