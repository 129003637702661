import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../../store';
import { useCallback, useState } from 'react';
import {
  API_ROUTES,
  APP_ROUTING_PATHS,
  EMagiLinkLoginPageViewType,
  isSignUpLocalStorageKey,
  loginTypeStorageKey,
  registrationParametersStorageKey,
} from '../../../constants';
import { useLocalStorage } from '../../../../shared/utils/useLocalStorage';
import { useForm } from 'react-hook-form';
import { isEmbeddedBrowser } from '../../auth.utils';
import { EAPIStatus } from '../../../../shared/api/models';
import { loginReqAction, requestMagicLink } from '../../auth.store';
import { baseUrl } from '../Login';
import emailMascot from '../../../../assets/images/mascot/onboarding/email-sign-in-mascot.png';
import signInMascot from '../../../../assets/images/mascot/onboarding/sign-in-mascot.png';
import './LoginWithEmailPage.scss';
import AppButton from '../../../../shared/components/app-button/AppButton';
import { ApplicationInsightsApi } from '../../../../application-insights';
import { useNavigate } from 'react-router-dom';
import { getItemFromLocalStorage } from '../../../../shared/utils/localStorage.utils';
import { AuthHeroCard } from '../../../../shared/components/auth-hero-card/AuthHeroCard';
import AppInput from '../../../../shared/components/app-input/AppInput';
import PhoneNumberVerificationAuthPage from '../../phone-verification-auth-flow/phone-or-email-verification-auth-page/PhoneOrEmailVerificationAuthPage';

export const LoginWithEmailPage = () => {
  const { magicLinkRes, loginRes } = useAppSelector((store) => store.authReducer);
  const { t } = useTranslation();
  const [viewType, setViewType] = useState<EMagiLinkLoginPageViewType>(
    EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK,
  );
  const [errorText, setErrorText] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, setLoginType] = useLocalStorage(loginTypeStorageKey, '');
  const {
    control,
    register,
    watch,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      email: '',
      code: '',
    },
  });
  const isMobileOrEmbeddedBrowser = isEmbeddedBrowser();
  const isSignUpFlow = getItemFromLocalStorage<boolean>(isSignUpLocalStorageKey);

  const emailWatch = watch('email');
  const codeWatch = watch('code');

  const onSubmit = () => {
    if (viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK) {
      onSubmitEmail();
    } else {
      onSubmitVerificationCode();
    }
  };

  const onSubmitEmail = () => {
    if (magicLinkRes.status === EAPIStatus.PENDING) return;
    setErrorText('');
    dispatch(
      requestMagicLink({
        payload: {
          email: emailWatch || '',
          redirectUri: `${baseUrl}${APP_ROUTING_PATHS.SSO_MAGIC_LINK}`,
          registrationParameters:
            localStorage.getItem(registrationParametersStorageKey) || undefined,
          isShortCodeRequested: !isMobileOrEmbeddedBrowser,
        },
        api: API_ROUTES.AUTH.REQUEST_MAGIC_LINK_EMAIL,
      }),
    )
      .unwrap()
      .then((res) => {
        if (res) {
          setLoginType('email');
          setViewType(
            isMobileOrEmbeddedBrowser
              ? EMagiLinkLoginPageViewType.MAGIC_LINK_SENT
              : EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP,
          );
        }
      })
      .catch(() => {
        setErrorText(t('somethingWentWrongTryAgainError').toString());
      });
  };

  const onSubmitVerificationCode = () => {
    dispatch(
      loginReqAction({
        payload: { accessToken: codeWatch, redirectUri: '', error: '', email: emailWatch },
        loginType: 'email',
      }),
    )
      .unwrap()
      .catch((e) => {
        ApplicationInsightsApi.trackException(e);
        setErrorText(t('phoneVerificationPhoneNumberError').toString());
      });
  };

  const getTitle = useCallback(() => {
    switch (viewType) {
      case EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK:
        return isSignUpFlow ? t('continueWithEmailTitle3') : t('loginReturningUser');
      case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP:
      case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT:
        return t('continueWithEmailTitle2');
      default:
        return '';
    }
  }, [viewType, t]);

  const getMascot = useCallback(() => {
    if (viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK && !isSignUpFlow) {
      return signInMascot;
    }
    return emailMascot;
  }, [viewType]);

  const getInstructions = useCallback(() => {
    switch (viewType) {
      case EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK:
        return isMobileOrEmbeddedBrowser
          ? t('continueWithEmailInstructions1')
          : t('continueWithEmailInstructions2');
      case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT:
        return t('continueWithEmailInstructions3');
      case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP:
        return t('continueWithEmailInstructions4');
      default:
        return '';
    }
  }, [viewType, isMobileOrEmbeddedBrowser, t]);

  const handleNavigateBack = () => {
    switch (viewType) {
      case EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK:
        navigate(-1);
        break;
      case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT:
      case EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP:
        setViewType(EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK);
        break;
      default:
        navigate(-1);
        break;
    }
  };

  return (
    <>
      {viewType === EMagiLinkLoginPageViewType.MAGIC_LINK_SENT_DESKTOP ? (
        <PhoneNumberVerificationAuthPage
          isLoginFlow={true}
          title={t('continueWithEmailTitle2')}
          emailToVerify={emailWatch}
          onClickBack={handleNavigateBack}
          subTitle={t('continueWithEmailInstructions3')}
        />
      ) : (
        <div
          className={`login-with-email-page login-with-email-page--view-type-${viewType} shorten-onboarding-flow auth-screen`}
          id="login-with-email-page"
        >
          <div className="login-with-email-content">
            <AuthHeroCard mascot={getMascot()} title={getTitle()} />
            <form
              onSubmit={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              className={`login-with-email-text-and-email-content-container auth-form verification-code-form`}
            >
              <p className="login-with-email-instructions white-space-pre-wrap">
                {getInstructions()}
              </p>
              {viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK && (
                <AppInput
                  className="auth-input"
                  {...register('email', {
                    required: 'required',
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: '',
                    },
                  })}
                  style={{ width: '100%' }}
                  error={errorText}
                  type="email"
                  name="email"
                  id="login-with-email-input"
                  placeholder={t('loginWithEmailInputPlaceholder')}
                  required
                />
              )}
              {viewType === EMagiLinkLoginPageViewType.REQUEST_MAGIC_LINK && (
                <AppButton
                  onClick={onSubmit}
                  className="auth-submit-btn auth-next-btn"
                  form="update-email-form"
                  id="login-with-email-submit"
                  data-testid="login-with-email-submit"
                  type="button"
                  style={{ width: '100%' }}
                  disabled={!isValid || magicLinkRes.status === EAPIStatus.PENDING}
                >
                  {t('sendButtonText')}
                </AppButton>
              )}
            </form>
          </div>
          <AppButton
            id="login-with-email-back-button"
            onClick={() => {
              setErrorText('');
              handleNavigateBack();
            }}
            className="back-link"
          >
            {t('registerScreenBackButtonText')}
          </AppButton>
        </div>
      )}
    </>
  );
};
