import { Fragment, useEffect, useRef, useState } from 'react';
import './PlanDayCard.scss';
import { useInViewport } from '../../../shared/hooks/useInViewPort';
import { useTranslation } from 'react-i18next';
import { store, useAppDispatch, useAppSelector } from '../../../app/store';
import { EAPIStatus } from '../../../shared/api/models';
import { isMobileDevice } from '../../../shared/utils/isMobileDevice';
import { PlanDayCardDueSection } from './PlanDayCardDueSection';
import { PlanDayCardScheduleSection } from './PlanDayCardScheduleSection';
import {
  getDateBasedOnDayOffset,
  getMonthNameAndDay,
  getDayOfTheMonth,
  getDateBasedOnDayIndexAndDayOffset,
} from '../../../shared/utils/dateFormat';
import { PlanDesktopNavigation } from '../plan-desktop-navigation/PlanDesktopNavigation';
import {
  EPlannerMode,
  setPlannerCurrentTaskPlacement,
} from '../../chat-wrapper/resizable-container/stage-container/stage-planner/stagePlanner.store';
import { planTourDataAttrNavigateMobile } from '../../../app/constants';

export enum EPlanDayCardDisplayType {
  MY_PLAN = 1,
  MY_DAY = 2,
  MY_WEEK = 3,
}

interface IProps {
  index: number;
  displayType: EPlanDayCardDisplayType;
}

export const PlanDayCard = ({ index, displayType }: IProps) => {
  const [daysToDisplay, setDaysToDisplay] = useState(0);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const inView = useInViewport(containerRef);
  const { tasksListResponse } = useAppSelector((store) => store.StageTasksReducer);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setDaysToDisplay(
      displayType === EPlanDayCardDisplayType.MY_WEEK ? (isMobileDevice() ? 3 : 7) : 1,
    );
  }, [displayType]);

  const handlePlaceUnscheduledTasks = () => {
    const currentTaskPlacement = store.getState().StagePlannerReducer.currentTaskPlacement;
    const plannerMode = store.getState().StagePlannerReducer.plannerMode;
    if (
      displayType === EPlanDayCardDisplayType.MY_PLAN &&
      plannerMode === EPlannerMode.UNSCHEDULEDTASKSPLACER &&
      currentTaskPlacement
    ) {
      const inViewDayElement = document.querySelector('.plan-day-card-container--inview');
      if (!inViewDayElement) return;
      const elementId = inViewDayElement?.id;
      if (!elementId) return;
      const dayIndex = parseInt(elementId?.split('-')[1] || '0');
      const dayDate = getDateBasedOnDayOffset(dayIndex);
      const roundedMinutes = Math.round(dayDate.getMinutes() / 60) * 60;
      dayDate.setMinutes(roundedMinutes);
      const modified = Object.assign({}, currentTaskPlacement, {
        workTime: dayDate.toISOString(),
        duration: currentTaskPlacement.duration || 3600,
      });
      dispatch(setPlannerCurrentTaskPlacement(modified));
    }
  };

  const myWeekHeaderText = () => {
    const newDate = new Date();
    return `
      ${getMonthNameAndDay(
        getDateBasedOnDayIndexAndDayOffset(
          newDate.getDay() + index * daysToDisplay,
          0,
        ).toISOString(),
      )}
       - 
      ${getDayOfTheMonth(
        getDateBasedOnDayIndexAndDayOffset(
          newDate.getDay() + index * daysToDisplay,
          daysToDisplay - 1,
        ).toISOString(),
      )}
    `;
  };

  return (
    <div
      onClick={handlePlaceUnscheduledTasks}
      ref={containerRef}
      id={`day-${index}`}
      className={`plan-day-card-container${inView ? ' plan-day-card-container--inview' : ''}${
        tasksListResponse.status === EAPIStatus.PENDING ? ' plan-day-card-container--loading' : ''
      } plan-day-card-container--display-type-${displayType}`}
    >
      <div className="plan-day-card-inner-container">
        <div className="plan-day-weeks-header-container">
          <h2 className="plan-day-weeks-header">{myWeekHeaderText()}</h2>
          <PlanDesktopNavigation />
        </div>
        <div className="plan-day-card-content">
          <div
            className="plan-day-header-and-due-tasks-container"
            data-plan-tour={planTourDataAttrNavigateMobile}
          >
            {Array.from({ length: daysToDisplay }).map((_, dayIndex) => (
              <Fragment key={dayIndex}>
                <PlanDayCardDueSection
                  index={index}
                  daysToDisplay={daysToDisplay}
                  displayType={displayType}
                  dayIndex={dayIndex}
                />
              </Fragment>
            ))}
          </div>
          <PlanDayCardScheduleSection
            index={index}
            daysToDisplay={daysToDisplay}
            displayType={displayType}
            inView
          />
          <div className="plan-day-card-content--empty">
            <p>{t('myPlanDayNoDue')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
