import type { FunctionComponent } from 'react';
import { APP_ROUTING_PATHS } from '../../../constants';
import { useTranslation } from 'react-i18next';
import PhoneOrEmailVerificationByOtp from '../../../../shared/components/phone-verification-flow/phone-or-email-verification-by-otp/PhoneOrEmailVerificationByOtp';
import type { IVerifyOtpResponse } from '../../auth.interfaces';
import { useNavigateWithSearchParams } from '../../../../shared/utils/routingUtils';
import AppButton from '../../../../shared/components/app-button/AppButton';

interface IProps {
  isLoginFlow?: boolean;
  title?: string;
  subTitle?: string;
  emailToVerify?: string;
  onClickBack?: () => void;
}

const PhoneOrEmailVerificationAuthPage: FunctionComponent<IProps> = ({
  isLoginFlow,
  title,
  subTitle,
  emailToVerify,
  onClickBack,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithSearchParams();

  const handleFulfilledVerifyOtp = (data: IVerifyOtpResponse) => {
    if (data.shouldLink) {
      navigate(APP_ROUTING_PATHS.LINK_ACCOUNTS);
    } else {
      navigate(APP_ROUTING_PATHS.REDIRECT);
    }
  };

  return (
    <div
      className="phone-verification-page shorten-onboarding-flow auth-screen"
      id="phone-verification-page"
      data-testid="phone-verification-page"
    >
      <PhoneOrEmailVerificationByOtp
        className="signup-signin-phone-verification-form"
        isLoginFlow={isLoginFlow}
        isRegistrationFlow={true}
        onFulfilledVerifyOtp={handleFulfilledVerifyOtp}
        submitText={t('welcomeScreenButtonText')}
        trackEventName={`user-${isLoginFlow ? 'signin' : 'signup'}-verify-code`}
        context="CreateUser"
        title={title || t('phoneVerificationTitle')}
        subTitle={subTitle || t('phoneVerificationSubTitle')}
        emailToVerify={emailToVerify}
      />
      <AppButton
        id="verification-back-button"
        onClick={() => (onClickBack ? onClickBack() : navigate(-1))}
        className="back-link"
      >
        {t('registerScreenBackButtonText')}
      </AppButton>
    </div>
  );
};

export default PhoneOrEmailVerificationAuthPage;
