import type { FunctionComponent, ReactNode } from 'react';
import React from 'react';
import expand from '../../../../../assets/images/expand.svg';
import { useAppDispatch, useAppSelector } from '../../../../../app/store';
import { setShouldStageExpand } from '../stage-tasks/stageTasks.store';
import './StageHeader.scss';
import { useSearchParams } from 'react-router-dom';
import {
  chatHistoryParam,
  dislikeFeedbackParam,
  planTourDataAttrChangeViewsDesk,
  planTourDataAttrPlannerDesk,
  stageParam,
} from '../../../../../app/constants';
import AppButton from '../../../../../shared/components/app-button/AppButton';
import { EAppStageContentType } from '../stageContainer.interfaces';
import { StageTasksDropdownFilters } from '../stage-tasks/stage-tasks-dropdown-filters/StageTasksDropdownFilters';
import { PlanUnscheduledFab } from '../../../../plan/plan-unscheduled-fab/PlanUnscheduledFab';
import ClearScratchpadButton from '../stage-scratchpad/clear-scratchpad-button/ClearScratchpadButton';
import collapseIcon from '../../../../../assets/images/collapse-icon.svg';
import { useTranslation } from 'react-i18next';
import { PlanViewSelectionDropdown } from '../stage-planner/plan-view-selection/PlanViewSelectionDropdown';

export const StageHeader: FunctionComponent<{ children?: ReactNode | ReactNode[] }> = ({
  children,
}) => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { shouldStageExpand } = useAppSelector((store) => store.StageTasksReducer);
  const { t } = useTranslation();

  const getContent = () => {
    switch (searchParams.get(stageParam)) {
      case EAppStageContentType.TASKS:
        return (
          <>
            <h1 className="header-title">{t('stageMyTasksTitle')}</h1>
            <StageTasksDropdownFilters />
          </>
        );
      case EAppStageContentType.PLANNER:
        return (
          <>
            <PlanViewSelectionDropdown data-plan-tour={planTourDataAttrChangeViewsDesk} />
            <PlanUnscheduledFab planTourDataAttr={planTourDataAttrPlannerDesk} />
          </>
        );
      case EAppStageContentType.SCRATCHPAD:
        return <ClearScratchpadButton />;
      default:
        return <></>;
    }
  };

  const expandStage = () => {
    dispatch(setShouldStageExpand(true));
    // close dislike-feedback and chat-history modals while stage expanding
    setSearchParams((prev) => {
      prev.delete(chatHistoryParam);
      prev.delete(dislikeFeedbackParam);
      return prev;
    });
  };

  const renderSwitchButton = () => {
    if (shouldStageExpand) {
      return (
        <AppButton
          id="collapse-minimize-stage"
          className="minimize-stage"
          onClick={() => dispatch(setShouldStageExpand(false))}
          shouldChangeBackgroundOnActive={false}
        >
          <img src={collapseIcon} alt="collapse" />
        </AppButton>
      );
    }
    return (
      <AppButton
        id="expand-stage"
        className="expand"
        onClick={expandStage}
        shouldChangeBackgroundOnActive={false}
      >
        <img src={expand} alt="expand" />
      </AppButton>
    );
  };

  const getViewType = () => {
    switch (searchParams.get(stageParam)) {
      case EAppStageContentType.TASKS:
        return 'tasks';
      case EAppStageContentType.PLANNER:
        return 'plan';
      case EAppStageContentType.SCRATCHPAD:
        return 'scratchpad';
      default:
        return '';
    }
  };

  return (
    <div className={`stage-header stage-header--${getViewType()}`}>
      {renderSwitchButton()}
      <div className="stage-header-content">{getContent()}</div>
    </div>
  );
};
