import { useFormContext } from 'react-hook-form';
import type { IAddEditTaskForm, ITaskFields } from '../../stageTasks.interface';
import { useTranslation } from 'react-i18next';
import { AppRichText } from '../../../../../../../shared/components/app-rich-text/AppRichText';
import { ReactComponent as DescriptionIcon } from '../../../../../../../assets/images/task-details/task-details-description-icon.svg';

interface ITaskDescriptionProps {
  onUpdateTaskTextarea: (updatedField: ITaskFields, shouldSendMsgToTheBot?: boolean) => void;
  isReadOnly: boolean;
  isSubTask: boolean;
}

const TaskDescription = ({
  onUpdateTaskTextarea,
  isReadOnly,
  isSubTask,
}: ITaskDescriptionProps) => {
  const taskForm = useFormContext<IAddEditTaskForm>();
  const descriptionWatch = taskForm.watch('description');
  const { t } = useTranslation();

  const onBlur = (value: string) => {
    taskForm.setValue('description', value);
    onUpdateTaskTextarea({ description: value });
  };

  return (
    <div className="task-detail-section task-detail-section--description">
      <DescriptionIcon
        className={`task-detail-icon ${descriptionWatch?.length ? 'task-detail-icon--active' : ''}`}
      />

      <AppRichText
        id="description"
        testId={`${isSubTask ? 'sub-' : ''}task-description`}
        className={`task-textarea task-description ${
          isReadOnly && !descriptionWatch ? 'd-none' : ''
        }`}
        placeholder={t('addEditTaskDescriptionPlaceholder')}
        onBlur={(value: string) => {
          if (value !== descriptionWatch) onBlur(value);
        }}
        initialValue={descriptionWatch}
        maxLength={168}
        disabled={isReadOnly}
      />
    </div>
  );
};

export default TaskDescription;
