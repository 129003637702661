import { FunctionComponent } from 'react';
import './AuthHeroCard.scss';

interface IAuthHeroCardProps {
  mascot: string;
  title: string;
  subTitle?: string;
}

export const AuthHeroCard: FunctionComponent<IAuthHeroCardProps> = ({
  mascot,
  title,
  subTitle,
}) => {
  return (
    <div className="auth-hero-card">
      <div className="sparks-bg">
        <img src={mascot} alt="mascot" className="auth-card-mascot" />
        <h1 className="auth-card-title">{title}</h1>
        {subTitle && <h2 className="auth-sub-title">{subTitle}</h2>}
      </div>
    </div>
  );
};
