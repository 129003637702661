import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import welcomeScreen1Image from '../../../../assets/images/welcome-screen-1-image.png';

export const CarouselScreen1: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="carousel-element welcome-screen-1" data-testid="carousel-element">
      <h1 className="title auth-title">{t('welcomeScreen1Title')}</h1>
      <div className="image-container">
        <img src={welcomeScreen1Image} alt={t('welcomeScreen1ImageAltText1')} />
      </div>
      <p className="extension-text">{t('welcomeScreen1Paragraph')}</p>
    </div>
  );
};
