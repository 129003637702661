export const getInitials = (name: string | null): string => {
  let initials = '';
  if (name) {
    const names: string[] = name.split(' ');
    const firstNameInitial = names[0] ? names[0].charAt(0) : '';
    const lastNameInitial = names.length > 1 ? names[names.length - 1].charAt(0) : '';
    initials = (firstNameInitial + lastNameInitial).toUpperCase();
  }
  return initials;
};
