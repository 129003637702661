import type { FunctionComponent } from 'react';
import React from 'react';
import type { ITaskSingleActionButtonsProps } from '../TasksListSingleItem.interfaces';
import { useTranslation } from 'react-i18next';
import { ETaskStatus } from '../../../../../../resizable-container/stage-container/stage-tasks/stageTasks.interface';
import TaskActionButton from './TaskActionButton';
import createAppOverlayPopover from '../../../../../../../../shared/components/app-overlay-popover/createAppOverlayPopover';
import { ConfirmModal } from '../../../../../../../../shared/components/confirm-modal/ConfirmModal';
import removeAppOverlayPopover from '../../../../../../../../shared/components/app-overlay-popover/removeAppOverlayPopover';
import { deleteTaskModalAppOverlayPosition } from '../../TasksList.utils';
import './TaskDeleteActionButton.scss';

const TaskDeleteActionButton: FunctionComponent<ITaskSingleActionButtonsProps> = ({
  onUpdateTask,
  className = '',
}) => {
  const { t } = useTranslation();

  const onClickDeleteAction = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    createAppOverlayPopover(
      <ConfirmModal
        title={t('deleteTaskConfirmModalTitle')}
        informationText={t('deleteTaskConfirmModalSubTitle')}
        confirmBtnText={t('settingsResetProfileConfirmModalConfirmButtonText') + '.'}
        onConfirm={() => {
          onUpdateTask({ status: ETaskStatus.DELETED });
          removeAppOverlayPopover();
        }}
        onCancel={() => removeAppOverlayPopover()}
        className="delete-modal"
      />,
      'delete-task-modal',
      e,
      { ...deleteTaskModalAppOverlayPosition() },
    );
  };

  return (
    <>
      <TaskActionButton
        actionName={t('taskDeleteActionButtonText')}
        actionType="delete"
        onClickAction={onClickDeleteAction}
        className={className}
      />
    </>
  );
};

export default TaskDeleteActionButton;
